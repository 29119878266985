import {methods, url} from '../utils';
import {authenticatedService} from "./middleware";

const routes = {
  minis: (userId) => url.LIST_MINIS(userId),
  miniRoom: (userId, miniId, roomId) => url.MINI_ROOM(userId, miniId, roomId),
  miniRoomSuccesses: (userId, miniId, roomId) => url.MINI_ROOM_SUCCESSES(userId, miniId, roomId),
};

export default {
  getListMinis: (userId) => authenticatedService(methods.GET, routes.minis(userId), {}, {}),
  getMiniRoom: (userId, miniId, roomId) => authenticatedService(methods.GET, routes.miniRoom(userId, miniId, roomId), {}, {}),
  updateMiniRoomSuccesses: (userId, miniId, roomId, successes) => authenticatedService(methods.PATCH, routes.miniRoomSuccesses(userId, miniId, roomId), successes, {}),
};
