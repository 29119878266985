import AnimerunatelierDéfinirsesvaleurss from './AnimerunatelierDéfinirsesvaleurss.pdf';
import AnimerunatelierRépartirlesrôles from './AnimerunatelierRépartirlesrôles.pdf';
import Introductionaubudgetprévisionnel from './Introductionaubudgetprévisionnel.pdf';
import LesrôlesdanslaMiniEntreprise from './LesrôlesdanslaMiniEntreprise.pdf';
import Lexiquedestermesentrepreneuriaux from './Lexiquedestermesentrepreneuriaux.pdf';
import Prendrelaparoleenpublic from './Prendrelaparoleenpublic.pdf';
import Prendreunedécision from './Prendreunedécision.pdf';
import Quepeutonproduire from './Quepeutonproduire.pdf';
import Rédigersonrapportdactivitécollège from './Rédigersonrapportdactivitécollège.pdf';
import Rédigersonrapportdactivitélycée from './Rédigersonrapportdactivitélycée.pdf';
import TrameAutorisationdutilisationdelimage from './TrameAutorisationdutilisationdelimage.pdf';
import TrameLecahierdescharges from './TrameLecahierdescharges.pdf';
import Utiliserlaplateforme4Loutilcomptable from './Utiliserlaplateforme4Loutilcomptable.pdf';
import TrameBudgetprévisionnel from './TrameBudgetprévisionnel.xlsm';
import TrameLettreinvestisseur from './TrameLettreinvestisseur.docx';
import FairelebilanhumaindesaMiniEntreprise from './FairelebilanhumaindesaMiniEntreprise.pdf';
import AutorisationdroitimageMiniL from './AutorisationdroitimageMiniL.pdf';
import AutorisationdroitimageMiniM from './AutorisationdroitimageMiniM.pdf';
import BilanInduviduelMiniEntreprise from './BilanInduviduelMiniEntreprise.pdf';
export const ressources = {
  AnimerunatelierDéfinirsesvaleurss,
  AnimerunatelierRépartirlesrôles,
  Introductionaubudgetprévisionnel,
  LesrôlesdanslaMiniEntreprise,
  Lexiquedestermesentrepreneuriaux,
  Prendrelaparoleenpublic,
  Prendreunedécision,
  Quepeutonproduire,
  Rédigersonrapportdactivitécollège,
  Rédigersonrapportdactivitélycée,
  TrameAutorisationdutilisationdelimage,
  TrameLecahierdescharges,
  Utiliserlaplateforme4Loutilcomptable,
  TrameBudgetprévisionnel,
  TrameLettreinvestisseur,
  FairelebilanhumaindesaMiniEntreprise,
  AutorisationdroitimageMiniL,
  AutorisationdroitimageMiniM,
  BilanInduviduelMiniEntreprise
};