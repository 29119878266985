import moment from 'moment';
import React, { useState } from 'react';
import { icons } from '../../../../../assets';
import { filterAndSortUsers, getProjectImage, Routes } from '../../../../../utils';
import SchoolItem from '../schoolItem';
import UserItem from '../userItem';
import './miniItem.scss';

const MiniItem = ({ strings, mini, currentUserId, navigateTo }) => {
  const [showDetail, setShowDetail] = useState(false);
  const toggleDetail = () => setShowDetail(!showDetail);
  const handleRoomClicked = (roomId) => () => navigateTo(
    Routes.ROOM_DETAIL.replace(':miniId', mini.id).replace(':roomId', roomId)
  );
  const projectUsers = filterAndSortUsers(mini.users, currentUserId);

  return (
    <div className={'mini-item column fade'}>
      <div className={'header line'}>
        <div className={'wrapper'}>
          <div className={"title"}>
            <span>{mini.name || strings('namelessProject')}</span>
          </div>
          <div className={"status"}>
            <p>{strings(`project_status_${mini.status.toLowerCase()}`)}</p>
            <div className={`state ${mini.status.toLowerCase()}`} />
          </div>
        </div>
        <div className={'line'}>
          {getProjectImage(strings, mini.type)}
          <div className={'vertical-separator'} />
          <div className={'column'}>
            <div className={'line date'}>
              <p className={'label'}>{strings('date')}</p>
              <p className={'value'}>{moment(mini.startDate).format('DD MMMM YYYY')}</p>
            </div>
            <p className={'place'}>{mini.distance ? strings('online') : mini.placeName}</p>
          </div>
        </div>
      </div>
      <div className={'separator'} />
      <div className={'detail column'}>
        <div className={'summary line'}>
          <div className={'info line'}>
            <div className={'line'}>
              <p className={'value'}>{mini.studentsCount}</p>
              <i className={'material-icons'}>{icons.PROFILE}</i>
              <p className={'label'}>{strings('students')}</p>
            </div>
            <div className={'line'}>
              <p className={'value'}>{mini.duration}</p>
              <i className={'material-icons'}>{icons.SCHEDULE}</i>
              <p className={'label'}>{strings('hours')}</p>
            </div>
            <div className={'line'}>
              <p className={'value'}>{mini.rooms.length}</p>
              <i className={'material-icons'}>{icons.SCHOOL}</i>
              <p className={'label'}>{strings('rooms')}</p>
            </div>
          </div>
          <div className={`action line ${showDetail ? 'developped' : ''}`} onClick={toggleDetail}>
            <p className={'label'}>{strings('show_detail')}</p>
            <i className={'material-icons chevron'}>{icons.CHEVRON_RIGHT}</i>
          </div>
        </div>
        <div className={`collapse-wrapper column ${showDetail ? 'developped' : ''}`}>
          <div className={'separator'} />
          <div className={'contacts line'}>
            <div className={'column'}>
              <p className={'title'}>{strings('partners')}</p>
              {mini.partners.map((partner) => (
                <div key={partner.id} className={'partner-item'}>
                  <p>{partner.name}</p>
                </div>
              ))}
            </div>
            <div className={'vertical-separator'} />
            <div className={'column'}>
              <p className={'title'}>{strings('school')}</p>
              {mini.schools.map((school) => (
                <SchoolItem key={school.id} school={school} strings={strings} />
              ))}
            </div>
            <div className={'vertical-separator'} />
            <div className={'column'}>
              <p className={'title'}>{strings('mentors')}</p>
              {projectUsers.mentors.map((user) => (
                <UserItem key={user.id} user={user} strings={strings} />
              ))}
            </div>
            <div className={'vertical-separator'} />
            <div className={'column'}>
              <p className={'title'}>{strings('facilitators')}</p>
              {projectUsers.facilitators.map((user) => (
                <UserItem key={user.id} user={user} strings={strings} />
              ))}
            </div>
            <div className={'vertical-separator'} />
            <div className={'column'}>
              <p className={'title'}>{strings('supervisors')}</p>
              {projectUsers.supervisors.map((user) => (
                <UserItem key={user.id} user={user} strings={strings} />
              ))}
            </div>
          </div>
        </div>
        <div className={'rooms column'}>
          {mini.rooms.map((room, index) => (
            <div key={room.id} className={'room line'} onClick={handleRoomClicked(room.id)}>
              <div className={'line'}>
                <div className={'intro line'}>
                  <p className={'label'}>{`${strings('room_number')}${index + 1}`}</p>
                  <i className={'material-icons'}>{icons.PROFILE}</i>
                  <p className={'students'}>{room.students}</p>
                </div>
                <div className={'access line'}>
                  <i className={'material-icons'}>{icons.LINK}</i>
                  <p className={'code'}>{room.code}</p>
                  <i className={'material-icons'}>{icons.LOCK}</i>
                  <p className={'password'}>{room.password}</p>
                </div>
              </div>
              <i className={'material-icons chevron'}>{icons.CHEVRON_RIGHT}</i>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MiniItem;
