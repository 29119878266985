import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import { CommonActions } from '../../../redux/common';
import { UserActions } from '../../../redux/user';
import { default as Profile } from './Profile';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(UserActions.getUserRequested()),
  getRegions: () => dispatch(CommonActions.getRegionsRequested()),
  navigateTo: path => dispatch(push(path)),
  pop: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
