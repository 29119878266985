import { KUMULUS_SSO } from './constants';

export const Routes = {
  HOME: '/',
  PROJECT_LIST: '/projects',
  PROJECT_SUCCESS: '/projects/:projectId/success',
  SUCCESS_DETAIL: '/success/:succesId',
  VIDEO_DETAIL: `/player/:projectId/:succesId/:videoId`,
  PROJECT_BUDGET: '/projects/:projectId/budget',
  MY_SEANCES: '/myseances',
  PROJECT_DETAIL: '/projects/:projectId',
  ROOM_DETAIL: '/minis/:miniId/room/:roomId',
  LEGAL_MENTIONS: '/mentions-legales',
  PLANNING: '/projects/:projectId/planning',
  LINKS: '/projects/:projectId/links',
  DATA: '/projects/:projectId/data',
  PROFILE: '/profile',
  GUIDES: '/guides/',
};

export const origins = {
  AUTHENT: 'A',
  KUMULUS: 'K',
  DASHBOARD: 'D',
  FACILITATOR: 'F',
};

export const url = {
  LIST_PROJECTS: (userId) => `/users/${userId}/projects`,
  UPDATE_SUCCESS: (userId, id) => `/users/${userId}/projects/${id}/successes`,
  GET_PROJECT: (userId, projectId) => `/users/${userId}/projects/${projectId}`,
  UPDATE_PROJECT: (userId, projectId) => `/users/${userId}/projects/${projectId}`,
  ADD_MEETING: (userId, projectId) => `/users/${userId}/projects/${projectId}/meetings`,
  DELETE_MEETING: (userId, projectId, meetingId) =>
    `/users/${userId}/projects/${projectId}/meetings/${meetingId}`,
  UPDATE_MEETING: (userId, projectId, meetingId) =>
    `/users/${userId}/projects/${projectId}/meetings/${meetingId}`,
  ADD_BUDGET: (userId, projectId) => `/users/${userId}/projects/${projectId}/budgets`,
  DELETE_BUDGET: (userId, projectId, budgetId) =>
    `/users/${userId}/projects/${projectId}/budgets/${budgetId}`,
  UPDATE_BUDGET: (userId, projectId, budgetId) =>
    `/users/${userId}/projects/${projectId}/budgets/${budgetId}`,
  DOWNLOAD_BUDGET: (userId, projectId) => `/users/${userId}/projects/${projectId}/budgets/export`,
  GET_OPERATION_TYPES: () => `/operationTypes`,
  ADD_LINK: (userId, projectId) => `/users/${userId}/projects/${projectId}/links`,
  UPDATE_LINK: (userId, projectId, linkId) =>
    `/users/${userId}/projects/${projectId}/links/${linkId}`,
  DELETE_LINK: (userId, projectId, linkId) =>
    `/users/${userId}/projects/${projectId}/links/${linkId}`,
  UPDATE_DATA: (userId, projectId) => `/users/${userId}/projects/${projectId}/data`,
  LIST_MINIS: (userId) => `/users/${userId}/minis`,
  MINI_ROOM: (userId, miniId, roomId) => `/users/${userId}/minis/${miniId}/room/${roomId}`,
  MINI_ROOM_SUCCESSES: (userId, miniId, roomId) =>
    `/users/${userId}/minis/${miniId}/room/${roomId}/successes`,
  GET_CLASS_CATEGORIES: () => `/classCategories`,
  GET_CLASS_LEVELS: () => `/classLevels`,
  GET_CLASS_SECTORS: () => `/classSectors`,
  GET_DEDICATED_TIMES: () => `/dedicatedTimes`,
  GET_REGIONS: () => '/inscription/regions',
  ADD_SUCCESS: (userId, projectId) => `/users/${userId}/projects/${projectId}/successes`,
  DELETE_SUCCESS: (userId, projectId, successId) =>
    `/users/${userId}/projects/${projectId}/successes/${successId}`,
};

export const userRole = {
  ADMIN: 'ADMIN',
  FACILITATOR: 'FACILITATOR',
  SUPERVISOR: 'SUPERVISOR',
  MENTOR: 'MENTOR',
  STUDENT: 'STUDENT',
};

export const actions = {
  LOGOUT: 'logout',
};

export const projectTypeKeys = {
  M13: 'M13',
  M9: 'M9',
  L: 'L',
  XL: 'XL',
  S: 'S',
  X10: '10X',
};

export const navigationMenu = [
  {
    label: 'my_projects',
    route: Routes.PROJECT_LIST,
    newTab: false,
    dropdown: false
  },
  {
    label: 'tool_box',
    route: `${process.env.REACT_APP_KUMULUS_URL}${KUMULUS_SSO}`,
    newTab: true,
    dropdown: false
  },
  {
    label: 'supervisor_guides',
    route: '',
    newTab: false,
    dropdown: true
  }
];

export const navigationMenuGuides = [
  {
    label: 'Guide Encadrant Mini-Entreprise® M',
    route: `${Routes.GUIDES}?mini=M`,
    type: 'M'
  },{
    label: 'Guide Encadrant Mini-Entreprise® L',
    route: `${Routes.GUIDES}?mini=L`,
    type: 'L'
  },{
    label: 'Guide Encadrant Mini-Entreprise® XL',
    route: `${Routes.GUIDES}?mini=XL`,
    type: 'XL'
  }
]

export const notionUri = {
  M: 'd5e2eabb40bd48a9ad38c91f8ab8313e',
  L: '3c19a84f5e7d41c0abdc9c57c4b60eb5',
  XL: 'f9bb59b2637044de9d04fd0ff34aa518'
}

export const footerLink = {
  LEGAL_MENTIONS: {
    label: "legal_mentions",
    link: Routes.LEGAL_MENTIONS
  },
};

export const accountType = {
  BANK: 'BANK',
  FUND: 'FUND'
};

export const budgetOperation = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW'
};

export const iconList = {
  TRELLO: 'TRELLO',
  TWITTER: 'TWITTER',
  FACEBOOK: 'FACEBOOK',
  SLACK: 'SLACK',
  MAIL: 'MAIL',
  CALENDAR: 'CALENDAR',
  G_DRIVE: 'G_DRIVE',
  DROPBOX: 'DROPBOX',
  ONE_DRIVE: 'ONE_DRIVE',
  FOLDER: 'FOLDER',
  SNAPCHAT: 'SNAPCHAT',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  PINTEREST: 'PINTEREST',
  G_PLUS: 'G_PLUS',
  YOUTUBE: 'YOUTUBE',
  VIMEO: 'VIMEO',
  TUMBLR: 'TUMBLR',
  WWEEDDOO: 'WWEEDDOO',
  MAILCHIMP: 'MAILCHIMP',
  ASANA: 'ASANA',
  WRIKE: 'WRIKE',
  TUZZIT: 'TUZZIT',
  ACHIEVEMENT: 'ACHIEVEMENT',
  EPA: 'EPA',
  AT: 'AT',
  LINK: 'LINK',
  FRIEND: 'FRIEND',
  ARROW: 'ARROW',
  GLOBAL: 'GLOBAL',
  HOME: 'HOME',
  VIDEO: 'VIDEO',
};

export const civility = {
  MR: 'MR',
  MME: 'MME'
};

export const projectType = {
  MINI: 'MINI',
  PROJECT: 'PROJECT'
};

export const projectStatus = {
  ALL: 'ALL',
  VALIDATED: 'VALIDATED',
  ARCHIVED: 'ARCHIVED',
  COMMING: 'COMMING',
  FINISHED: 'FINISHED'
};

export const meetingFilteringType = {
  ALL: 'ALL',
  MONTHLY: 'MONTHLY',
  COMING: 'COMING',
  CURRENT: 'CURRENT',
};
