import React, { useEffect, useState } from 'react';
import './project-list.scss';
import { projectType, Routes, userRole } from '../../../../utils';
import { ScreenHeader } from '../../../components';
import { sortProjectsByStatus } from '../../../../services/Sorter/ProjectsSorter';
import { MiniItem } from './miniItem';
import ProjectItem from './projectItem';
import { filterProjectsByStatus } from '../../../../services/Filter/ProjectsFilter';

export const ProjectList = ({ role, projects, strings, currentUserId, getProjects, navigateTo }) => {

  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectCount, setProjectCount] = useState(0);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  useEffect(() => {
    if (role === userRole.STUDENT && projects && projects.length > 0) {
      if (!projects[0].projectType || projects[0].projectType === projectType.PROJECT) {
        navigateTo(Routes.PROJECT_DETAIL.replace(':projectId', projects[0].id));
      } else {
        navigateTo(Routes.ROOM_DETAIL.replace(':miniId', projects[0].id).replace(':roomId', projects[0].rooms[0].id));
      }
    }
  }, [projects, navigateTo, role]);

  useEffect(() => {
    const filtered = filterProjectsByStatus(projects, selectedFilter);
    setFilteredProjects(filtered);
    setProjectCount(filtered.length);
  }, [projects, selectedFilter]);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div id={'projects'}>

      <ScreenHeader
        showFilters={true}
        screenName={projectCount > 1 ? 'projects' : 'project'}
        showBackAction={false}
        projectsLength={projectCount}
        className={'navigation'}
        handleFilterChange={handleFilterChange}
      />
      <div className={'container'}>
        {filteredProjects.length === 0 ? (
          <p className={'no-projects'}>{strings('no_projects')}</p>
        ) : (
          sortProjectsByStatus(filteredProjects)
          .map((project) => {
            return project.projectType === projectType.MINI ? (
              <MiniItem key={`mini-${project.id}`} mini={project} strings={strings} currentUserId={currentUserId} navigateTo={navigateTo} />
            ) : (
              <ProjectItem project={project} strings={strings} key={project.id} currentUserId={currentUserId} showContactsInfo={role !== userRole.STUDENT} />
            );
          })
        )}
      </div>
    </div>
  );
};
