import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { ProjectsActions, ProjectsSelectors } from '../../../redux/projects';
import { createAction } from '../../../utils';
import { Data } from './Data';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  shouldRetrieveLists: ProjectsSelectors.shouldUpdateDataList()(state),
  projectId: state.projects.project ? state.projects.project.id : null,
  type: state.projects.project.type ,
  data: state.projects.project ? state.projects.project.data : null,
  categories: state.projects.categories,
  levels: state.projects.levels,
  sectors: state.projects.sectors,
  times: state.projects.times,
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
  getProject: projectId => createAction(dispatch(ProjectsActions.getProjectDetailRequested(projectId))),
  saveData:  (projectId, data) => dispatch(ProjectsActions.updateDataRequested(projectId, data)),
  getDataLists: () => createAction(dispatch(ProjectsActions.getDataListsRequested(false))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Data);
