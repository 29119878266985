import React, { useState, useEffect, useRef } from 'react';
import { icons } from '../../../../assets';
import { navigationMenu } from '../../../../utils';
import { default as Item } from '../item';
import { DropDown } from '../../index';

export const Menu = ({strings, isSuccess, guides, navigateTo}) => {
  const [displayed, setDisplayed] = useState(false);

  const responsiveMenu = useRef(null);

  const toggleMenu = () => setDisplayed(!displayed);
  const handleClickOutside = e =>
    e.clientX > responsiveMenu.current.getBoundingClientRect().width && setDisplayed(false);

  const handleDropDownChanged = () => (value) => {
    const position = value.value
    const route = guides[position].route
    navigateTo(route)
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [displayed]);
  const filterMenuItems = () => {
    if (isSuccess) {
      return navigationMenu.filter(item => item.label !== 'tool_box');
    } else {
      return navigationMenu;
    }
  }
  return (
    <div id={'menu'}>
      <i onClick={toggleMenu} className={'material-icons menu'}>
        {displayed ? icons.CLOSE : icons.MENU}
      </i>
      <div className={`wrapper ${displayed && 'displayed'}`} ref={responsiveMenu}>
        <ul className={'items'}>
          {filterMenuItems().map((item, index) => item.dropdown ?
              <DropDown
                label={strings(item.label)}
                current={item.dropdown ? item.dropdown.label : null}
                data={guides.map((item) => item.label)}
                className={`${strings(item.label)}`}
                handleClickItem={handleDropDownChanged('classes', index, guides)}
              />
          :
            <Item
              key={index}
              text={strings(item.label)}
              route={item.route}
              newTab={item.newTab}
              fromResponsiveMenu
              close={toggleMenu}
            />
          )}
        </ul>
      </div>
    </div>
  );
}
