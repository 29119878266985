import React from 'react';
import { icons } from '../../../assets';
import './snackBar.scss';

export const SnackBar = ({isShown, message, level, forceHide}) => {
  return (
    <div
      id="infoView"
      className={isShown ? 'display-info fade' : 'hide-info'}
      style={{backgroundColor: level}}>
      <div className={'message'}>{message}</div>
      <div className={'remove-info-box'}>
        <i className="material-icons remove-info" onClick={forceHide}>{icons.CLOSE}</i>
      </div>
    </div>
  );
};
