import { EMAIL_REGEXP, PHONE_REGEX } from '../../../../utils';

export const validateUserForm = (user) => {
  const formError = {};
  const {civility, firstName, lastName, email, phone, region} = user;

  formError.civility = !civility;
  formError.firstName = !firstName || firstName.length < 2;
  formError.lastName = !lastName || lastName.length < 2;
  formError.email = !EMAIL_REGEXP.test(email);
  formError.phone = !phone || !PHONE_REGEX.test(phone);
  formError.region = !region || region.length < 2;

  return formError;
}

export const validateMentorForm = (mentor) => {
  const formError = {};
  const {description, companyName, companyAddress, companyPostalCode, companyCity, companyCountry, ceoLastName, ceoFirstName, ceoPhone, ceoEmail} = mentor;
  
  formError.description = !description || description.length < 2;
  formError.companyName = !companyName || companyName.length < 2;
  formError.companyAddress = !companyAddress || companyAddress.length < 2;
  formError.companyPostalCode = !companyPostalCode || companyPostalCode.length < 2;
  formError.companyCity = !companyCity || companyCity.length < 2;
  formError.companyCountry = !companyCountry || companyCountry.length < 2;
  formError.ceoLastName = ceoLastName && ceoLastName.length < 2;
  formError.ceoFirstName = ceoFirstName && ceoFirstName.length < 2;
  formError.ceoPhone = ceoPhone && !PHONE_REGEX.test(ceoPhone);
  formError.ceoEmail = ceoEmail && !EMAIL_REGEXP.test(ceoEmail);

  return formError;
};



