import React from 'react';
import './detail-budget-list.scss';

export const BudgetSummary = ({ budget, strings }) => {
  const { bank } = budget.total;

  const value = val => (
    <p className={`${val < 0 ? 'negative' : ''}`}>{val.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + strings('currency')}</p>
  );

  return (
    <div className={'summary'}>
      <div className={'item header'}>
        <h2>{strings('budget_balance')}</h2>
      </div>
      <div className={'item'}>
        {value(bank)}
      </div>
    </div>
  );
};
