export const sortProjectsByStatus = (projects) => {
  return projects.sort((projectA, projectB) => {
    if (projectA.status === 'VALIDATED' && projectB.status !== 'VALIDATED') {
      return -1;
    }
    if (projectA.status !== 'VALIDATED' && projectB.status === 'VALIDATED') {
      return 1;
    }
    if (projectA.status === 'COMMING' && projectB.status === 'FINISHED') {
      return -1;
    }
    if (projectA.status === 'FINISHED' && projectB.status === 'COMMING') {
      return 1;
    }
    return 0;
  });
};
