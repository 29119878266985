import { FORBIDDEN } from 'http-status-codes';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { UserService } from '../../services';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as UserActions, types } from './actions';

function* getUserRequested() {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(UserService.getUser, id);
  if (!error) {
    yield put(UserActions.getUserSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('profile_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateUserRequested({user}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(UserService.updateUser, id, user);
  if (!error) {
    yield put(UserActions.updateUserSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('update_user_error_generic'));
  }
  yield put(LoaderActions.loaded());
}

function* changePasswordRequested({body}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(UserService.changePassword, id, body);
  if (!error) {
    yield put(UserActions.changePasswordSucceeded(response.data));
    yield put(SnackActions.displayInfo('change_password_success'));
  } else if (error.response.status === FORBIDDEN) {
    yield put(SnackActions.displayError('change_password_error_wrong_password'));
  } else {
    yield put(SnackActions.displayError('change_password_error_generic'));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_USER_REQUESTED, getUserRequested),
  takeLatest(types.UPDATE_USER_REQUESTED, updateUserRequested),
  takeLatest(types.CHANGE_PASSWORD_REQUESTED, changePasswordRequested),
];
