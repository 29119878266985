import React, { useEffect, useState } from 'react';
import ShortId from 'shortid';
import { icons, images } from '../../../assets';
import { Routes,projectTypeKeys } from '../../../utils';
import { Button, ButtonColors, DropDown, ScreenHeader } from '../../components';
import './data.scss';
import { Input } from './Input';

export const Data = React.memo(
  ({
    match,
    strings,
    getProject,
    data,
    projectId,
    categories,
    levels,
    sectors,
    times,
    saveData,
    shouldRetrieveLists,
    getDataLists,
    navigateTo,
    type,
  }) => {
    const classDeepFields = ['category', 'level', 'sector'];
    const classFlatFields = ['girlsCount', 'boysCount'];

    const [formData, setFormData] = useState(null);

    useEffect(() => {
      shouldRetrieveLists && getDataLists();
    }, [shouldRetrieveLists]);

    useEffect(() => {
      const { projectId: urlProjectId } = match.params;
      if (!projectId || !data || parseInt(urlProjectId) !== projectId) {
        getProject(urlProjectId);
      }
    }, [projectId, data]);

    useEffect(() => {
      if (data) {
        //Generate shortId to have a unique key for each item preventing list from re-rendering every time a modification occurs but rendering when an item is deleted
        const dataCopy = JSON.parse(JSON.stringify(data));
        //Instantiate a first element in the list
        if (!dataCopy.classes || dataCopy.classes.length === 0) {
          dataCopy.classes = [{}];
        }
        if (!dataCopy.times || dataCopy.times.length === 0) {
          dataCopy.times = [{}];
        }

        dataCopy.classes.map((item) => {
          item.key = ShortId.generate();
          return item;
        });
        dataCopy.times.map((item) => {
          item.key = ShortId.generate();
          return item;
        });
        setFormData(dataCopy);
      }
    }, [data]);

    const handleNavigateBack = () => {
      navigateTo(Routes.PROJECT_DETAIL.replace(':projectId', projectId));
    };

    const handleDropDownChanged = (dataList, index, dropDownList) => (value) => {
      const formCopy = JSON.parse(JSON.stringify(formData));

      const modifiedElement = formCopy[dataList][index];
      modifiedElement[value.key] = dropDownList[value.value];

      setFormData(formCopy);
    };

    const handleInputChanged = (dataList, index) => (field, value) => {
      const formCopy = JSON.parse(JSON.stringify(formData));

      const modifiedElement = formCopy[dataList][index];
      modifiedElement[field] = parseInt(value);
      modifiedElement[`${field}OnError`] = !value || isNaN(parseInt(value));

      setFormData(formCopy);
    };

    const handleDeleteItem = (dataList, index) => () => {
      const formCopy = JSON.parse(JSON.stringify(formData));
      formCopy[dataList].splice(index, 1);

      if (formCopy[dataList].length === 0) {
        formCopy[dataList].push({ key: ShortId.generate() });
      }
      setFormData(formCopy);
    };

    const handleAddItem = (dataList) => () => {
      const formCopy = JSON.parse(JSON.stringify(formData));
      formCopy[dataList].push({ key: ShortId.generate() });

      setFormData(formCopy);
    };

    const handleSave = () => {
      const formCopy = JSON.parse(JSON.stringify(formData));
      let globalError = false;

      formCopy.classes.forEach((item) => {
        classDeepFields.forEach((field) => {
          if (!item[field] || !item[field].label) {
            globalError = true;
            item[field] = { onError: true };
          }
          classFlatFields.forEach(
            (field) => (item[`${field}OnError`] = !item[field] || isNaN(parseInt(item[field]))),
          );
        });
      });

      formCopy.times.forEach((item) => {
        if (!item.time || !item.time.label) {
          globalError = true;
          item.time = { onError: true };
        }

        item.volumeOnError = !item.volume || item.volume <= 0 || isNaN(parseInt(item.volume));
        if (item.volumeOnError) {
          globalError = true;
        }
      });

      if (globalError) {
        setFormData(formCopy);
        scrollToFirstElementInError(formCopy);
      } else {
        if (projectId) {
          formCopy.classes.forEach((item) => {
            item.category = item.category.id;
            item.sector = item.sector.id;
            item.level = item.level.id;
          });
          formCopy.times.forEach((item) => {
            item.time = item.time.id;
          });
          saveData(projectId, formCopy);
        }
      }
    };

    const scrollToFirstElementInError = (formData) => {
      const navigation = document.querySelector(`#navigation`);
      const firstClassError = formData.classes.findIndex(
        (item) =>
          item.category.onError ||
          item.level.onError ||
          item.sector.onError ||
          item.girlsCountOnError ||
          item.boysCountOnError,
      );

      if (firstClassError >= 0) {
        const element = document.querySelector(`.class_${firstClassError}`);
        window.scrollTo({
          top: element.offsetTop - navigation.getBoundingClientRect().height,
          // top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        const firstTimeError = formData.times.findIndex(
          (item) => item.time.onError || item.volumeOnError,
        );

        if (firstTimeError >= 0) {
          const element = document.querySelector(`.time_${firstTimeError}`);
          window.scrollTo({
            top: element.offsetTop - navigation.getBoundingClientRect().height,
            // top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    };

    const totalStudents = formData
      ? formData.classes
          .map(
            (item) =>
              (item.boysCount ? item.boysCount : 0) + (item.girlsCount ? item.girlsCount : 0),
          )
          .reduce((acc, val) => acc + val, 0)
      : 0;
    const totalVolume = formData
      ? formData.times
          .map((item) => (item.volume ? item.volume : 0))
          .reduce((acc, val) => acc + val, 0)
      : 0;


    return (
      <div id={'projectData'}>
        <img src={images.epaBackground2} alt={'epa-background'} className={'background'} />
        <ScreenHeader screenName={'dashboard'} arrowAction={handleNavigateBack} />
        <div className={'data-form'}>
          <ul className={'classes'}>
            {formData &&
              formData.classes &&
              formData.classes.map((item, index) => (
                <li key={item.key} className={`class_${index}`}>
                  <p className={'label'}>{`${strings('class_number')}${index + 1}`}</p>
                  <div className={'wrapper'}>
                    <p className={'label'}>{strings('category')}</p>
                    <DropDown
                      label={'category'}
                      current={item.category ? item.category.label : null}
                      data={categories.map((item) => item.label)}
                      className={`category ${
                        item.category && item.category.onError ? 'invalid' : ''
                      }`}
                      handleClickItem={handleDropDownChanged('classes', index, categories)}
                    />
                  </div>
                  <div className={'wrapper'}>
                    <p className={'label'}>{strings('level')}</p>
                    <DropDown
                      label={'level'}
                      current={item.level ? item.level.label : null}
                      data={levels.map((item) => item.label)}
                      className={`level ${item.level && item.level.onError ? 'invalid' : ''}`}
                      handleClickItem={handleDropDownChanged('classes', index, levels)}
                    />
                  </div>
                  <div className={'wrapper'}>
                    <p className={'label'}>{strings('sector')}</p>
                    <DropDown
                      label={'sector'}
                      current={item.sector ? item.sector.label : null}
                      data={sectors.map((item) => item.label)}
                      className={`sector ${item.sector && item.sector.onError ? 'invalid' : ''}`}
                      handleClickItem={handleDropDownChanged('classes', index, sectors)}
                    />
                  </div>
                  <div className={'wrapper wrapper-composition'}>
                    <div className={'composition'}>
                      <p className={'label'}>{strings('girls_count')}</p>
                      <Input
                        label={strings('password')}
                        className={`${item.girlsCountOnError ? 'invalid' : ''}`}
                        type={'number'}
                        tag={'girlsCount'}
                        defaultValue={item.girlsCount}
                        handleValueChanged={handleInputChanged('classes', index)}
                        image={images.girl}
                        alt={'Icone fille'}
                      />
                    </div>
                    <div className={'composition'}>
                      <p className={'label'}>{strings('boys_count')}</p>
                      <Input
                        label={strings('password')}
                        className={`${item.boysCountOnError ? 'invalid' : ''}`}
                        type={'number'}
                        tag={'boysCount'}
                        defaultValue={item.boysCount}
                        handleValueChanged={handleInputChanged('classes', index)}
                        image={images.boy}
                        alt={'Icone garçon'}
                      />
                    </div>
                  </div>
                  <Button
                    className={'delete'}
                    label={strings('delete_class')}
                    color={ButtonColors.gray}
                    icon={icons.DELETE}
                    action={handleDeleteItem('classes', index)}
                  />
                  <div className={'separator'} />
                </li>
              ))}
          </ul>
          <div className={'summary'}>
            <Button
              className={'add'}
              label={strings('add_class')}
              color={ButtonColors.green500}
              icon={icons.ADD}
              action={handleAddItem('classes')}
            />
            <div className={'wrapper'}>
              <p className={'label'}>{strings('composition')}</p>
              <p className={'value'}>{totalStudents}</p>
            </div>
          </div>
          <p className={'label'}>
            {type !== projectTypeKeys.XL
              ? strings('dedicated_time_annual')
              : strings('dedicated_time')}
          </p>
          <ul className={'times'}>
            {formData &&
              formData.times &&
              formData.times.map((item, index) => (
                <li key={item.key} className={`time_${index}`}>
                  <div className={'wrapper'}>
                    <div className={'container first'}>
                      <p className={'index'}>{index + 1}</p>
                      <DropDown
                        label={'time'}
                        current={item.time ? item.time.label : null}
                        data={times.map((item) => item.label)}
                        className={`time ${item.time && item.time.onError ? 'invalid' : ''}`}
                        handleClickItem={handleDropDownChanged('times', index, times)}
                      />
                    </div>
                    <div className={'container second'}>
                      <div className={'label-wrapper'}>
                        <p className={'volume'}>{strings('volume')}</p>
                        {/* <p className={'annual'}>{strings('annual')}</p> */}
                      </div>
                      <Input
                        label={strings('password')}
                        className={`time ${item.volumeOnError ? 'invalid' : ''}`}
                        type={'number'}
                        tag={'volume'}
                        defaultValue={item.volume}
                        handleValueChanged={handleInputChanged('times', index)}
                        unit={'H'}
                      />
                    </div>
                  </div>
                  <Button
                    className={'delete'}
                    label={strings('delete_time')}
                    color={ButtonColors.gray}
                    icon={icons.DELETE}
                    action={handleDeleteItem('times', index)}
                  />
                  <div className={'separator'} />
                </li>
              ))}
          </ul>
          <div className={'summary'}>
            <Button
              className={'add'}
              label={strings('add_time')}
              color={ButtonColors.green500}
              icon={icons.ADD}
              action={handleAddItem('times')}
            />
            <div className={'wrapper'}>
              <p className={'label'}>
                {type !== projectTypeKeys.XL
                  ? strings('total_annual_volume')
                  : strings('total_volume')}
              </p>
              <p className={'value'}>{`${totalVolume}H`}</p>
            </div>
          </div>
          <div className={'save'}>
            <Button label={strings('save')} color={ButtonColors.green500} action={handleSave} />
          </div>
        </div>
      </div>
    );
  },
);
