import React, { useState } from "react";
import { icons } from "../../../../../assets";
import "./user-item.scss";

export const UserItem = ({ user }) => {
  const [displayPhone, setDisplayPhone] = useState(false);

  return (
    <div id={"userItem"} key={user.id}>
      <div className={"user-item-name"}>{`${user.firstName} ${user.lastName}`}</div>
      {(user.phone || user.email) && (
        <div className={"user-item-contact"}>
          {user.email && (
            <i className={"material-icons"} onClick={() => (window.location.href = `mailto:${user.email}`)}>
              {icons.MAIL}
            </i>
          )}
          {user.phone && (
            <i
              className={"material-icons phone"}
              onClick={() => window.open(`tel:${user.phone}`)}
              onMouseEnter={() => setDisplayPhone(true)}
              onMouseLeave={() => setDisplayPhone(false)}
            >
              {icons.PHONE}
              <span className={`user-phone ${displayPhone && "displayed"}`}>{user.phone}</span>
            </i>
          )}
        </div>
      )}
    </div>
  );
};
