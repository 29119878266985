import moment from 'moment';

export const modifiersStyles = {
  sundays: {
    color: '#535D5F',
    backgroundColor: '#FDB614',
    borderRadius: '2px',
    border: 'none'
  },
  allDays: {
    color: '#535D5F',
    backgroundColor: 'rgba(239,242,244,0.5)',
    borderRadius: '2px',
    padding: '10px',
  },
  today: {
    color: 'white',
    backgroundColor: '#FD8204',
    border: 'none',
  },
  daysWithEvents: {
    color: 'white',
    backgroundColor: '#00A0AF',
    border: 'none',
  },
  selectedDay: {
    color: 'white',
    backgroundColor: '#1DB46C',
    border: 'none',
  }
};

export const modifiers = (meetings, selectedDay) => ({
  /* L'odre est important à cause de la surcharge via les "modifiersStyles", comme en CSS */
  sundays: { daysOfWeek: [0] },
  allDays: { daysOfWeek: [1, 2, 3, 4, 5, 6] },
  daysWithEvents: meetings.datesForCalendar.map(m => moment(m.date).toDate()),
  today: new Date(),
  selectedDay: moment(selectedDay).toDate(),
});