import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../../utils/constants';
import './successStyle.scss';
import { icons, images } from '../../../../../../assets';
import { splitString } from '../../../../../../utils/splitString';
import { userRole } from '../../../../../../utils';
const UnachevedSuccessItem = ({
  success,
  succesDetail,
  isListSucces = false,
  removeSuccess,
  role,
}) => {
  const { id, name, obligatory, validationDate, ressources, illustration } = success;
  const validDate = validationDate ? moment(validationDate).format(DATE_FORMAT) : '';
  return (
    <div className={'unachevedsuccess-item'} onClick={() => succesDetail(id)}>
      <div className={'header-content'} onClick={() => succesDetail(id)}>
        <img src={illustration || images.success2} alt={name || 'logo'} />
      </div>
      <div className={'success-detail'}>
        <h4 onClick={() => succesDetail(id)}> {splitString(name, 100)} </h4>
        <div className={'actions'}>
          <div className={'medias'}>
            {(isListSucces && !obligatory && (role === userRole.SUPERVISOR || role === userRole.ADMIN || role === userRole.FACILITATOR)) ? (
            
                <i className={'material-icons deleteIcon'} onClick={() => removeSuccess(id)}>
                {' '}
                {icons.DELETE}{' '}
              </i> 
            ): null}
            {ressources?.documents.length > 0 && (
              <i className={'material-icons'} onClick={() => succesDetail(id)}>
                {' '}
                {icons.FILE}{' '}
              </i>
            )}
            {ressources?.videos.length > 0 && (
              <i className={'material-icons'} onClick={() => succesDetail(id)}>
                {' '}
                {icons.PLAY}{' '}
              </i>
            )}
          </div>
          <div className={'btn'} onClick={() => succesDetail(id)}>
            <i className={'material-icons fill-1'}> {icons.ARROW_FORWARD} </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnachevedSuccessItem;
