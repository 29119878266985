import React, { useRef, useState } from 'react';
import { icons } from '../../../../assets';
import { Button, ButtonColors } from '../../../components';

const Successes = ({ strings, progress, successes, showSuccessDetail }) => {
  return (
    <div className={'successes'}>
      <div className={'header row'}>
        <p className={'label'}>{strings('successes')}</p>
        <div className={'button-wrapper'}>
          <Button color={ButtonColors.orange} label={strings('manage')} action={showSuccessDetail} />
        </div>
      </div>
      <div className={`progress current`}>
        <div className={'bar'}>
          <div style={{ width: `${progress}%` }} className={'inner'} />
        </div>
      </div>
      {successes.map((success, index) => (
        <div key={index} className={'success row'}>
          <i className={`material-icons ${success.validated ? 'validated' : ''}`}>{success.validated ? icons.CHECK : icons.SCHEDULE}</i>
          <div className={'label'}>{success.label}</div>
          <div className={'date'}>{success.validDate}</div>
        </div>
      ))}
    </div>
  );
};

export default Successes;
