import React, { Fragment } from "react";
import { userRole } from "../../../../utils";
import "./user-item.scss";

export const UserItem = ({ user, strings }) => {
  const getRole = (role) => {
    switch (role) {
      case userRole.MENTOR:
        return strings("mentor");
      case userRole.SUPERVISOR:
        return strings("supervisor");
      case userRole.STUDENT:
        return strings("student");
      case userRole.FACILITATOR:
        return strings("facilitator");
      default:
        return "";
    }
  };

  return (
    <div className={"user-item"}>
      <p className={"role"}>{getRole(user.role)}</p>
      <p className={"identity"}>{`${user.civility === "MR" ? strings("mister") : strings("misses")} ${user.firstName} ${user.lastName}`}</p>
      <Fragment>
        {user.phone && <p>{user.phone}</p>}
        {user.email && <p>{user.email}</p>}
      </Fragment>
    </div>
  );
};
