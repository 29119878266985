import React,{useState,useEffect} from 'react'
import { info } from 'sass'
import "./circularProgress.scss"
const CircularProgress = ({ value }) => {

  return (
    <div className={"circular-progress"} style={{ background: `conic-gradient(#00763D ${value * 3.6}deg, #F8F8F9 ${value * 3.6}deg )`}}  >
      <div className="value-container"> {`${value} %`} </div>
    </div>
  )
}

export default CircularProgress