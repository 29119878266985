import { connect } from 'react-redux';
import { ProjectsActions, ProjectsSelectors } from '../../../../redux/projects';
import { AddBudget } from './AddBudget';

const mapStateToProps = state => ({
    operationTypes: ProjectsSelectors.getOperationTypes()(state)
});

const mapDispatchToProps = dispatch => ({
    addBudget: (projectId, budget) => dispatch(ProjectsActions.addBudgetRequested(projectId, budget)),
    updateBudget: (projectId, budgetId, budget) => dispatch(ProjectsActions.updateBudgetRequested(projectId, budgetId, budget))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBudget);