import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { icons, images } from '../../../../assets';
import { ProjectsActions } from '../../../../redux/projects';
import { getIcon, projectTypeKeys, Routes, userRole } from '../../../../utils';
import { splitString } from '../../../../utils/splitString';
import { parseSuccessFromExcel } from '../../../../utils/success';
import { Button, ButtonColors, ScreenHeader } from '../../../components';
import CircularProgress from '../../../components/progress-bar/circle/CircularProgress';
import EditBlock from './blocEdit/EditBlock';
import './project-detail.scss';
import PaginatedItems from './successItem/PaginatedItems';
import Avatar from '@mui/material/Avatar';
const initialState = {
  id: 0,
  name: '',
  description: '',
  avatar: '',
};
const offSetItem = {
  id: null,
  name: null,
  validationDate: null,
  validated: false,
  offset: true,
  other_name: null,
  ressources: {
    videos: [],
    documents: [],
  },
  illustration: null,
};
export const ProjectDetail = React.memo(
  ({
    match,
    resetProjectDetail,
    getProject,
    project,
    strings,
    navigateTo,
    role,
    meetings,
    wonSuccesses,
    unValidatedSuccesses,
    data,
    getDataLists,
  }) => {
    const dispatch = useDispatch();
    const value = (val) => (
      <p className={`${val < 0 ? 'negative' : ''}`}>
        {val.toLocaleString(navigator.language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + strings('currency')}
      </p>
    );
    const { id, budget, links, type } = project;
    const [showCollectionPopup, setShowCollectionPopup] = useState(true);
    const [projectName, setProjectName] = useState(project.name);
    const [projectDescription, setProjectDescription] = useState(project.description);
    const [projectAvatar, setProjectAvatar] = useState(project.avatar);
    const [showEdit, setShowEdit] = useState(false);
    const [editMode, setEditMode] = useState(initialState);
    const [callBack, setCallBack] = useState(false);
    const [invalidateSuccess, setInvalidateSuccess] = useState(unValidatedSuccesses);
    const [canDisablePopup, setCanDisablePopup] = useState(false);
    const { total } = budget;
    const { bank } = total;
    useEffect(() => {
      resetProjectDetail();
      handleCanDisablePopup();
      getDataLists();
      setInvalidateSuccess(unValidatedSuccesses);
    }, []);

    const handleCanDisablePopup = () => {
      if (userRole.SUPERVISOR === role) {
        setCanDisablePopup(true);
      }
    };

    useEffect(() => {
      const { projectId } = match.params;
      getProject(projectId);
      setEditMode({
        ...editMode,
        id: project.id,
        name: project.name,
        description: project.description,
        avatar: project.avatar,
      });
      setInvalidateSuccess(unValidatedSuccesses);
    }, [getProject, match.params]);

    useEffect(() => {
      const { projectId } = match.params;
      getProject(projectId);
      setProjectName(project.name);
      setProjectDescription(project.description || '');
      setProjectAvatar(project.avatar || '');
      setInvalidateSuccess(unValidatedSuccesses);
    }, [project.name, setProjectName, setProjectDescription, setProjectAvatar]);
    useEffect(() => {
      const { projectId } = match.params;
      getProject(projectId);
    }, [callBack]);
    const handleAddData = () => {
      if (id) {
        navigateTo(Routes.DATA.replace(':projectId', id));
      }
    };
    const handleSuccesDetail = (id) => {
      navigateTo(Routes.SUCCESS_DETAIL.replace(':succesId', id));
    };
    const handleClosePopup = () => {
      setShowCollectionPopup(false);
    };
    const handleShowEditForm = () => {
      setProjectName(project.name);
      setProjectAvatar(project.avatar);
      setProjectDescription(project.description);
      setShowEdit(true);
    };
    const handleHideEditForm = () => {
      const { projectId } = match.params;
      getProject(projectId);
      setShowEdit(false);
    };
    const handleChoiseAvatar = (url) => {
      setProjectAvatar(url);
      setEditMode({ ...editMode, avatar: url });
    };
    const handleInputChange = (e) => {
      const { value, name } = e.target;
      setEditMode({ ...editMode, [name]: value });
      name === 'name' && setProjectName(value);
      name === 'description' && setProjectDescription(value);
    };
    const updateProjectName = (projectId, name, description, avatar) =>
      dispatch(ProjectsActions.updateProjectRequested(projectId, name, description, avatar));
    const handleEditForm = () => {
      const { projectId } = match.params;
      const id = parseInt(projectId, 10);
      updateProjectName(id, projectName, projectDescription, projectAvatar);
      setCallBack(!callBack);
      handleHideEditForm();
    };

    return (
      <div id={'projectDetail'}>
        <ScreenHeader
          showFilters={false}
          screenName={'projectList'}
          showBackAction={role !== userRole.STUDENT}
          arrowAction={() => navigateTo(Routes.PROJECT_LIST)}
          handleDataCollection={false}
          handleFilterChange={false}
        />
        <div className={'bloc fade'}>
          <div className={'descriptionBoc'}>
            <div className={'description_left'}>
              <h1 className={'title'}> {`${splitString(`Bonjour, ${project.name}`, 64)}`}</h1>
              <p>{splitString(project.description, 230)}</p>
            </div>
            <div className={`description_right ${project.avatar === '' && 'background'}`}>
              <div className={'pictogram'} onClick={handleShowEditForm}>
                <Avatar
                  alt="Profil"
                  src={project.avatar || images.profilProject}
                  sx={{ width: 90, height: 90 }}
                />
              </div>
              <div className={'pencil'}>
                <i className={'material-icons'} onClick={handleShowEditForm}>
                  {icons.EDIT}
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className={'main fade'}>
          <div className={`successes `}>
            <div className={'detail-header'}>
              <h2>{`${strings('successes')} de la Mini-Entreprise®`}</h2>
              <p>
                Retrouvez ici les différentes tâches à accomplir dans la progression de la
                Mini-Entreprise®.
              </p>
            </div>
            <div className={'successes-container'}>
              <div className={'left'}>
                <div className={'title'}>Progression</div>
                <div className={'progress'}>
                  <CircularProgress value={wonSuccesses} />
                </div>
              </div>
              <div className={'right'}>
                <div className={'label'}>
                  <div>
                    <p className={'texte'}>Il reste</p>
                    <p className={'number'}>{invalidateSuccess.length}</p>
                  </div>
                  <p className={'others'}>succès à accomplir</p>
                </div>
                <div className={'desktop_successes_list'}>
                  {invalidateSuccess.length > 0 && wonSuccesses < 100 ? (
                    <PaginatedItems
                      action={() => navigateTo(Routes.PROJECT_SUCCESS.replace(':projectId', id))}
                      succesDetail={handleSuccesDetail}
                      items={[
                        ...parseSuccessFromExcel(
                          invalidateSuccess.length <= 8
                            ? invalidateSuccess
                            : invalidateSuccess.slice(0, 8),
                          type,
                        ),
                        offSetItem,
                      ]}
                      itemsPerPage={2}
                      pageRange={2}
                    />
                  ) : wonSuccesses === 100 ? (
                    <img src={images.winsuccess} alt={'winsuccess'} />
                  ) : (
                    ''
                  )}
                </div>
                <div className="phone_success_footer"></div>
              </div>
            </div>
            <div className={'action-btn'}>
              <Button
                label={strings('all_successes')}
                color={ButtonColors.green500}
                icon={icons.ADD}
                className={'all-successes-btn'}
                action={() => navigateTo(Routes.PROJECT_SUCCESS.replace(':projectId', id))}
              />
            </div>
          </div>
          {project.type === projectTypeKeys.M9 ? (
            ''
          ) : (
            <div className={'planning no-marge'}>
              <div className={'detail-header'}>
                <h2>{strings('planning')}</h2>
              </div>
              {meetings.length > 0 ? (
                <ul className={'meeting-list'}>
                  {meetings.map((event) => (
                    <li className={'meeting-item'} key={event.id}>
                      <div className={'title'}>
                        <i className={'material-icons'}> {icons.CALENDAR} </i>
                        <p> {event.date} </p>
                      </div>
                      <div className={'description'}> {event.description} </div>
                      <hr />
                    </li>
                  ))}
                </ul>
              ) : (
                <Fragment>
                  <img src={images.planning} alt={'Planning'} />
                  <p>{strings('no_event')}</p>
                </Fragment>
              )}
              <Button
                label={meetings.length > 0 ? strings('all_events') : strings('add_event')}
                color={ButtonColors.green500}
                icon={icons.ADD}
                action={() => navigateTo(Routes.PLANNING.replace(':projectId', project.id))}
              />
            </div>
          )}
        </div>
        <div className={'ressources'}>
          <div className={'left fade'}>
            <div className={'title'}>
              <h2>{strings('shared_links')}</h2>
            </div>
            <ul className={'links-list'}>
              {links &&
                links.map((link) => (
                  <li key={link.url} onClick={() => window.open(link.url, '_blank')}>
                    {getIcon(link.icon)}
                    <p className={'link-title'}>{link.label}</p>
                  </li>
                ))}
            </ul>
            <div className={'manage-bt'}>
              <div className={'manage-bt-links'}>
                <Button
                  label={strings('add')}
                  color={ButtonColors.green500}
                  icon={icons.ADD}
                  action={() => navigateTo(Routes.LINKS.replace(':projectId', id))}
                />
              </div>
            </div>
          </div>
          <div className={'left fade add-icon'}>
            <img src={images.monney} alt={'Monney'} />
            <div className={'title'}>
              <h2>{`Suivi financier de la Mini-Entreprise®`}</h2>
            </div>
            <div className={'budget'}>
              <div className={'budget-item'}>
                <div className={'budget-item-title'}> {strings('budget_balance')} </div>
                <div className={'budget-item-content'}> {value(bank)}</div>
              </div>
              {/* <div className={'budget-item'}>
                <div className={'budget-item-title'}> {strings('FUND')} </div>
                <div className={'budget-item-content'}> {value(fund)}</div>
              </div> */}
            </div>
            <div className={'manage-bt'}>
              <div className={'manage-bt-links'}>
                <Button
                  label={strings('add_budget')}
                  icon={icons.ADD}
                  color={ButtonColors.green500}
                  action={() => navigateTo(Routes.PROJECT_BUDGET.replace(':projectId', id))}
                />
              </div>
            </div>
          </div>
        </div>
        {role !== userRole.STUDENT && project && project.data && !data && (
          <div className={'data unfilled'}>
            <div className={'wrapper'}>
              <img src={images.schedule} alt={'collecte de données'} />
              <p>{strings('project_data_unfilled')}</p>
            </div>
            <div className={'wrapper'}>
              <div className={'action-wrapper'} onClick={handleAddData}>
                <i className={'material-icons'}>{icons.ARROW_FORWARD}</i>
                <p>{strings('project_data_unfilled_action')}</p>
              </div>
            </div>
          </div>
        )}
        {role !== userRole.STUDENT && project && project.data && !data && (
          <div className={`popup-wrapper ${showCollectionPopup ? 'shown' : 'hidden'}`}>
            <div className={'popup'}>
              {!canDisablePopup ? (
                <i className={'material-icons close'} onClick={handleClosePopup}>
                  {icons.CLOSE}
                </i>
              ) : null}

              <p>{strings('project_data_collection')}</p>
              <div className={'actions-wrapper'}>
                {!canDisablePopup ? (
                  <Button
                    label={strings('project_data_remind')}
                    color={ButtonColors.gray}
                    action={handleClosePopup}
                  />
                ) : null}
                <Button
                  label={strings('project_data_fill')}
                  color={ButtonColors.green500}
                  action={handleAddData}
                />
              </div>
            </div>
          </div>
        )}
        {role !== userRole.STUDENT && project && project.data && data && (
          <div className={'data filled'}>
            <div className={'header'}>
              <p className={'label'}>{strings('project_working_group')}</p>
              <p className={'manage-bt'} onClick={handleAddData}>
                <i className={'material-icons'}>{icons.EDIT}</i>
              </p>
            </div>
            <div className={'info'}>
              <ul>
                {data.classes.map((item, index) => (
                  <li className={'class'} key={index}>
                    <div className={'wrapper'}>
                      <p className={'label'}>{strings('category')}</p>
                      <p className={'value'}>{item.category}</p>
                    </div>
                    <div className={'wrapper'}>
                      <p className={'label'}>{strings('level')}</p>
                      <p className={'value'}>{item.level}</p>
                    </div>
                    <div className={'wrapper'}>
                      <p className={'label'}>{strings('sector')}</p>
                      <p className={'value'}>{item.sector}</p>
                    </div>
                    <div className={'wrapper'}>
                      <p className={'label'}>{strings('composition')}</p>
                      <div className={'composition'}>
                        <p className={'value number'}>{item.total}</p>
                        <div className={'count'}>
                          <div className={'item'}>
                            <img src={images.girls} alt={item} />
                            <p className={'value'}>{item.girlsCount}</p>
                          </div>
                          <div className={'item'}>
                            <img src={images.boys} alt={item} />
                            <p className={'value'}>{item.boysCount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <ul>
                {data.times.map((item, index) => (
                  <li className={'time'} key={index}>
                    <div className={'time-container'}>
                      <div className={'volume'}>
                        <div className={'volume-left'}>
                          <p className={'label'}>{strings('annual_volume')}</p>
                        </div>
                        <div className={'volume-right'}>
                          <p className={'label'}>{`${item.volume}H`}</p>
                          <p className={'label'}>
                            <i className={`material-icons`}>{icons.SCHEDULE} </i>
                          </p>
                        </div>
                      </div>
                      <div className={'matiere'}>
                        <p className={'title'}>{strings('topic')}</p>
                        <p className={'value'}>{item.time}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <EditBlock
          show={showEdit}
          avatar={projectAvatar}
          name={projectName}
          hideForm={handleHideEditForm}
          choiseAvatar={handleChoiseAvatar}
          description={projectDescription}
          editBlock={handleEditForm}
          inputChange={handleInputChange}
        />
      </div>
    );
  },
);
