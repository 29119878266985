import {select, takeLatest} from 'redux-saga/effects';
import actions, { types } from './actions';

function* confirmPopup() {
  const popupAction = yield select(state => state.confirmPopup.actionPopup);
  yield popupAction()
}

export default [
  takeLatest(types.CONFIRM_CONFIRM_POPUP, confirmPopup),
  takeLatest(types.REFUSE_CONFIRM_POPUP, actions.hidePopup),
];
