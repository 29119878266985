import { Links } from './Links';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { push } from 'react-router-redux';
import { ProjectsActions } from '../../../redux/projects';
import { ConfirmPopupActions } from '../../../redux/confirmPopup';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  links: state.projects.project.links,
  projectId: state.projects.project.id,
  linkInError: state.projects.linkInError,
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
  getProject: projectId => dispatch(ProjectsActions.getProjectDetailRequested(projectId)),
  addLink: (projectId, link) => dispatch(ProjectsActions.addLinkRequested(projectId, link)),
  updateLink: (projectId, linkId, link) => dispatch(ProjectsActions.updateLinkRequested(projectId, linkId, link)),
  deleteLink: (projectId, linkId) => dispatch(ProjectsActions.deleteLinkRequested(projectId, linkId)),
  showPopup: (message, popupAction) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  resetErrors: () => dispatch(ProjectsActions.setLinkInError(false)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Links);