export const icons = {
  CANCEL: 'cancel',
  CLOSE: 'close',
  EDIT:'edit',
  MAIL: 'mail_outline',
  PHONE: "phone",
  ARROW_FORWARD: "arrow_forward",
  ARROW_BACK: 'arrow_back',

  ARROW_BACKIOS: "arrow_back_ios",
  ARROW_FORWARD_IOS: "arrow_forward_ios_outlined_icon",
  THREE_DOT: 'more_vert',
  MENU: 'menu',
  LOGOUT: 'power_settings_new',
  SETTINGS: 'settings',
  ADD: 'add',
  CHECK: 'check',
  CHECK_CIRCLE: 'check_circle_icon',
  SCHEDULE: 'schedule',
  CALENDAR: 'date_range',
  DELETE: 'delete',
  ARROW_DROPDOWN: 'arrow_drop_down',
  EYE: 'remove_red_eye',
  PROFILE: 'person',
  ACCOUNT:'account_circle_icon',
  NOTIF: 'notifications',
  LOCK: 'lock',
  LINK: 'link',
  SCHOOL: 'school',
  CHEVRON_RIGHT: 'chevron_right',
  DOWNLOAD: 'file_download',
  FILE: ' insert_drive_file_icon',
  PLAY:'play_circle_icon'
};
