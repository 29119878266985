import moment from 'moment';
import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
};

const getNotificationsSucceeded = (state = initialState, action) => {
  const {list} = action;

  return {...state, list};
};

const setNotificationsReadSucceeded = (state = initialState, action) => {
  const {list} = action;

  return {...state, list};
};

export default createReducer(initialState, {
  [types.GET_NOTIFICATIONS_SUCCEEDED]: getNotificationsSucceeded,
  [types.SET_NOTIFICATIONS_READ_SUCCEEDED]: setNotificationsReadSucceeded,
});
