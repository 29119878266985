import { getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { Menu } from './Menu';
import { navigationMenuGuides } from '../../../../utils/enum';
import { push } from 'react-router-redux';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isSuccess: isSuccess(state.router.location.pathname),
  guides: navigationMenuGuides
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});
const isSuccess = path => /\d/.test(path.split('/')[2]) && path.includes('success');

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
