import React, { useRef, useState } from 'react';
import { icons } from '../../../../assets';

const Progress = ({ strings, roomId, rooms }) => {
  return (
    <div className={'progress'}>
      <p className={'label'}>{strings('other_rooms')}</p>
      {rooms.map((room, index) => (
        <div className={'column'}>
          <div className={'row'}>
            <p className={'name'}>{`${strings('room_number')}${index + 1} ${room.id === roomId ? strings('current_room') : ''}`}</p>
            <div className={'value'}>{room.progress}%</div>
          </div>
          <div className={`progress ${room.id === roomId ? 'current' : ''}`}>
            <div className={'bar'}>
              <div style={{ width: `${room.progress}%` }} className={'inner'} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Progress;
