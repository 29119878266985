import { put, takeLatest } from 'redux-saga/effects';
import { CommonActions } from '../common';
import { types } from './actions';

function* logout() {
  yield put(CommonActions.resetReducers());
  // We don't make a yield call(history.replace, Routes.DEFAULT_ROUTE) to cancel api call automatically
  window.location = `${process.env.REACT_APP_AUTH_URL}?action=logout`;
}

export default [
  takeLatest(types.LOGOUT_REQUESTED, logout),
];
