import { UserItem } from './UserItem';
import { userRole } from '../../../../../utils';

export default UserItem;

export const getRole = (role, strings, isPlural) => {
  switch (role) {
    case userRole.MENTOR:
      return isPlural ? strings("mentors") : strings("mentor");
    case userRole.SUPERVISOR:
      return isPlural ? strings("supervisors") : strings("supervisor");
    case userRole.STUDENT:
      return isPlural ? strings("students") : strings("student");
    case userRole.FACILITATOR:
      return isPlural ? strings("facilitators") : strings("facilitator");
    case userRole.ADMIN:
      return isPlural ? strings("admins") : strings("admin");
    default:
      return ''
  }
};

