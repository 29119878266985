import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { DetailBudgetList } from './DetailBudgetList';
import { goBack } from 'react-router-redux';
import { ProjectsActions } from '../../../redux/projects';
import { ConfirmPopupActions } from '../../../redux/confirmPopup';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  project: state.projects.project,
  operationTypes: state.projects.operationTypes,
  role: state.user.user.role
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  getProject: projectId => dispatch(ProjectsActions.getProjectDetailRequested(projectId)),
  getOperationTypes: (inBackground) => dispatch(ProjectsActions.getOperationTypesRequested(inBackground)),
  deleteBudget:  (projectId, budgetId) => dispatch(ProjectsActions.deleteBudgetRequested(projectId, budgetId)),
  downloadBudgetEntries:  (projectId) => dispatch(ProjectsActions.downloadBudgetRequested(projectId)),
  showPopup: (message, popupAction) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction))
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailBudgetList);