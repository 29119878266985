import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {ProjectsActions} from "../../../../redux/projects";
import { ProjectList } from './ProjectList';
import { push } from 'react-router-redux';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  projects: state.projects.list,
  currentUserId: state.user.user.id,
  role: state.user.user.role
});

const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(ProjectsActions.getProjectsRequested()),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);

