import { ProjectDetail } from './projectDetail';
import { connect } from 'react-redux';
import { ProjectsActions, ProjectsSelectors } from '../../../../redux/projects';
import { createAction } from '../../../../utils';
import { getTranslate } from 'react-localize-redux';
import { push } from 'react-router-redux';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  project: state.projects.project,
  role: state.user.user.role,
  meetings: ProjectsSelectors.formatComingMeetings()(state),
  wonSuccesses: ProjectsSelectors.getPourcentageOfWonSuccesses()(state),
  firstsSuccesses: ProjectsSelectors.getFirstSuccesses()(state),
  validatedSuccesses: ProjectsSelectors.getValidatedSuccesses()(state),
  unValidatedSuccesses: ProjectsSelectors.getUnValidatedSuccesses()(state),
  data: ProjectsSelectors.getData()(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetProjectDetail: () => createAction(dispatch(ProjectsActions.resetProjectDetail())),
  getProject: (projectId) =>
    createAction(dispatch(ProjectsActions.getProjectDetailRequested(projectId))),
  getDataLists: () => createAction(dispatch(ProjectsActions.getDataListsRequested())),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
