export const filterProjectsByStatus = (projects, selectedFilter) => {
  let filtered = [];

  if (selectedFilter === "ALL") {
    filtered = projects;

  } else if (selectedFilter === "VALIDATED") {
    const projectValidated = projects.filter((project) => project.status === "VALIDATED");
    const miniRunning = projects.filter((project) => project.projectType === "MINI" && project.status === "RUNNING");
    filtered = projectValidated.concat(miniRunning);

  } else {
    filtered = projects.filter((project) => project.status === selectedFilter);
  }

  return filtered;
};
