import React, { useState } from 'react';
import { icons } from '../../../../assets';
import './meetings.scss';
import moment from 'moment';
import { meetingFilteringType } from '../../../../utils';

export const Meetings = React.memo(({ strings, meetings, handleOpenConfirm, setSelectedDay,selectedDay, setMeeting,setIsCreatingMeeting,filter, setFilter }) => {


  const handleClickMeetingItem = (m) => {
    setSelectedDay(moment(m.date, ['D MMMM YYYY']).format());
    setMeeting({ id: m.id, date: m.date, description: m.description });
    setIsCreatingMeeting(true)
  };

  const handleClickDeleteMeetingItem = (e, m) => {
    e.stopPropagation();
    handleOpenConfirm(m);
  };

  const handleFilterChange = (newFilter) => () =>  setFilter(newFilter)
  let meetingList = [];
  switch (filter) {
    case meetingFilteringType.ALL:
      meetingList = meetings.all;
      break;
    case meetingFilteringType.COMING:
      meetingList = meetings.coming;
      break;
    case meetingFilteringType.MONTHLY:
      meetingList = meetings.monthly;
      break;
    case meetingFilteringType.CURRENT:
       meetingList = meetings.all.filter(item => item.date === moment(selectedDay).format('D MMMM YYYY')) 
      break;
  }

  return (
    <div className={'events fade'}>
      <div className={'filter'}>
        {
          filter === meetingFilteringType.CURRENT && 
        <p className={`option ${filter === meetingFilteringType.CURRENT ? 'selected' : ''}`} onClick={handleFilterChange(meetingFilteringType.CURRENT)}>{strings('current')}</p>
        }
        <p className={`option ${filter === meetingFilteringType.MONTHLY ? 'selected' : ''}`} onClick={handleFilterChange(meetingFilteringType.MONTHLY)}>{strings('current_month')}</p>
        <p className={`option ${filter === meetingFilteringType.COMING ? 'selected' : ''}`} onClick={handleFilterChange(meetingFilteringType.COMING)}>{strings('coming')}</p>
        <p className={`option ${filter === meetingFilteringType.ALL ? 'selected' : ''}`} onClick={handleFilterChange(meetingFilteringType.ALL)}>{strings('all_dates')}</p>
      </div>
      <hr />
      {meetingList && meetingList.length > 0 ? (
        <ul className={'meetings-list'}>
          {meetingList.map((m) => (
            <li className={`meeting-item`} key={m.id} onClick={() => handleClickMeetingItem(m)}>
              <div className={'body'}>
                <div className={'title'}>
                  <i className={'material-icons'}>{icons.CALENDAR}</i>
                  <p>{m.date}</p>
                </div>
                <div className={'description'}>{m.description}</div>
              </div>
              <i className={'material-icons delete'} onClick={(e) => handleClickDeleteMeetingItem(e, m)}>
                {icons.DELETE}
              </i>
            </li>
          ))}
        </ul>
      ) : (
        <p className={'no_event'} >{strings('no_event')}</p>
      )}
    </div>
  );
});
