import React, { useEffect } from "react";
import ContactItem from "./contactItem";
import "./contactList.scss";

export const ContactList = ({ getProjects, strings, currentUser, displayed, projects }) => {
  useEffect(() => {
    getProjects();
  }, [getProjects]);

  if (!projects) return null;

  return (
    <div id={"contacts"} className={`${displayed ? "displayed" : ""}`}>
      <div className={"container"}>
        {projects.map((project) => (
          <ContactItem project={project} strings={strings} key={project.id} currentUser={currentUser} />
        ))}
      </div>
    </div>
  );
};
