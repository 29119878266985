import React, { PureComponent } from 'react';
import './popup.scss';
import { Button, ButtonColors } from '../../buttons';
import { icons } from '../../../../assets/icons/materialIcons';

export class ConfirmPopup extends PureComponent {

  background = React.createRef();

  onBackground = (e) => {
    const {hidePopup} = this.props;

    if (this.background && this.background.current && !this.background.current.contains(e.target)) {
      hidePopup();
    }
    e.stopPropagation();
  };

  onValidate = () => {
    const {hidePopup, validatePopup} = this.props;

    validatePopup();
    hidePopup();
  };

  render() {
    const {strings, message, displayed, hidePopup} = this.props;

    return (
      <div id={`confirm-popup-background`}
           className={` ${displayed ? ' fade show' : 'hide'}`}
           onClick={this.onBackground}>
        <div id={'confirm-popup'} ref={this.background}
             className={` ${displayed ? 'show' : 'hide'}`}>
          <i className={'material-icons'} onClick={hidePopup}>{icons.CLOSE}</i>
          <p className={'message'}>{message}</p>
          <div className={'popup-actions'}>
            <Button label={strings('cancel')} className={'edit-button'} color={ButtonColors.orange}
                    action={hidePopup}/>
            <Button label={strings('validate')} className={'edit-button'} color={ButtonColors.green500}
                    action={this.onValidate}/>
          </div>
        </div>
      </div>
    );
  }
}

