import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { NotificationsActions } from '../../../redux/notifications';
import { default as Notifications } from "./Notifications";

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  notifications: state.notifications.list,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => dispatch(NotificationsActions.getNotificationsRequested()),
  setNotificationsRead: () => dispatch(NotificationsActions.setNotificationsReadRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
