import { CropLandscapeOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { icons } from '../../../../assets'
import { getDiffSuccesses, getSuccessFromExcel } from '../../../../utils/success'
import { Button, ButtonColors } from '../../../components'
import "./addSuccess.scss"
import AddSuccesItem from './succesItem/AddSuccesItem'

const AddSuccess = ({
  show,
  hideForm,
  successList,
  addedSuccess,
  type,
  addSuccesToProject,
  project,
}) => {
  const [succesSelected, setSuccesSelected] = useState([]);
  const [callBack, setCallBack] = useState(false);
  const handleSelectionChange = (success) => {
    const { id } = success;
    const existSuccess = succesSelected.filter((item) => item.id === id);
    if (existSuccess.length > 0) {
      setSuccesSelected(succesSelected.filter((item) => item.id !== id));
    } else {
      setSuccesSelected([...succesSelected, success]);
    }
  };
  const handleAdd = () => {
   const { id } = project
   const projectId = id

    try {
      if (succesSelected.length > 0) {
        const success = succesSelected.map((item) => ({ successId: item.id, name: item.name }));
         addSuccesToProject(projectId, success);
        //Si l'ajoute est effectué
        addedSuccess(succesSelected.length, true);
        hideForm();
        setSuccesSelected([]);
        setCallBack(!callBack);
      }
    } catch (error) {
      console.error(error)
    }
  };
  const handleClose = () => {
    setCallBack(!callBack);
    hideForm();
  };
  useEffect(() => {
    setSuccesSelected([]);
  }, [callBack]);
  return (
    <div className={`popup-wrapperss ${show ? 'shown' : 'hidden'}`}>
      <div className={'popupss'}>
        <div className={'header'}>
          <h2 className={'header-title'}>Ajouter des succès</h2>
          <i className={'material-icons close'} onClick={handleClose}>
            {icons.CLOSE}
          </i>
        </div>
        <div className={`succes-content ${successList.length === 0 ? 'center' : ''}`}>
          {successList.length > 0 ? (
            successList.map((success, index) => {
              return (
                <AddSuccesItem
                  key={index}
                  success={success}
                  handleSelectionChange={handleSelectionChange}
                  callBack={callBack}
                />
              );
            })
          ) : (
            <p>Aucun succès à ajouter</p>
          )}
        </div>

        <div className={'action-btn'}>
          <Button
            enabled={true}
            label={`Ajouter ${succesSelected.length > 0 ? succesSelected.length : ''} succès`}
            color={ButtonColors.green500}
            action={handleAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default AddSuccess
