import React, { useEffect, useRef } from 'react';
import './icon-picker.scss';
import { iconList, getIcon } from '../../../../../utils';

export const IconPicker = ({strings, isIconPickerVisible, setIsIconPickerVisible, handleSelectIcon}) => {
  const iconPicker = useRef(null);
  

  useEffect(() => {
    const handleClickOutside = e => iconPicker && iconPicker.current && !iconPicker.current.contains(e.target) && setIsIconPickerVisible();

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [iconPicker, setIsIconPickerVisible])

  return(
    <div className={`icon-picker fade ${isIconPickerVisible && 'visible'}`} ref={iconPicker}>
      <div className={'title'}>
      <p>{strings('choose_icon')}</p>
      </div>
      <ul className={'icon-list'}>
        {Object.entries(iconList).map(([key, value]) =>
          <li 
            className={'icon'}
            key={key}
            onClick={handleSelectIcon(value)}
          >
            {getIcon(key)}
            <p>{value}</p>
          </li>
        )}
      </ul>
    </div>
  )
}
