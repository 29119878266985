export const clearUrlParams = (url) => {
  const paramsIndex = url.indexOf('?');
  if (paramsIndex > 0) {
    return url.substring(0, paramsIndex);
  } else {
    return url;
  }
};
export const getFileFormat = (url) => {
  return url && url!=='#'? url.split('.').pop() : '';
}