import React, { Fragment, PureComponent } from 'react';
import { icons, images } from '../../../assets';
import { iconList, Routes, usePageViews, userRole } from '../../../utils';
import { Button, ButtonColors, ScreenHeader } from '../../components';
import { BsInfoCircleFill } from 'react-icons/bs';

import './detailSuccess.scss';
import VideoItem from './VideoItem';
import FileItem from './FileItem';
import ValidateSuccess from './ValidateSuccess_calendar/ValidateSuccess';
import moment from 'moment';
import ConfettiSuccess from '../../components/confetti/ConfettiSuccess';
import { saveAs } from 'file-saver';
import { parseSuccessFromExcel } from '../../../utils/success';
import { getFileFormat } from '../../../utils/url';
import { RollerShades } from '@mui/icons-material';

export class DetailSucces extends PureComponent {
  state = {
    successes: this.props.project
      ? parseSuccessFromExcel(this.props.project.successes, this.props.project.type) || []
      : [],
    show: false,
    success: null,
    selectedDay: new Date(),
    isConfetti: false,
  };

  onPalyVideo = (id) => {
    const { navigateTo, project } = this.props;
    const succesId = this.props.match.params.succesId;
    navigateTo(
      Routes.VIDEO_DETAIL.replace(':projectId', project.id)
        .replace(':succesId', succesId)
        .replace(':videoId', id),
    );
  };

  onShowConfetti = () => {
    const succesId = this.props.match.params.succesId;
    if (localStorage.getItem('successId') === succesId) {
      let time = 0;
      const interval = setInterval(() => {
        time++;
        this.setState({ isConfetti: true });
        if (time >= 3) {
          this.setState({ isConfetti: false });
          this.setState({ success: this.getSuccesDetail(succesId) });
          localStorage.setItem('successId', '');
          clearInterval(interval);
        }
      }, 1000);
    }
  };
  onClickValidate = (date) => {
    let { updateSuccesses, project } = this.props;
    let { id } = project;
    const { success, successes } = this.state;

    const validateSucces = successes.filter((success) => success.validated);

    const formattedSuccesses = [
      ...validateSucces,
      {
        ...success,
        validationDate: moment(date).toISOString(),
      },
    ];
    localStorage.setItem('successId', this.props.match.params.succesId);
    updateSuccesses(id, formattedSuccesses);
  };
  choiseDate = (date) => {
    this.setState({ selectedDay: date });
    this.onClickValidate(date);
    this.hideCalendar();
  };
  showCalendar = () => {
    this.setState({ show: true });
  };
  hideCalendar = () => {
    this.setState({ show: false });
  };

  getSuccesDetail(succesId) {
    const id = parseInt(succesId);
    return parseSuccessFromExcel(
      this.state.successes.filter((item) => item.id === id),
      this.props.project.type,
    )[0];
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.project && prevProps.project !== this.props.project) {
      this.setState({
        successes: parseSuccessFromExcel(this.props.project.successes, this.props.project.type),
      });
      this.onShowConfetti();
    }
  }
  componentDidMount() {
    const succesId = this.props.match.params.succesId;
    this.setState({ success: this.getSuccesDetail(succesId) });
    this.setState({
      successes: parseSuccessFromExcel(this.props.project.successes, this.props.project.type),
    });
    this.onShowConfetti();
  }
  removeSuccess = (id) => {
    const { project, updateSuccessesCancel, navigateTo, getProjectDetails } = this.props;
    const projectId = project.id;
    const successToCancel = [
      {
        id,
        validationDate: null
      }
    ];
    this.setState({ numberOfSuccess: 0, isAdded: false, isDeleted: true });
    updateSuccessesCancel(projectId, successToCancel);
    getProjectDetails(projectId);
    navigateTo(Routes.PROJECT_SUCCESS.replace(':projectId', project.id));
  };

  handleOpenConfirm = (id) => {
    this.props.showPopup(this.props.strings('confirmation_question_success_invalidation'), () =>
      this.removeSuccess(id),
    );
  };

  render() {
    const { pop, project, history } = this.props;
    const { success, isConfetti, url, title, selectedDay } = this.state;

    const screenName = history['oldLocation']
      ? history['oldLocation'].pathname.split('/').pop() == 'success'
        ? 'success_list'
        : 'dashboard'
      : 'sucess_detail';
    return (
      success && (
        <Fragment>
          <ConfettiSuccess show={isConfetti} numberOfPieces={500} />
          <div id={'detail-succes'}>
            <img className={'background'} src={images.epaBackground} alt={'background'} />
            <ScreenHeader screenName={screenName} arrowAction={pop} className={'navigation'} />
            {success && (
              <div
                className={`container fade ${
                  success.validated || isConfetti ? 'success' : 'white'
                }`}
              >
                <div className="title">
                  <h1>{success.name}</h1>
                </div>
                {success.validated ||
                  (isConfetti && (
                    <div className="success-message">
                      <p>Etape réalisée !</p>
                    </div>
                  ))}

                <p className="conseil"> {success.description} </p>
                {success?.ressources.videos && success?.ressources.videos.length > 0 && (
                  <div className="videos">
                    <h2>{`Vidéo(s)`}</h2>
                    <div className="video-container">
                      {success?.ressources.videos.map((item, index) => (
                        <VideoItem
                          validated={success.validated || isConfetti}
                          onPalyVideo={this.onPalyVideo}
                          key={index}
                          url={item.url}
                          title={item.title}
                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {success?.ressources.documents && success?.ressources.documents.length > 0 && (
                  <div className="documents">
                    <h2>{`Document(s)`}</h2>
                    <div className="document-container">
                      {success?.ressources.documents.map((item, index) => (
                        <FileItem
                          key={index}
                          title={item.title}
                          type={getFileFormat(item.url)}
                          url={item.url}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div className={'footer'}>
                  {success.validated &&
                    (this.props.role === userRole.SUPERVISOR ||
                      this.props.role === userRole.FACILITATOR ||
                      this.props.role === userRole.ADMIN) && (
                      <button
                        className={'action-button outlinedBtn'}
                        onClick={() => this.handleOpenConfirm(success.id)}
                      >
                        Annuler la validation du succès
                      </button>
                    )}
                  <Button
                    enabled={!isConfetti}
                    color={ButtonColors.green500}
                    label={`${success.validated ? 'Retour à la liste ' : 'Valider le succès'} `}
                    className={'action-button'}
                    action={success.validated ? pop : this.showCalendar}
                    icon={!success.validated && icons.CHECK}
                    reverse={!success.validated}
                  />
                </div>
              </div>
            )}
            <ValidateSuccess
              show={this.state.show}
              hide={this.hideCalendar}
              selectedDay={this.state.selectedDay}
              choiseDate={this.choiseDate}
            />
          </div>
        </Fragment>
      )
    );
  }
}
