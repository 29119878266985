export const successes10XData = [
  //---------------------------1--------------------------------//
  {
    id: 67,
    name: "Nous avons trouvé notre idée",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------2-------------------------------//
  {
    id: 68,
    name: "Nous avons finalisé notre business plan",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------3-------------------------------//
  {
    id: 69,
    name: "Nous avons finalisé notre questionnaire d'étude de marché",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------4-------------------------------//
  {
    id: 70,
    name: "Nous avons créé notre logo et l'avons déposé sur la plateforme pour le concours",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------5-------------------------------//
  {
    id: 71,
    name: "Nous avons réalisé notre logo",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------6-------------------------------//
  {
    id: 72,
    name: "Nous avons élaboré notre stratégie marketing",
    description:
   "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
    'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------7-------------------------------//
  {
    id: 73,
    name: "Nous avons élaboré notre stratégie de communication",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------8-------------------------------//
  {
    id: 74,
    name: "Nous avons défini nos objectifs de vente",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------9-------------------------------//
  {
    id: 75,
    name: "Nous avons trouvé nos fournisseurs",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------10-------------------------------//
  {
    id: 76,
    name: "Nous avons créé notre vidéo et l'avons déposé sur la plateforme pour le concours",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------11-------------------------------//
  {
    id: 77,
    name: "Nous avons trouvé notre espace de vente",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------12-------------------------------//
  {
    id: 78,
    name: "Nous avons imaginé notre stand de vente",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------13-------------------------------//
  {
    id: 79,
    name: "Nous nous sommes répartis les rôles pour la vente",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
  //---------------------------14-------------------------------//
  {
    id: 80,
    name: "Nous avons clôturé nos comptes",
    description:
     "Réalisez le succès puis validez-le grâce au bouton “valider le succès” en bas de page.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1665571483/Illustrations%20succ%C3%A8s/1OXL%20iliustration/EPA_Challenge_10X_q59s7d.png',
  },
];
