import moment from 'moment';
import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  linkInError: false,
  project: {
    id: null,
    code: null,
    name: '',
    description: null,
    avatar: null,
    password: null,
    school: null,
    type: null,
    users: [],
    successes: [],
    meetings: {
      coming: [],
      all: [],
    },
    budget: {
      total: {
        bank: 0,
        fund: 0
      },
      entrie: [],
    },
    links: [],
  },
  room: null,
  list: [],
  operationTypes: [],
  categories: [],
  levels: [],
  sectors: [],
  times: [],
  lastTimeRetrievingList: null,
  successAdded:false
};

const getProjectsSucceeded = (state = initialState, action) => {
  const {list} = action;

  return {...state, list};
};

const getProjectDetailSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project,successAdded: false };
};

const updateSuccessesSucceeded = (state = initialState, action) => {
  const {project} = action;

  return { ...state, project, successAdded: false };
};

const addMeetingSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project};
};

const deleteMeetingSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project};
};

const updateMeetingSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project};
};

const addBudgetSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project};
};

const deleteBudgetSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project};
};

const updateBudgetSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project};
};

const getOperationTypesSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, operationTypes: list};
};

const addLinkSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project, linkInError: false};
};

const updateLinkSucceeded = (state = initialState, action) => {
  const {project} = action;
  return {...state, project, linkInError: false};
};

const deleteLinkSucceeded = (state = initialState, action) => {
  const {project} = action;
  return { ...state, project, linkInError: false, successAdded: false };
};

const linkInError = (state = initialState, action) => {
  const {boolean} = action;
  return { ...state, linkInError: boolean, successAdded: false };
};

const resetProjectDetail = (state = initialState) => {
  return { ...state, project: initialState.project, successAdded: false };
};

const updateDataSucceeded = (state = initialState, action) => {
  const {project} = action;
  return { ...state, project, successAdded: false };
};

const getDataListSucceeded = (state = initialState, action) => {
  const {categories, levels, sectors, times} = action;
  return {
    ...state,
    categories,
    levels,
    sectors,
    times,
    lastTimeRetrievingList: moment().format('x'),
    successAdded: false,
  };
};

const resetRoomDetail = (state = initialState) => {
  return { ...state, room: initialState.room, successAdded: false };
};

const getMiniRoomSucceeded = (state = initialState, action) => {
  const {room} = action;
  return { ...state, room, successAdded: false };
};

const updateMiniRoomSuccessesSucceeded = (state = initialState, action) => {
  const {room} = action;
  return { ...state, room, successAdded: false };
};
const addSuccessSucceeded = (state = initialState, action) => {
   const { project } = action;
   return { ...state, project, successAdded:true };
}

const deleteSuccessSucceeded = (state = initialState, action) => {
  const { project } = action;
  return { ...state, project, successAdded: false };
};
export default createReducer(initialState, {
  [types.GET_PROJECTS_SUCCEEDED]: getProjectsSucceeded,
  [types.GET_PROJECT_DETAIL_SUCCEEDED]: getProjectDetailSucceeded,
  [types.PATCH_SUCCESS_SUCCEEDED]: updateSuccessesSucceeded,
  [types.ADD_MEETING_SUCCEEDED]: addMeetingSucceeded,
  [types.DELETE_MEETING_SUCCEEDED]: deleteMeetingSucceeded,
  [types.UPDATE_MEETING_SUCCEEDED]: updateMeetingSucceeded,
  [types.ADD_BUDGET_SUCCEEDED]: addBudgetSucceeded,
  [types.DELETE_BUDGET_SUCCEEDED]: deleteBudgetSucceeded,
  [types.UPDATE_BUDGET_SUCCEEDED]: updateBudgetSucceeded,
  [types.GET_OPERATION_TYPES_SUCCEEDED]: getOperationTypesSucceeded,
  [types.ADD_LINK_SUCCEEDED]: addLinkSucceeded,
  [types.UPDATE_LINK_SUCCEEDED]: updateLinkSucceeded,
  [types.DELETE_LINK_SUCCEEDED]: deleteLinkSucceeded,
  [types.SET_LINK_IN_ERROR]: linkInError,
  [types.UPDATE_DATA_SUCCEEDED]: updateDataSucceeded,
  [types.GET_DATA_LISTS_SUCCEEDED]: getDataListSucceeded,
  [types.RESET_PROJECT_DETAIL]: resetProjectDetail,
  [types.RESET_ROOM_DETAIL]: resetRoomDetail,
  [types.GET_MINI_ROOM_SUCCEEDED]: getMiniRoomSucceeded,
  [types.PATCH_MINI_ROOM_SUCCESS_SUCCEEDED]: updateMiniRoomSuccessesSucceeded,
  [types.RESET_ROOM_DETAIL]: resetRoomDetail,
  [types.ADD_SUCCESS_SUCCEEDED]: addSuccessSucceeded,
  [types.DELETE_SUCCESS_SUCCEEDED]: deleteSuccessSucceeded,
});
