import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { default as Password } from './Password';
import { UserActions } from '../../../../redux/user';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
  changePassword: body => dispatch(UserActions.changePasswordRequested(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Password);
