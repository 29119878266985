import { ProjectsActions } from '../../../redux/projects';
import { Navigation } from './Navigation';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { push } from 'react-router-redux';
import { userRole } from '../../../utils';
import { AuthActions } from '../../../redux/auth';
import { NotificationsSelectors } from '../../../redux/notifications';
import { navigationMenuGuides } from '../../../utils/enum';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isStudent: isStudent(state.user.user.role),
  isProjectDetail: isProjectDetail(state.router.location.pathname),
  isMiniDetail: isMiniDetail(state.router.location.pathname),
  isSuccess: isSuccess(state.router.location.pathname),
  projects: state.projects,
  project: state.projects.project,
  room: state.projects.room,
  newNotifCount: NotificationsSelectors.getNewNotifCount(state),
  guides: navigationMenuGuides
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
  updateProjectName: (projectId, name,projectDescription,projectAvatar) => dispatch(ProjectsActions.updateProjectRequested(projectId, name,projectDescription,projectAvatar)),
  logout: () => dispatch(AuthActions.logoutRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

// Selectors
const isStudent = role => role === userRole.STUDENT;
const isProjectDetail = path => /\d/.test(path.split('/')[2]) && path.includes('project');
const isSuccess = path => /\d/.test(path.split('/')[2]) && path.includes('success');
const isMiniDetail = path => /\d/.test(path.split('/')[2]) && /\d/.test(path.split('/')[4]) && path.includes('minis');
