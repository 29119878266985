import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { icons } from "../../../../assets";
import { Button, ButtonColors, IconInput } from "../../../components";
import "./password.scss";

const Password = ({ strings, user, changePassword }) => {
  const [form, setForm] = useState({onError: false});

  //Once the password have been changed, reset form
  useEffect(() => { setForm({onError: false}) }, [user]);

  const onValueChanged = (tag, value) => {
    const newForm = JSON.parse(JSON.stringify(form));
    newForm[tag] = value;
    newForm.onError = false;
    setForm(newForm);
  };

  const validateForm = () => {
    const newForm = JSON.parse(JSON.stringify(form));
    if (form.newPassword.length < 7) {
      newForm.onError = true;
    }

    if (form.newPassword !== form.validationPassword) {
      newForm.onError = true;
    }

    setForm(newForm);
    
    if (!newForm.onError) {
      changePassword({oldPassword: form.currentPassword, newPassword: form.newPassword})
    }
  };

  const enableButton = !!form.currentPassword && !!form.newPassword && !!form.validationPassword;

  return (
    <div className={"password-form"}>
      <p>{strings("change_password")}</p>
      <div className={"separator"} />
      <div className={"wrapper half"}>
      <IconInput
          label={strings('current_password')}
          type={'password'}
          tag={'currentPassword'}
          icon={icons.EYE}
          defaultValue={form.currentPassword}
          handleValueChanged={onValueChanged}
        />
      </div>
      <div className={"wrapper"}>
      <IconInput
          label={strings('new_password')}
          type={'password'}
          tag={'newPassword'}
          icon={icons.EYE}
          defaultValue={form.newPassword}
          inputClassName={form.onError && form.newPassword.length < 7 ? 'invalid' : ''}
          error={form.onError && form.newPassword.length < 7 ? strings('error_password_length') : null}
          handleValueChanged={onValueChanged}
        />
        <IconInput
          label={strings('validation_password')}
          type={'password'}
          tag={'validationPassword'}
          icon={icons.EYE}
          defaultValue={form.validationPassword}
          inputClassName={form.onError && form.newPassword.length >= 7 ? 'invalid' : ''}
          error={form.onError && form.newPassword.length >= 7 ? strings('error_password_mismatch') : null}
          handleValueChanged={onValueChanged}
        />
      </div>
      <div className={"action"}>
        <Button className={"confirm"} label={strings("save")} color={ButtonColors.green} enabled={enableButton} action={validateForm} />
      </div>
      <div className={"separator"} />
    </div>
  );
};

Password.propTypes = {
  strings: PropTypes.func,
  user: PropTypes.object,
  changePassword: PropTypes.func,
};

Password.defaultProps = {
  strings: () => console.log("strings not implemented"),
  user: {},
  changePassword: () => console.log("changePassword not implemented"),
};

export default Password;
