import React, { useEffect, useState } from 'react';
import { icons, images } from '../../../assets';
import ReactPlayer from 'react-player/lazy';
import './detailVideo.scss';
import { Button, ButtonColors, ScreenHeader } from '../../components';
import { parseSuccessFromExcel } from '../../../utils/success';
import { getFileFormat } from '../../../utils/url';

const VideoItem = ({ url, title, validated, onPalyVideo }) => {
  return (
    <div className={`video-card ${validated ? 'checked' : ''}`}>
      <div className="card-header">
        <iframe
          src={url}
          width="100%"
          height="100%"
          frameborder="0"
          loading="lazy"
          allowFullScreen
        ></iframe>
        <div className="play-button">
          <img
            src={images.playButton}
            alt="play button"
            onClick={() => onPalyVideo({ url, title })}
          />
        </div>
      </div>
      <div className={'card-footer'}>
        <div className={'title'}>{title}</div>
        <img src={images.expand} alt="expand" onClick={() => onPalyVideo({ url, title })} />
      </div>
    </div>
  );
};
const FileItem = (props) => {
  const { title, type, url } = props;

  return (
    <div className={'file-item'}>
      <div className={'title'}>
        <p> {title}</p>
        <p> {type}</p>
      </div>
      <div>
        <a href={`../../../assets/documents/${url}`} className="download_file" download>
          <Button
            color={ButtonColors.green500}
            label={`Télécharger`}
            className={'action-button'}
            icon={icons.DOWNLOAD}
            reverse={true}
            enabled={true}
            /*  enabled={url !== '#' || url === ''} */
          />
        </a>
      </div>
    </div>
  );
};
const DetailVideo = ({ pop, match, project }) => {
  const [muted, setMuted] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [url, setUrl] = useState('');
  const [movie, setMovie] = useState({ url: '', title: '' });
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const { successes, type } = project;
    const { projectId, succesId, videoId } = match.params;
    let success = parseSuccessFromExcel(successes, type).filter(
      (item) => item.id === parseInt(succesId),
    )[0];
    const video = success.ressources.videos[parseInt(videoId)];
    setUrl(video.url);
    setMovie({ ...movie, url: video.url, title: video.title });
    setSuccess(success);
  }, []);
  const onPalyVideo = ({ url, title }) => {
    setMovie({ ...movie, url, title });
  };

  return (
    <div id="detail_video">
      <img className={'background'} src={images.epaBackground} alt={'background'} />
      <ScreenHeader screenName={'sucess_detail'} arrowAction={pop} className={'navigation'} />
      <div className={'player-wrapper fade desktop'}>
        <div className="player">
          <iframe
            src={movie.url}
            width="100%"
            height="100%"
            frameborder="0"
            loading="lazy"
            allow="autoplay;"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className={'player-wrapper-mobile fade'}>
        <div className={'k-player'}>
          <div className={'header'}>
            <iframe
              src={movie.url}
              width="100%"
              height="100%"
              frameborder="0"
              loading="lazy"
              allow="autoplay;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={'footer'}>{movie.title}</div>
        </div>
        {success !== null &&
          success.ressources.videos.filter((item) => item.url !== movie.url).length > 0 && (
            <div className={'others'}>
              <h2>{`${
                success.ressources.videos.filter((item) => item.url !== movie.url).length > 1
                  ? 'Vidéos suivantes'
                  : 'Vidéo suivante'
              }`}</h2>
              <div className={'viewerListe'}>
                {success.ressources.videos
                  .filter((item) => item.url !== movie.url)
                  .map((item, index) => (
                    <VideoItem
                      validated={success.validated}
                      onPalyVideo={onPalyVideo}
                      key={index}
                      url={item.url}
                      title={item.title}
                    />
                  ))}
              </div>
            </div>
          )}
        {success?.ressources.documents && success?.ressources.documents.length > 0 && (
          <div className="documents">
            <h2>{`Document(s)`}</h2>
            <div className="document-container">
              {success?.ressources.documents.map((item, index) => (
                <FileItem
                  key={index}
                  title={item.title}
                  type={getFileFormat(item.url)}
                  url={item.url}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailVideo;
