//import nom_image from "./mon_image"
import epaLogo from './epa-logo.png';
import epalogo2 from './logo-epa.svg';
import epaLogoTurquoise from './logo_epa_rvb_bleu_turquoise.png';
import epaBackground from './epa-background.svg';
import epaBackground2 from './epa-background2.svg';
import epaBackgroundWite from './figure-white.svg';
import link from './LINK.svg';
import lock from './lock.svg';
import ressources from './ressources.png';
import currency from './currency.png';
import checklist from './checklist.png';
import mentions from './mentions.svg';
import commercial from './commercial.svg';
import equal from './equal.svg';
import epaLogoMiniM from './logo-epa-mini-M.svg';
import epaLogoMiniL from './logo-epa-mini-L.svg';
import epaLogoMiniS from './logo-epa-mini-S.svg';
import epaLogoMiniXL from './Logo_MEXL_RVB_Orange.svg';
import epaLogoChallenge10X from './logo-epa-challenge-10X.png';
import asanaIcon from './asana.png';
import onedriveIcon from './one-drive.png';
import tuzzitIcon from './tuzzit.png';
import wweeddooIcon from './wweeddoo.png';
import wrikeIcon from './wrike.png';
import jaLogo from './logo-junior.svg';
import epaLinkLogo from './logo-epa-link.svg';
import boys from './boys.svg';
import girls from './girls.svg';
import boy from './garçon.svg';
import girl from './fille.svg';
import schedule from './schedule.svg';
//New images
import monney from './monney.png'
import profilProject from './profil-project.png'
import recycleBin from './recycle-bin.svg'
import success2 from './success_2.svg'
import winsuccess from "./win.png"
import planning from './planing.svg'
import playButton from './play.png'
import expand from './expand.png'
export const images = {
  epalogo2,
  epaBackgroundWite,
  epaLogo,
  epaBackground,
  epaBackground2,
  link,
  lock,
  epaLogoTurquoise,
  ressources,
  currency,
  checklist,
  mentions,
  commercial,
  equal,
  epaLogoMiniL,
  epaLogoMiniM,
  epaLogoMiniS,
  epaLogoMiniXL,
  epaLogoChallenge10X,
  asanaIcon,
  onedriveIcon,
  tuzzitIcon,
  wweeddooIcon,
  wrikeIcon,
  jaLogo,
  epaLinkLogo,
  boys,
  girls,
   boy,
  girl,
  schedule,
  monney,
  profilProject,
  recycleBin,
  success2,
  winsuccess,
  planning,
  playButton,
  expand
};
