import { call, put, select, takeLatest } from "redux-saga/effects";
import { NotificationsServices } from "../../services";
import { NotificationsActions } from "./index";
import { types } from "./actions";

function* getNotificationsRequested() {
  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(NotificationsServices.getNotifications, userId);

  if (!error) {
    yield put(NotificationsActions.getNotificationsSucceeded(response.data));
  }
}

function* setNotificationsReadRequested() {
  const userId = yield select((state) => state.auth.id);
  const notifs = yield select((state) => state.notifications.list);

  const [error, response] = yield call(NotificationsServices.setNotificationsRead, userId, notifs.filter((n) => n.isNew).map((n) => n.id));

  if (!error) {
    yield put(NotificationsActions.setNotificationsReadSucceeded(response.data));
  }
}

export default [
  takeLatest(types.GET_NOTIFICATIONS_REQUESTED, getNotificationsRequested),
  takeLatest(types.SET_NOTIFICATIONS_READ_REQUESTED, setNotificationsReadRequested),
];
