import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {  userRole } from '../../../utils';
import {default as Navigation} from './navigation';
import { Presentation } from './presentation';
import { Progress } from './progress';
import './room.scss';
import { Successes } from './successes';
import { SuccessesDetail } from './successesDetail';
import { Tools } from './tools';

const Room = ({ match, strings, role, room, resetRoomDetail, getMiniRoom, updateSuccesses }) => {
  const [showSuccessDetail, setShowSuccessDetail] = useState(false);

  useEffect(() => {
    resetRoomDetail();
  }, []);

  useEffect(() => {
    const { miniId, roomId } = match.params;
    if (miniId && roomId) {
      getMiniRoom(miniId, roomId);
    }
  }, [getMiniRoom, match.params]);

  const handleSave = (successes) => {
    const { miniId, roomId } = match.params;

    const formattedSuccesses = successes
      .slice()
      .filter((success) => success.validated)
      .map((success) => ({ ...success, validationDate: moment(success.validationDate).toISOString() }));
    updateSuccesses(miniId, roomId, formattedSuccesses);
  };

  const toggleSuccessDetail = () => setShowSuccessDetail(!showSuccessDetail);
  //usePageViews()
  return (
    <div id={'room'}>
      {room && (
        <>
          {room.room && room.room.successes && (
            <SuccessesDetail
              strings={strings}
              show={showSuccessDetail}
              roomSuccesses={room.room.successes}
              handleSave={handleSave}
              handleClose={toggleSuccessDetail}
            />
          )}
          {(role !== userRole.STUDENT && room.mini && room.mini.rooms && room.room) &&
          <Navigation miniId={room.mini.id} rooms={room.mini.rooms} roomIndex={room.room.index}/>
          }
          <div className={'content row'}>
            <div className={'primary column'}>
              {room.mini && <Presentation strings={strings} subject={room.mini.subject} videos={room.mini.videos} />}
              {room.room && room.room.successes && (
                <Successes strings={strings} progress={room.room.progress} successes={room.room.successes} showSuccessDetail={toggleSuccessDetail} />
              )}
            </div>
            <div className={'secondary column'}>
              {room.mini && room.mini.commonTools && room.mini.commonTools.length > 0 && (
                <Tools label={strings('common_tools')} tools={room.mini.commonTools} />
              )}
              {room.room && room.room.roomTools && room.room.roomTools.length > 0 && <Tools label={strings('room_tools')} tools={room.room.roomTools} />}
              {room.progress && <Progress strings={strings} roomId={room.room.id} rooms={room.progress} />}
              {room.mini && room.mini.partners && (
                <div className={'partners'}>
                  <p className={'label'}>{strings('partners_title')}</p>
                  {room.mini.partners.map((partner, index) => (
                    <p key={index} className={'partner'}>
                      {partner}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Room;
