import { Routes } from '../utils';
import {
  ProjectList,
  LegalMentions,
  DetailSuccessList,
  DetailSucces,
  ProjectDetail,
  Planning,
  DetailBudgetList,
  Links,
  Data,
  Profile,
  Room,
  DetailVideo
} from '../ui/screens';
import Guide from '../ui/screens/guide';


export default {
  home: {
    path: Routes.PROJECT_LIST,
    component: ProjectList
  },
  detailSuccess: {
    path: Routes.PROJECT_SUCCESS,
    component: DetailSuccessList
  },
   detailSucces: {
    path: Routes.SUCCESS_DETAIL,
    component: DetailSucces
  },
   detailVideo: {
    path: Routes.VIDEO_DETAIL,
    component: DetailVideo
  },
  detailBudget: {
    path: Routes.PROJECT_BUDGET,
    component: DetailBudgetList
  },
  projectDetail: {
    path: Routes.PROJECT_DETAIL,
    component: ProjectDetail
  },
  legalMentions: {
    path: Routes.LEGAL_MENTIONS,
    component: LegalMentions
  },
  planning: {
    path: Routes.PLANNING,
    component: Planning
  },
  links: {
    path: Routes.LINKS,
    component: Links
  },
  data: {
    path: Routes.DATA,
    component: Data
  },
  profile: {
    path: Routes.PROFILE,
    component: Profile
  },
  room: {
    path: Routes.ROOM_DETAIL,
    component: Room
  },
  guides: {
    path: Routes.GUIDES,
    component: Guide
  }
};
