import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  id: null,
  accessToken: null,
  refreshToken: null,
};

// region Reducers
const refreshTokens = (state = initialState, action) => {
  const {id, accessToken, refreshToken} = action;
  return {...state, id, accessToken, refreshToken};
};
// endregion

export default createReducer(initialState, {
  [types.REFRESH_TOKENS_SUCCEED]: refreshTokens,
});
