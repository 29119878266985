import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { CommonSelectors } from '../../../../redux/common';
import { UserActions } from '../../../../redux/user';
import { default as User } from './User';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  user: state.user.user,
  regions: CommonSelectors.getRegions(state),
});

const mapDispatchToProps = dispatch => ({
  updateProfile: user => dispatch(UserActions.updateUserRequested(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
