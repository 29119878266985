import React, { useState } from 'react';
import '../detail-budget-list.scss';
import { accountType, budgetOperation, userRole } from '../../../../utils';
import { formatDateForBudget } from '../../../../utils/formatDate';
import { icons } from '../../../../assets';

export const DetailBudgetMobile = ({
  entry,
  strings,
  id,
  role,
  handleOpenConfirm,
  handleUpdateBudget,
}) => {
  const [isDescritionOpened, setIsDescritionOpened] = useState(false);
  const { description, amount, account, operationDate, executionDate, operationType } = entry;
  const viewMore = () =>{
    setIsDescritionOpened(!isDescritionOpened);
  }
  const isWithdraw = (operationType) => {
    return operationType.operation === budgetOperation.WITHDRAW;
  };
  const value = (val, operationType) => (
    <p className={`${isWithdraw(operationType) ? 'negative' : ''}`}>
      {`${isWithdraw(operationType) ? '- ' : '+ '}` +
        val.toLocaleString(navigator.language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) +
        strings('currency')}
    </p>
  );

  const deleteBudget = (e) => {
    e.stopPropagation();
    handleOpenConfirm(id);
  };

  const updateBudget = () => {
    handleUpdateBudget(id);
  };

  return (
    <div className="bugederListItem">
      <div className={'headerMobile'}>
        <div className={'col object'}>
          <p>{strings('budget_object')}</p>
        </div>

        <div className={'col amount'}>
          <p>{strings('budget_amount')}</p>
        </div>

        <div className={'col date'}>
          <p>{strings('budget_date')}</p>
        </div>
      </div>

      <div className={'budget-item selectable'} onClick={updateBudget}>
        <div className={'col object'}>
          <p>{description}</p>
        </div>

        <div className={'col amount'}>{value(amount, operationType)}</div>

        <div className={'col date'}>
          <p>{formatDateForBudget(operationDate)}</p>
        </div>
      </div>
      <div className={`descriptionArea ${isDescritionOpened ? 'show' : 'hide'}`}>
        <div className="descriptionAreaItem">
          <p className="descriptionTitle">{strings('budget_type')}</p>
          <p className="descriptionData">{operationType.label}</p>
        </div>
        <div className="descriptionAreaItem">
          <p className="descriptionTitle">{strings('budget_execution_date')}</p>
          <p className="descriptionData">
            {executionDate !== null ? formatDateForBudget(executionDate) : '-'}
          </p>
        </div>
      </div>
      <div className="itemAction">
        <p onClick={viewMore}>
          {isDescritionOpened ? 'Masquer les details' : 'Afficher les details'}
        </p>

        <div className={'col delete'}>
          <i className="material-icons" onClick={deleteBudget}>
            {icons.DELETE}
          </i>
        </div>
      </div>
    </div>
  );
};
