import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Switch = ({ label, checked, handleCheckChange }) => {
  const [isChecked, setChecked] = useState(!!checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const onCheckChange = e => {
    handleCheckChange(e.target.checked);
    setChecked(!isChecked);
  }

  return (
    <div className={'switch-wrapper'}>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={onCheckChange}/>
        <span className="slider round"></span>
      </label>
      <p>{label}</p>
    </div>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  handleCheckChange: PropTypes.func
};

Switch.defaultProps = {
  label: '',
  handleCheckChange: () => console.log("Not implemented")
};

export default Switch;
