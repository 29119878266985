import { methods, url } from '../utils';
import { authenticatedService } from './middleware';

const routes = {
  getListProjects: (userId) => url.LIST_PROJECTS(userId),
  updateSuccesses: (userId, id) => url.UPDATE_SUCCESS(userId, id),
  getProjectDetail: (userId, projectId) => url.GET_PROJECT(userId, projectId),
  updateProject: (userId, projectId, projectName, projectDescription, projectAvatar) =>
    url.UPDATE_PROJECT(userId, projectId, projectName, projectDescription, projectAvatar),
  addMeeting: (userId, projectId) => url.ADD_MEETING(userId, projectId),
  deleteMeeting: (userId, projectId, meetingId) => url.DELETE_MEETING(userId, projectId, meetingId),
  updateMeeting: (userId, projectId, meetingId, meeting) =>
    url.UPDATE_MEETING(userId, projectId, meetingId, meeting),
  addBudget: (userId, projectId) => url.ADD_BUDGET(userId, projectId),
  deleteBudget: (userId, projectId, budgetId) => url.DELETE_BUDGET(userId, projectId, budgetId),
  updateBudget: (userId, projectId, budgetId) => url.UPDATE_BUDGET(userId, projectId, budgetId),
  downloadBudget: (userId, projectId) => url.DOWNLOAD_BUDGET(userId, projectId),
  getOperationTypes: (inBackground) => url.GET_OPERATION_TYPES(inBackground),
  addLink: (userId, projectId, linkId) => url.ADD_LINK(userId, projectId, linkId),
  updateLink: (userId, projectId, linkId, link) => url.UPDATE_LINK(userId, projectId, linkId, link),
  deleteLink: (userId, projectId, linkId) => url.DELETE_LINK(userId, projectId, linkId),
  updateData: (userId, projectId) => url.UPDATE_DATA(userId, projectId),
  getClassCategories: () => url.GET_CLASS_CATEGORIES(),
  getClassLevels: () => url.GET_CLASS_LEVELS(),
  getClassSectors: () => url.GET_CLASS_SECTORS(),
  getClassDedicatedTimes: () => url.GET_DEDICATED_TIMES(),
  addSuccess: (userId, projectId) => url.ADD_SUCCESS(userId, projectId),
  deleteSuccess: (userId, projectId, successId) => url.DELETE_SUCCESS(userId, projectId, successId),
};

export default {
  getListProjects: (userId) =>
    authenticatedService(methods.GET, routes.getListProjects(userId), {}, {}),
  getProjectDetail: (userId, projectId) =>
    authenticatedService(methods.GET, routes.getProjectDetail(userId, projectId), {}, {}),
  updateSuccesses: (userId, id, successes) =>
    authenticatedService(methods.PATCH, routes.updateSuccesses(userId, id), successes, {}),
  updateProject: (userId, projectId, projectName, projectDescription, projectAvatar) =>
    authenticatedService(
      methods.PATCH,
      routes.getProjectDetail(userId, projectId),
      { id: projectId, name: projectName, description: projectDescription, avatar: projectAvatar },
      {},
    ),
  addMeeting: (userId, projectId, meeting) =>
    authenticatedService(methods.PUT, routes.addMeeting(userId, projectId), meeting, {}),
  deleteMeeting: (userId, projectId, meetingId) =>
    authenticatedService(
      methods.DELETE,
      routes.deleteMeeting(userId, projectId, meetingId),
      {},
      {},
    ),
  updateMeeting: (userId, projectId, meetingId, meeting) =>
    authenticatedService(
      methods.PATCH,
      routes.updateMeeting(userId, projectId, meetingId),
      meeting,
      {},
    ),
  addBudget: (userId, projectId, budget) =>
    authenticatedService(methods.PUT, routes.addBudget(userId, projectId), budget, {}),
  deleteBudget: (userId, projectId, budgetId) =>
    authenticatedService(methods.DELETE, routes.deleteBudget(userId, projectId, budgetId), {}, {}),
  updateBudget: (userId, projectId, budgetId, budget) =>
    authenticatedService(
      methods.PATCH,
      routes.updateBudget(userId, projectId, budgetId),
      budget,
      {},
    ),
  downloadBudget: (userId, projectId) =>
    authenticatedService(methods.GET, routes.downloadBudget(userId, projectId), {}, {}, {}, 'blob'),
  getOperationTypes: (inBackground) =>
    authenticatedService(methods.GET, routes.getOperationTypes(inBackground), {}, {}),
  addLink: (userId, projectId, link) =>
    authenticatedService(methods.PUT, routes.addLink(userId, projectId), link, {}),
  updateLink: (userId, projectId, linkId, link) =>
    authenticatedService(methods.PATCH, routes.updateLink(userId, projectId, linkId), link, {}),
  deleteLink: (userId, projectId, linkId) =>
    authenticatedService(methods.DELETE, routes.deleteLink(userId, projectId, linkId), {}, {}),
  updateData: (userId, projectId, body) =>
    authenticatedService(methods.PUT, routes.updateData(userId, projectId), body, {}),
  getClassCategories: () => authenticatedService(methods.GET, routes.getClassCategories(), {}, {}),
  getClassLevels: () => authenticatedService(methods.GET, routes.getClassLevels(), {}, {}),
  getClassSectors: () => authenticatedService(methods.GET, routes.getClassSectors(), {}, {}),
  getClassDedicatedTimes: () =>
    authenticatedService(methods.GET, routes.getClassDedicatedTimes(), {}, {}),
  addSuccess: (userId, projectId, success) =>
    authenticatedService(methods.PUT, routes.addSuccess(userId, projectId), success, {}),
  deleteSuccess: (userId, projectId, successId) =>
    authenticatedService(
      methods.DELETE,
      routes.deleteSuccess(userId, projectId, successId),
      {},
      {},
    ),
};
