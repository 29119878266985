import React, {PureComponent} from 'react';
import './footer.scss';
import { EPA_WEBSITE_URL } from '../../../utils/constants';

export class Footer extends PureComponent {
  state = {
    position: window.scrollY,
  };
  handleNavigation = (e) => {
    const window = e.currentTarget;
    this.setState({ position: window.scrollY });
  };
  componentDidMount() {
     window.addEventListener('scroll', (e) => this.handleNavigation(e));
  }

  render() {
    const { strings, footerLink, navigateTo } = this.props;
    const { position } = this.state;
  
    return (
      <div id={`footer`} className={`${position === 0 ? 'float' : ''}`}>
        <div className={'content'}>
          <p id={'copyright'}>{strings('copyright')}</p>
          <div id={'link'}>
            {footerLink.map((item) => (
              <p onClick={() => navigateTo(item.link)} key={item.label}>
                {item.label}
              </p>
            ))}
          </div>
          <p id={'go-web-epa'} onClick={() => window.open(EPA_WEBSITE_URL, '_blank')}>
            {strings('access_to')}
          </p>
        </div>
      </div>
    );
  }
}
