import { goBack } from 'connected-react-router';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { default as Navigation } from './Navigation';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
  pop: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
