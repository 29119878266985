import React, { useRef, useState } from 'react';
import { icons, images } from '../../../../assets';

const Presentation = ({ strings, subject, videos }) => {
  const videosDivRef = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);

  const translateCarousel = (step) => () => {
    if (videosDivRef && videosDivRef.current) {
      let newIndex = videoIndex + step;
      let scrollValue = videosDivRef.current.scrollLeft + videosDivRef.current.clientWidth * step;
      if (videosDivRef.current.scrollLeft === 0 && step < 0) {
        newIndex = videos.length - 1;
        scrollValue = videosDivRef.current.clientWidth * (videos.length - 1);
      }
      if (videosDivRef.current.scrollLeft === videosDivRef.current.clientWidth * (videos.length - 1) && step > 0) {
        newIndex = 0;
        scrollValue = 0;
      }

      videosDivRef.current.scrollTo({
        top: 0,
        left: scrollValue,
        behavior: 'smooth',
      });

      setVideoIndex(newIndex);
    }
  };

  const goToIndex = (index) => () => {
    if (videosDivRef && videosDivRef.current) {
      setVideoIndex(index);
      videosDivRef.current.scrollTo({
        top: 0,
        left: videosDivRef.current.clientWidth * index,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={'presentation column'}>
      <div className={'info column'}>
        <img src={images.epaLogoMiniS} alt={'Logo projet'} />
        <p className={'subject'}>{subject}</p>
      </div>
      {videos && videos.length > 0 && (
        <div className={'carousel row'}>
          <div className={'arrow left'}>
            <i className={'material-icons'} onClick={translateCarousel(-1)}>
              {icons.CHEVRON_RIGHT}
            </i>
          </div>
          <div className={'videos-wrapper column'}>
            <div className={'videos row'} ref={videosDivRef}>
              {videos.map((video, index) => (
                <div key={index} className={'video column'}>
                  <p>{video.label}</p>
                  <div dangerouslySetInnerHTML={{ __html: video.video }} />
                </div>
              ))}
            </div>
            <div className={'bubbles row'}>
              {videos &&
                videos.length > 1 &&
                videos.map((_, index) => <div key={index} className={`bubble ${videoIndex === index ? 'selected' : ''}`} onClick={goToIndex(index)} />)}
            </div>
          </div>
          <div className={'arrow right'}>
            <i className={'material-icons'} onClick={translateCarousel(+1)}>
              {icons.CHEVRON_RIGHT}
            </i>
          </div>
        </div>
      )}
    </div>
  );
};

export default Presentation;
