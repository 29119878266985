import React, { useState, useEffect, useRef } from 'react';
import { icons, images, strings } from '../../../../../assets';
import avatars from '../../../../../utils/avatars';
import { Button, ButtonColors } from '../../../../components';
import './editBlock.scss';
import Axios from 'axios';
import { uploadFile } from '../../../../../utils/download';
import Avatar from '@mui/material/Avatar';
const EditBlock = ({
  show,
  hideForm,
  choiseAvatar,
  editBlock,
  inputChange,
  name,
  description,
  avatar,
}) => {
  const [error, setError] = useState('');
  const [succes, setSucces] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const textInputImage = useRef(null);
  function avatarImageClick() {
    console.log(textInputImage.current.focus());
    textInputImage.current.click();
  }

  const uploadImage = async (e) => {
    try {
      const { files } = e.target;
      const file = files[0];
      const taille = file.size / 1024 / 1024; //Taille en MB
      if (taille <= 10) {
        setIsImageLoading(true);
        const formatData = new FormData();
        formatData.append('file', file);
        formatData.append('upload_preset', 'project_profil');
        const response = await Axios.post(
          'https://api.cloudinary.com/v1_1/dcmzzdl4g/image/upload',
          formatData,
        );

        if (response) {
          setIsImageLoading(false);
          const { url } = response.data;
          const { name } = file;
          setSucces(`L'image ${name} téléchargée avec succès`);
          setError('');
          choiseAvatar(url);
        }
      } else {
        setSucces('');
        setError(`L'image ${name} est trop grande, choisir une autre image`);
      }
    } catch (error) {
      console.error({ error });
    }
  };
  const handleDeletePicture = () => {
    choiseAvatar('');
  };
  useEffect(() => {
    setError('');
    setSucces('');
  }, [show]);
  return (
    <div className={`popup-wrappers ${show ? 'shown' : 'hidden'}`}>
      <div className={'popups'}>
        <i className={'material-icons close'} onClick={hideForm}>
          {icons.CLOSE}
        </i>
        <div className={'form'} autoComplete="off">
          <div className="form-container">
            <h2 className={'profile-info'}>Profil</h2>
            <div className={'profile-content'}>
              <div className={'field'}>
                <label htmlFor="name">Nom de notre mini-entreprise</label>
                <input
                  className={'input'}
                  maxLength="64"
                  type="text"
                  id="name"
                  onChange={inputChange}
                  name="name"
                  placeholder="Nom de notre mini-entreprisel’équipe"
                  value={name}
                />
              </div>
              <div className={'field'}>
                <label htmlFor="description">Descriptif du projet</label>
                <textarea
                  name="description"
                  className={'input'}
                  onChange={inputChange}
                  maxLength="230"
                  rows="5"
                  type="text"
                  id="description"
                  value={description}
                  placeholder="Décrivez votre mini-entreprise, ses membres, le bien ou service que vous avez choisi."
                />
              </div>
            </div>
            <h2 className={'avatar-info'}>Logo</h2>
            <div className={'avatar-container'}>
              <p>Change le logo de ta mini-entreprise</p>
              <label>Format recommandé : 90 x 87 pixels, Taille : 10 MB</label>
              <div className={`message ${succes && 'success'} ${error && 'error'} `}>
                {error && error}
                {succes && succes}
              </div>

              <div className={'avatar-items'}>
                <input
                  className="inputAvatar"
                  hidden
                  type="file"
                  onChange={uploadImage}
                  name="logo"
                  accept="image/*"
                  id="photo"
                  ref={textInputImage}
                ></input>
              </div>

              <div className={'avatar-exist'}>
                {avatar ? (
                  <img className={'header'} src={avatar} alt={'Profil'} />
                ) : (
                  <div className="noAvatar">
                    <i className="bx bxs-camera-plus"></i>
                  </div>
                )}
                {isImageLoading ? (
                  <i class="bx bx-loader-alt bx-spin editIcon"></i>
                ) : (
                  <i className={'editIcon material-icons'} onClick={avatarImageClick}>
                    {' '}
                    {icons.EDIT}{' '}
                  </i>
                )}
              </div>
            </div>
          </div>
          <div className={'action-btn'}>
            <Button label={'Valider'} color={ButtonColors.green500} action={editBlock} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBlock;
