import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  regions: [],
};

const getRegionsSucceeded = (state = initialState, action) => {
  const {regions} = action;
  return {...state, regions};
};

export default createReducer(initialState, {
  [types.GET_REGIONS_SUCCEEDED]: getRegionsSucceeded,
});
