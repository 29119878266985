import { ScreenHeader } from './ScreenHeader';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  role: state.user.user.role,
  showToolLink: isProjectDetailsScreen(state.router.location.pathname)
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenHeader);

const isProjectDetailsScreen = path => /\d/.test(path.split('/')[path.split('/').length - 1]);
