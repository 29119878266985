import item from '../ui/components/navigation/item';
import { successes10XData } from './10XSuccesses';
import { projectTypeKeys } from './enum';
import { successExtratLargeData } from './XLSuccesses';
export const getValidatedSuccesses = (successes) => {
  const successesToDisplay = successes.filter((success) => success.validated);
  return successesToDisplay;
};
export const getUnValidatedSuccesses = (successes) => {
  const successesToDisplay = successes.filter((success) => success.validated === false);
  return successesToDisplay;
};
const successLarge = [
  {
    id: 30,
    name: "Le droit à l'image",
    description:
      "Consultez les ressources pour comprendre l'importance des autorisations de droit à l'image.",
    ressources: {
      videos: [],
      documents: [
        {
          title: "Autorisation de l'utilisation de l'image",
          url: 'AutorisationdroitimageMiniL.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/LeDroitAlImage_csgh6p.png',
  },
  {
    id: 42,
    name: "La Mini-Entreprise®, qu'est-ce que c'est?",
    description: "Consultez les ressources pour comprendre ce qu'est une Mini-Entreprise®.",
    ressources: {
      videos: [
        {
          title: 'Votre année de mini-entrepreneurs en quelques mots',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d69243e7b98a80ceb1bb56f/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/LaMiniEntrepriseQuEstCeQueCest_xnrqrq.png',
  },
  {
    id: 41,
    name: 'Le tableau de bord de la Mini-Entreprise®',
    description:
      'Consultez le tutoriel de la plateforme pédagogique pour vous familiariser avec le tableau de bord.',
    ressources: {
      videos: [
        {
          title: 'La plateforme pédagogique',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f1987a3736d8a00114b9d2a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/TableaudeBorddeNotreEntreprise_fn4igf.png',
  },
  {
    id: 103,
    name: "Une entreprise, qu'est-ce que c'est?",
    description: "Consultez les ressources pour comprendre ce qu'est une entreprise.",
    ressources: {
      videos: [
        {
          title: "Qu'est-ce qu'une entreprise ?",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d6919207b98a80ceb1b767c/deploy',
        },
      ],
      documents: [
        {
          title: 'Lexique des termes entrepreneuriaux',
          url: 'Lexiquedestermesentrepreneuriaux.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/UneEntrepriseQuEstCeQueCest_lnxxzy.png',
  },
  {
    id: 104,
    name: 'Le rôle du mentor de la Mini-Entreprise®',
    description:
      'Consultez les ressources pour comprendre le rôle du mentor de votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Un mentor, pour quoi faire ?',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d78b40a27f1280d17c65dd1/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/RoleDuMentorDeLaMinientreprise-MAJ_y24mt9.png',
  },
  {
    id: 44,
    name: 'Le choix de notre bien ou service',
    description:
      'Consultez les ressources pour vous aider à choisir le bien ou service que vous allez développer dans votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Pourquoi étudier la faisabilité de mon idée ?',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d67a4847b98a80ceb19dc42/deploy',
        },
        {
          title: 'Poser son modèle économique',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f06e713c32a750011048467/deploy',
        },
        {
          title: 'Favoriser la créativité',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d67a42c7b98a80ceb19dc01/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/LeChoixDeNotreBienOuService_hfwkii.png',
  },
  {
    id: 105,
    name: 'Définition de notre clientèle cible',
    description:
      'Consultez les ressources pour vous aider à définir la clientèle de votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Etudier son marché',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a45e598f3b50cfb5325eb/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/DefinitionDeNotreClienteleCible_t2aopk.png',
  },
  {
    id: 106,
    name: 'Charte des valeurs de notre Mini-Entreprise®',
    description: 'Consultez les ressources pour vous aider à définir votre charte.',
    ressources: {
      videos: [
        {
          title: 'Les valeurs de sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a3b0798f3b50cfb52f5ab/deploy',
        },
      ],
      documents: [
        {
          title: 'Animer un atelier - Définir ses valeurs',
          url: 'AnimerunatelierRépartirlesrôles.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/CharteDesValeursDeNotreMinientreprise_yesktw.png',
  },
  {
    id: 107,
    name: "Choix d'une ou plusieurs associations qui recevra les futurs bénéfices de la Mini-Entreprise®",
    description:
      'Consultez les ressources pour vous aider à choisir les associations qui recevront votre donation.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/ChoixDuneAssoPourFutursBe%CC%81ne%CC%81fices_bgkaik.png',
  },
  {
    id: 45,
    name: 'Validation de notre bien ou service par Entreprendre Pour Apprendre',
    description: 'Validez votre produit avec le référent Entreprendre pour Apprendre.',
    ressources: {
      videos: [],
      documents: [
        {
          title: 'Que peut-on produire ?',
          url: 'Quepeutonproduire.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1657722029/Illustrations%20succ%C3%A8s/Validation_de_notre_bien_ou_service_lavuls.png',
  },
  {
    id: 47,
    name: 'Répartition des responsabilités entre les membres de notre Mini-Entreprise®',
    description:
      "Consultez les ressources pour connaître les différents modes d'organisation possibles de votre Mini-Entreprise®. ",
    ressources: {
      videos: [
        {
          title: 'Organiser sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f0597c8c32a750011024ba7/deploy',
        },
        {
          title: 'Prendre une décision',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5dd4240d6244d00c56880ea8/deploy',
        },
        {
          title: 'Organiser son équipe',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7bbac0e6b6a10c48cce38d/deploy',
        },
        {
          title: "Gérer une crise dans l'équipe ",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d654de0074a8c0cfd022022/deploy',
        },
      ],
      documents: [
        {
          title: 'Les rôles dans la Mini-Entreprise®',
          url: 'LesrôlesdanslaMiniEntreprise.pdf',
        },
        {
          title: 'Prendre une décision',
          url: 'Prendreunedécision.pdf',
        },
        {
          title: 'Animer un atelier - Répartir les rôles',
          url: 'AnimerunatelierRépartirlesrôles.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/RepartitionDesResponsabilitesDesMembres_m5kkwi.png',
  },
  {
    id: 46,
    name: 'Rétroplanning de notre Mini-Entreprise®',
    description: 'Consultez les ressources pour savoir comment organiser votre Mini-Entreprise®. ',
    ressources: {
      videos: [
        {
          title: 'Organiser sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d6e22384cb79f0cf98f37e0/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/RetroplanningEtModeDeCommunication_x25c1m.png',
  },
  {
    id: 108,
    name: 'Identification de notre besoin en financement',
    description:
      'Consultez les ressources pour vous aider à identifier vos besoins en financement.',
    ressources: {
      videos: [
        {
          title: 'Identifier ses besoins de financement ',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7639ff27f1280d17c45a13/deploy',
        },
      ],
      documents: [
        {
          title: 'Introduction au budget prévisionnel',
          url: 'Introductionaubudgetprévisionnel.pdf',
        },
        {
          title: 'Trame - Budget prévisionnel',
          url: 'TrameBudgetprévisionnel.xlsm',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/IdentificationDesBesoinsDeFinancement_aubfn4.png',
  },
  {
    id: 49,
    name: 'Organisation de notre fonctionnement financier',
    description:
      "Consultez les ressources pour comprendre l'outil comptable de la plateforme et organiser votre fonctionnement financier.",
    ressources: {
      videos: [
        {
          title: "Utiliser l'outil comptable",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5e1dbc68666c250037bafc4a/deploy',
        },
        {
          title: 'Suivre sa comptabilité',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d766dbd27f1280d17c533f2/deploy',
        },
      ],
      documents: [
        {
          title: "Utiliser la plateforme 4 - L'outil comptable",
          url: 'Utiliserlaplateforme4Loutilcomptable.pdf',
        },
        {
          title: 'Trame - Budget prévisionnel',
          url: 'TrameBudgetprévisionnel.xlsm',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945344/Illustrations%20succ%C3%A8s/OrganisationDeNotreFonctionnementFinancier_qixync.png',
  },
  {
    id: 48,
    name: 'Organisation de notre production',
    description: 'Consultez les ressources pour vous aider à organiser votre production. ',
    ressources: {
      videos: [
        {
          title: 'Produire',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5e2199e9666c250037bc8b5f/deploy',
        },
      ],
      documents: [
        {
          title: 'Trame - Le cahier des charges',
          url: 'TrameLecahierdescharges.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945344/Illustrations%20succ%C3%A8s/OrganisationdeNotreProduction_u20fdm.png',
  },
  {
    id: 50,
    name: 'Nom et logo de notre Mini-Entreprise®',
    description: 'Consultez les ressources pour organiser un atelier de créativité.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/NomEtLogoDeNotreMinientreprise_xzbrde.png',
  },
  {
    id: 53,
    name: 'Notre stratégie marketing',
    description: "Consultez les ressources pour découvrir ce qu'est le marketing. ",
    ressources: {
      videos: [
        {
          title: 'Découvrir le marketing',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5ef20ef202f98600114396ff/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/NotreStrate%CC%81gieMarketing_mzmuw5.png',
  },
  {
    id: 54,
    name: 'Notre stratégie de communication',
    description:
      'Consultez les ressources pour définir la stratégie de communication pour toucher votre clientèle.',
    ressources: {
      videos: [
        {
          title: 'Communiquer, du nom aux relations presse',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d78fa1a27f1280d17c6d58a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/StrategieDeCommunication_jatmjo.png',
  },
  {
    id: 55,
    name: 'Inscription au festival régional',
    description:
      'Consultez les ressources pour vous préparer au festival Entreprendre Pour Apprendre dans votre région.',
    ressources: {
      videos: [
        {
          title: 'Créer sa vidéo pour le festival des Mini-Entreprises®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5ffdb71a4f0b6f001170de13/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/InscriptionAuFestivalRegional_ub6nxd.png',
  },
  {
    id: 56,
    name: 'Organisation d’une vente',
    description: 'Consultez les ressources pour préparer votre première vente.',
    ressources: {
      videos: [
        {
          title: 'Bâtir un argumentaire de vente',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5da724a60777a10ca582b598/deploy',
        },
        {
          title: 'Organiser une vente',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d6e3c48d3ce3b0d01fe648f/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945344/Illustrations%20succ%C3%A8s/OrganisationdUneVente_gnaajm.png',
  },
  {
    id: 109,
    name: 'Bilan individuel des compétences acquises',
    description:
      'Consultez les ressources pour vous aider à organiser un atelier de bilan individuel des compétences.',
    ressources: {
      videos: [
        {
          title: 'Rejoindre le réseau des alumni',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a4b2d98f3b50cfb533573/deploy',
        },
        {
          title: "Ce que j'ai appris cette année",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a4e1098f3b50cfb5341e6/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/BilanIndividuelDeCompe%CC%81tences_kbm4j0.png',
  },
  {
    id: 57,
    name: 'Bilan et clôture de notre Mini-Entreprise®',
    description:
      'Consultez les ressources pour vous aider à effectuer le bilan de votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: "Rédiger son rapport d'activité",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/60015c325a65050011bc4940/deploy',
        },
        {
          title: 'Clôturer sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f0476c45de5580011cc0c14/deploy',
        },
        {
          title: "Ce que j'ai appris cette année",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a4e1098f3b50cfb5341e6/deploy',
        },
      ],
      documents: [
        {
          title: "Rédiger son rapport d'activité - collège",
          url: 'Rédigersonrapportdactivitécollège.pdf',
        },
        {
          title: "Rédiger son rapport d'activité - lycée",
          url: 'Rédigersonrapportdactivitélycée.pdf',
        },
        {
          title: 'Trame - Lettre investisseur',
          url: 'TrameLettreinvestisseur.docx',
        },
        {
          title: 'Faire le bilan humain de sa Mini-Entreprise®',
          url: 'FairelebilanhumaindesaMiniEntreprise.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945340/Illustrations%20succ%C3%A8s/BilanEtClotureDelaMiniEntreprise_rt4ihn.png',
  },
  {
    id: 110,
    name: 'Attentes, craintes, questions des membres de notre Mini-Entreprise®',
    description:
      'Consultez les ressources pour organiser un atelier de démarrage de Mini-Entreprise®. ',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945340/Illustrations%20succ%C3%A8s/AtteintesCraintesQuestionsDesMembresDeNotreMiniEntreprise_zjokpp.png',
  },
  {
    id: 111,
    name: 'Entrepreneur, intrapreneur, quelle différence?',
    description:
      "Consultez les ressources pour comprendre les différentes manières d'entreprendre. ",
    ressources: {
      videos: [
        {
          title: "Qu'est-ce qu'un entrepreneur ?",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d936599f8c8eb0cf33bed97/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/EntrepreneurIntrapreneurQuelleDifference_g6rbpy.png',
  },
  {
    id: 112,
    name: "Visite d'une entreprise",
    description:
      "Consultez les ressources pour savoir comment organiser la visite d'une entreprise",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945346/Illustrations%20succ%C3%A8s/VisiteDUneEntreprise_a686a0.png',
  },
  {
    id: 51,
    name: 'Constitution de notre capital de démarrage',
    description: 'Consultez les ressources pour savoir comment construire le capital de démarrage',
    ressources: {
      videos: [
        {
          title: 'Financer sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d76463727f1280d17c47c4a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/ConstituerUnCapitalDeDemarrage_l5cgpg.png',
  },
  {
    id: 113,
    name: 'Des partenaires correspondant à la charte des valeurs de notre Mini-Entreprise®',
    description: '',
    ressources: {
      videos: [
        {
          title: 'Présenter son projet à un partenaire',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7911af98f3b50cfb524903/deploy',
        },
      ],
      documents: [
        {
          title: 'Prendre la parole en public',
          url: 'Prendrelaparoleenpublic.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/DesPartenairesCorrespondantAlaCharte_rzleyi.png',
  },
  {
    id: 150,
    name: 'Organisation de la recherche de nos futurs partenaires',
    description: '',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945344/Illustrations%20succ%C3%A8s/OrganisationDeRechercheDeFutursPartenaires_tuyooh.png',
  },
  {
    id: 52,
    name: 'Les challenges du réseau européen JA',
    description:
      'Consultez les ressources pour savoir comment organiser la recherche des futurs partenaires.',
    ressources: {
      videos: [
        {
          title: 'La COYC - un concours pour les 16-20 ans',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d8e290106e89b0cf50e1e0f/deploy',
        },
        {
          title: "L'EEC - un concours pour les 18-25 ans",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d8e296006e89b0cf50e1e95/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/LesChallengesDuReseauEuropeen_wpu7pd.png',
  },
];
const successMedium = [
  {
    id: 11,
    name: "Le droit à l'image",
    description:
      "Consultez les ressources pour comprendre l'importance des autorisations de droit à l'image.",
    ressources: {
      videos: [],
      documents: [
        {
          title: "Autorisation de l'utilisation de l'image",
          url: 'AutorisationdroitimageMiniM.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/LeDroitAlImage_csgh6p.png',
  },
  {
    id: 13,
    name: "La Mini-Entreprise®, qu'est-ce que c'est?",
    description: "Consultez les ressources pour comprendre ce qu'est une Mini-Entreprise®.",
    ressources: {
      videos: [
        {
          title: 'Votre année de mini-entrepreneurs en quelques mots',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d69243e7b98a80ceb1bb56f/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/LaMiniEntrepriseQuEstCeQueCest_xnrqrq.png',
  },
  {
    id: 14,
    name: 'Le tableau de bord de la Mini-Entreprise®',
    description:
      'Consultez le tutoriel de la plateforme pédagogique pour vous familiariser avec le tableau de bord.',
    ressources: {
      videos: [
        {
          title: 'La plateforme pédagogique',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f1987a3736d8a00114b9d2a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/TableaudeBorddeNotreEntreprise_fn4igf.png',
  },
  {
    id: 115,
    name: "Une entreprise, qu'est-ce que c'est?",
    description: "Consultez les ressources pour comprendre ce qu'est une entreprise.",
    ressources: {
      videos: [
        {
          title: "Qu'est-ce qu'une entreprise ?",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d6919207b98a80ceb1b767c/deploy',
        },
      ],
      documents: [
        {
          title: 'Lexique des termes entrepreneuriaux',
          url: 'Lexiquedestermesentrepreneuriaux.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/UneEntrepriseQuEstCeQueCest_lnxxzy.png',
  },
  {
    id: 116,
    name: 'Le rôle du mentor de la Mini-Entreprise®',
    description:
      'Consultez les ressources pour comprendre le rôle du mentor de votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Un mentor, pour quoi faire ?',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d78b40a27f1280d17c65dd1/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/RoleDuMentorDeLaMinientreprise-MAJ_y24mt9.png',
  },
  {
    id: 32,
    name: 'Le choix de notre bien ou service',
    description:
      'Consultez les ressources pour vous aider à choisir le bien ou service que vous allez développer dans votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Pourquoi étudier la faisabilité de mon idée ?',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d67a4847b98a80ceb19dc42/deploy',
        },
        {
          title: 'Poser son modèle économique',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f06e713c32a750011048467/deploy',
        },
        {
          title: 'Favoriser la créativité',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d67a42c7b98a80ceb19dc01/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/LeChoixDeNotreBienOuService_hfwkii.png',
  },
  {
    id: 117,
    name: 'Charte des valeurs de notre Mini-Entreprise®',
    description: 'Consultez les ressources pour vous aider à définir votre charte.',
    ressources: {
      videos: [
        {
          title: 'Les valeurs de sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a3b0798f3b50cfb52f5ab/deploy',
        },
      ],
      documents: [
        {
          title: 'Animer un atelier - Définir ses valeurs',
          url: 'AnimerunatelierDéfinirsesvaleurss.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/CharteDesValeursDeNotreMinientreprise_yesktw.png',
  },
  {
    id: 60,
    name: 'Répartition des responsabilités entre les membres de notre Mini-Entreprise®',
    description:
      "Consultez les ressources pour connaître les différents modes d'organisation possibles de votre Mini-Entreprise®.",
    ressources: {
      videos: [
        {
          title: 'Organiser sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f0597c8c32a750011024ba7/deploy',
        },
        {
          title: 'Prendre une décision',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5dd4240d6244d00c56880ea8/deploy',
        },
        {
          title: 'Organiser son équipe',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7bbac0e6b6a10c48cce38d/deploy',
        },
        {
          title: "Gérer une crise dans l'équipe ",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d654de0074a8c0cfd022022/deploy',
        },
      ],
      documents: [
        {
          title: 'Les rôles dans la Mini-Entreprise®',
          url: 'LesrôlesdanslaMiniEntreprise.pdf',
        },
        {
          title: 'Prendre une décision',
          url: 'Prendreunedécision.pdf',
        },
        {
          title: 'Animer un atelier - Répartir les rôles',
          url: 'AnimerunatelierRépartirlesrôles.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/RepartitionDesResponsabilitesDesMembres_m5kkwi.png',
  },
  {
    id: 63,
    name: 'Identification des besoins financiers de notre Mini-Entreprise®',
    description:
      'Consultez les ressources pour vous aider à identifier vos besoins en financement. ',
    ressources: {
      videos: [
        {
          title: 'Identifier ses besoins de financement',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7639ff27f1280d17c45a13/deploy',
        },
      ],
      documents: [
        {
          title: 'Introduction au budget prévisionnel',
          url: 'Introductionaubudgetprévisionnel.pdf',
        },
        {
          title: 'Trame - Budget prévisionnel',
          url: 'TrameBudgetprévisionnel.xlsm',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/IdentificationDesBesoinsDeFinancement_aubfn4.png',
  },
  {
    id: 61,
    name: 'Notre stratégie marketing',
    description: "Consultez les ressources pour découvrir ce qu'est le marketing. ",
    ressources: {
      videos: [
        {
          title: 'Découvrir le marketing',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5ef20ef202f98600114396ff/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/NotreStrate%CC%81gieMarketing_mzmuw5.png',
  },
  {
    id: 64,
    name: 'Notre stratégie de communication',
    description:
      'Consultez les ressources pour définir la stratégie de communication pour toucher votre clientèle.',
    ressources: {
      videos: [
        {
          title: 'Communiquer, du nom aux relations presse',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d78fa1a27f1280d17c6d58a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/StrategieDeCommunication_jatmjo.png',
  },
  {
    id: 66,
    name: 'Bilan individuel des compétences et de notre Mini-Entreprise®',
    description: 'Consultez les ressources pour effectuer le bilan de vos compétences.',
    ressources: {
      videos: [
        {
          title: "Ce que j'ai appris cette année",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a4e1098f3b50cfb5341e6/deploy',
        },
      ],
      documents: [
        {
          title: 'Faire le bilan humain de sa Mini-Entreprise®',
          url: 'FairelebilanhumaindesaMiniEntreprise.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/BilanIndividuelDeCompe%CC%81tences_kbm4j0.png',
  },
  {
    id: 118,
    name: 'Attentes, craintes, questions des membres de notre Mini-Entreprise®',
    description:
      'Consultez les ressources pour organiser un atelier de démarrage de Mini-Entreprise®. ',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945340/Illustrations%20succ%C3%A8s/AtteintesCraintesQuestionsDesMembresDeNotreMiniEntreprise_zjokpp.png',
  },
  {
    id: 119,
    name: 'Entrepreneur, intrapreneur, quelle différence?',
    description:
      "Consultez les ressources pour comprendre les différentes manières d'entreprendre.",
    ressources: {
      videos: [
        {
          title: "Qu'est-ce qu'un entrepreneur ?",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d936599f8c8eb0cf33bed97/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/EntrepreneurIntrapreneurQuelleDifference_g6rbpy.png',
  },
  {
    id: 120,
    name: "Visite d'une entreprise",
    description:
      "Consultez les ressources pour savoir comment organiser la visite d'une entreprise",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945346/Illustrations%20succ%C3%A8s/VisiteDUneEntreprise_a686a0.png',
  },
  {
    id: 65,
    name: 'Présentation de notre projet',
    description: "Consultez les ressources pour découvrir ce qu'est le marketing. ",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/NotreStrate%CC%81gieMarketing_mzmuw5.png',
  },
  {
    id: 59,
    name: 'Nous avons organisé notre Mini-Entreprise®',
    description: 'Nous avons organisé notre Mini-Entreprise ',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1664268646/Illustrations%20succ%C3%A8s/LeDroitAlImage_u33uko.png',
  },
  {
    id: 62,
    name: 'Nous avons trouvé le nom de notre Mini-Entreprise® et son logo',
    description: 'Nous avons trouvé le nom de notre Mini-Entreprise® et son logo',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1664268646/Illustrations%20succ%C3%A8s/LeDroitAlImage_u33uko.png',
  },
  {
    id: 58,
    name: 'Notre mentor a validé le choix de notre produit',
    description: 'Notre mentor a validé le choix de notre produit',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1664268646/Illustrations%20succ%C3%A8s/LeDroitAlImage_u33uko.png',
  },
];
const successExtratLarge = successExtratLargeData;
export const getSuccessFromExcel = (type) => {
  switch (type) {
    case projectTypeKeys.L:
      return successLarge;
      break;
    case projectTypeKeys.XL:
      return successExtratLarge;
      break;
    case projectTypeKeys.M13:
      return successMedium;
    case projectTypeKeys.X10:
      return successes10XData;
    case projectTypeKeys.M9:
      return [];
    default:
      break;
  }
};
export const parseSuccessFromExcel = (successes, type) => {
  if (successes && successes.length > 0 && type !== null) {
    return successes.map((success) => {
      const newSuccess = getSuccessFromExcel(type).filter((item) => item.id === success.id)[0];
      return {
        ...success,
        //  name: newSuccess?.name,
        description: newSuccess ? newSuccess?.description : "Ressources mises à disposition prochainement.",
        ressources: newSuccess ? newSuccess?.ressources : { videos: [], documents: [] },
        illustration: newSuccess ? newSuccess?.illustration : null,
      };
    });
  } else {
    return [];
  }
};
export const getDiffSuccesses = (allSuccess, successes) => {
  return allSuccess.filter((item) =>
    successes
      .map((success) => {
        return success.id;
      })
      .every((value) => value !== item.id),
  );
};
