import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { DetailSucces } from './DetailSucces';
import { replace, goBack } from 'react-router-redux';
import { ProjectsActions } from '../../../redux/projects';
import { ConfirmPopupActions } from '../../../redux/confirmPopup';

const mapStateToProps = (state) => ({
  project: state.projects.project,
  strings: getTranslate(state.locale),
  role: state.user.user.role,
});

const mapDispatchToProps = (dispatch) => ({
  getProjectDetails: (id) => dispatch(ProjectsActions.getProjectDetailRequested(id)),
  updateSuccesses: (projectId, successes) =>
    dispatch(ProjectsActions.updateSuccessesRequested(projectId, successes)),
  updateSuccessesCancel: (projectId, successes) =>
    dispatch(ProjectsActions.updateSuccessesCancel(projectId, successes)),
  pop: () => dispatch(goBack()),
  navigateTo: (path) => dispatch(replace(path)),
  deleteSuccess: (projectId, successId) =>
    dispatch(ProjectsActions.deleteSuccessvalidated(projectId, successId)),
  showPopup: (message, popupAction) =>
    dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailSucces);
