import { methods } from '../utils';
import { authenticatedService } from './middleware';

const {GET, PATCH} = methods;
const routes = {
  getUser: id => `/users/${id}`,
  changePassword: id => `/users/${id}/password`,
};

export default {
  getUser: id => authenticatedService(GET, routes.getUser(id), {}, {}),
  updateUser: (id, body) => authenticatedService(PATCH, routes.getUser(id), body, {}),
  changePassword: (id, body) => authenticatedService(PATCH, routes.changePassword(id), body, {}),
};
