import moment from 'moment';

export const modifiersStyles = {
  sundays: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#262626',
    border: 'none',
    padding: '10px',
    margin: '10px',
  },
  allDays: {
    // color: '#535D5F',
    // backgroundColor: 'rgba(239,242,244,0.5)',
    // borderRadius: '2px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#262626',
    border: 'none',
  },
  today: {
    border: 'none',
  },
  selectedDay: {
    color: 'white',
    backgroundColor: '#29828C',
    border: 'none',
    borderRadius: '50%',
  }
};

export const modifiers = (selectedDay) => ({
  /* L'odre est important à cause de la surcharge via les "modifiersStyles", comme en CSS */
  sundays: { daysOfWeek: [0] },
  allDays: { daysOfWeek: [1, 2, 3, 4, 5, 6] },
  // daysWithEvents: meetings.datesForCalendar.map(m => moment(m.date).toDate()),
  today: new Date(),
  selectedDay: moment(selectedDay).toDate(),
});