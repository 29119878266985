import React, { useState } from 'react';
import { icons } from '../../../../../assets';
import './school-item.scss';

export const SchoolItem = ({school, strings}) => {
  const [displayPhone, setDisplayPhone] = useState(false);

  const onClickPhone = () => window.open(`tel:${school.secretariatPhone}`);

  return (
    <div className={'school-item'}>
      <div className={'title'}>{strings('school')}</div>
      <p>{school.name}</p>
      <p>{school.postalCode} {school.city}</p>
      <div className={'contact'}>
        {school.secretariatPhone && 
          <i 
            className={'material-icons school-phone-icon'}
            onClick={onClickPhone}
            onMouseEnter={() => setDisplayPhone(true)}
            onMouseLeave={() => setDisplayPhone(false)}
          >
              {icons.PHONE}
              <span
                className={`school-phone ${displayPhone && 'displayed'}`}
              >{school.secretariatPhone}</span>
          </i>
        }
      </div>
    </div>
  )
};
