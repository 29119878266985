import { Planning } from './Planning';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { getTranslate } from 'react-localize-redux';
import { ProjectsSelectors, ProjectsActions } from '../../../redux/projects';
import { ConfirmPopupActions } from '../../../redux/confirmPopup';
import { getPevLocation } from '../../../utils';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  backLink: getPevLocation(state.router.location.pathname),
  meetings: ProjectsSelectors.formatMeetings()(state),
  projectId: state.projects.project.id,
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
  getProject: projectId => dispatch(ProjectsActions.getProjectDetailRequested(projectId)),
  addMeetingToProject: (projectId, meeting) => dispatch(ProjectsActions.addMeetingRequested(projectId, meeting)),
  deleteMeeting: (projectId, meetingId) => dispatch(ProjectsActions.deleteMeetingRequested(projectId, meetingId)),
  updateMeeting: (projectId, meetingId, meeting) => dispatch(ProjectsActions.updateMeetingRequested(projectId, meetingId, meeting)),
  showPopup: (message, popupAction) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  addBudgetToProject: (projectId, budget) => dispatch(ProjectsActions.addBudgetRequested(projectId, budget)),
  deleteBudget: (projectId, budgetId) => dispatch(ProjectsActions.deleteBudgetRequested(projectId, budgetId)),
  updateBudget: (projectId, budgetId, budget) => dispatch(ProjectsActions.updateBudgetRequested(projectId, budgetId, budget)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
