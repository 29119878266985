import React from 'react';
import { icons, ressources } from '../../../assets';
import { downloadFile } from '../../../utils/service';
import { Button, ButtonColors } from '../../components';
import { saveAs } from 'file-saver';
const FileItem = (props) => {
  const { title, type, url } = props;
  
const downloadFime =async(url)=>{
 saveAs('ressources/'+url);
}
  return (
    <div className={'file-item'}>
      <div className={'title'}>
        <p> {title}</p>
        <p> {type}</p>
      </div>
      <div>
        <a href={ressources[url.split('.')[0]]} className="download_file" download>
          <Button
            color={ButtonColors.green500}
            label={`Télécharger`}
            className={'action-button'}
            icon={icons.DOWNLOAD}
            reverse={true}
            enabled={true}
            /*  action={() => downloadFime(url)} */
            /*  enabled={url !== '#' || url === ''} */
          />
        </a>
      </div>
    </div>
  );
};

export default FileItem;
