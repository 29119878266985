import React from 'react';
import { images } from '../../../../assets';
import './footer-item.scss';

export const FooterItem = ({code, password, strings}) => {

  return (
    <div className={'footer-item'}>
      <div className={'code'}>
        <img src={images.link} alt={'code'}/>
        <p>{code}</p>
      </div>
      <div className={'password'}>
        <img src={images.lock} alt={'password'}/>
        <p>{password}</p>
      </div>
    </div>
  )
};
