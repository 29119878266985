import React from 'react';
import { getIcon } from '../../../../utils';

const Tools = ({ label, tools }) => {
  const handleClick = (url) => () => window.open(url, '_blank');
  
  return (
    <div className={'tools column'}>
      <p className={'label'}>{label}</p>
      {tools.map((tool) => (
        <div key={tool.id} className={'tool row'} onClick={handleClick(tool.url)}>
          {getIcon(tool.icon)}
          <p>{tool.label}</p>
        </div>
      ))}
    </div>
  );
};

export default Tools;
