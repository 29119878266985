import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { logger } from '../../../utils';
import './input.scss';

export class Input extends PureComponent {

  state = {
    value: this.props.defaultValue || '',
    isEditable: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.defaultValue !== this.state.value && this.props.defaultValue !== prevProps.value) {
      this.setState({value: this.props.defaultValue});
    }
  }

  valueChanged = event => {
    const {value} = event.target;
    const {tag, handleValueChanged} = this.props;

    this.setState({value});

    handleValueChanged(tag, value);
  };

  onFocus = () => this.setState({ isEditable: true});
  onBlur = () => {
    const {onblur} = this.props;
    onblur && onblur(this.state.value);
    this.setState({ isEditable: false});
  };
  
  handleKeyPress = e => {
    if (e.which === 13) {
      this.setState({isEditable: false});
      e.currentTarget.blur();
    }
  };

  render() {
    const {label, placeholder, type, className, inputClassName, error, maxLength, isHighlighted = true} = this.props;
    const {value, isEditable} = this.state;
    
    return (
      <div className={`input-wrapper ${className}`}>
        <span className={'label'}>{label}</span>
        <input
          className={`${isHighlighted && 'highlight'} ${!isEditable && inputClassName}`}
          type={type}
          ref={this.input}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength ? maxLength : ''}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.valueChanged}
          onKeyPress={this.handleKeyPress}
          spellCheck={false}
        />
        { error && <span className={'error'}>{error}</span> }
      </div>
    );
  }
}

Input.propsType = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleValueChanged: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  handleValueChanged: () => logger.warn('handleValueChanged not implemented.'),
};


