import React, { Fragment, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { icons } from '../../../../../assets';
import { SuccessItem } from './SuccessItem';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import AchevedSuccessItem from '../../../detailSuccess/list/detailSuccessItem/successItems/AchevedSuccessItem';

function Items({ currentItems, action, succesDetail }) {

  return (
    currentItems &&
    currentItems.length > 0 &&
    currentItems.map((succes, index) => (
      <SuccessItem
        key={index}
        succes={succes}
        addIdem={true}
        action={action}
        succesDetail={succesDetail}
      />
    ))
  );
}
function ItemsSuccess({ currentItems, action, succesDetail }) {
  return (
    currentItems &&
    currentItems.length > 0 &&
    currentItems.map((success, index) => (
      <AchevedSuccessItem
        key={index}
        success={success}
        addIdem={true}
        action={action}
        succesDetail={succesDetail}
      />
    ))
  );
}
const PaginatedItems = (props) => {
  const { items, itemsPerPage, pageRange, action, isDashboard = true, succesDetail } = props;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    setPageNumber(event.selected + 1);
  };

  return (
    <Fragment>
      {isDashboard ? (
        <Items
          currentItems={currentItems || items.slice(0, 2)}
          action={action}
          succesDetail={succesDetail}
        />
      ) : (
        <ItemsSuccess currentItems={currentItems} succesDetail={succesDetail} />
      )}
      {currentItems && currentItems.length > 0 && (
        <ReactPaginate
          className={'paginate-container'}
          breakLabel="..."
          nextLabel={
            pageNumber < pageCount ? (
              isDashboard ? (
                <BiChevronRight size={25} style={{ color: '#1E1E1E' }} />
              ) : (
                <div className={'page-swip'}>
                  Page suivante <FiArrowRight size={20} style={{ color: '#1E1E1E' }} />
                </div>
              )
            ) : (
              ''
            )
          }
          previousLabel={
            pageNumber > 1 ? (
              isDashboard ? (
                <BiChevronLeft size={25} style={{ color: '#1E1E1E' }} />
              ) : (
                <div className={'page-swip'}>
                  <FiArrowLeft size={20} style={{ color: '#1E1E1E' }} /> Page précédente
                </div>
              )
            ) : (
              ''
            )
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={pageRange}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
        />
      )}
      {!isDashboard && pageCount > 0 && (
        <div className="page-count"> {`${pageNumber} / ${pageCount}`} </div>
      )}
      {isDashboard && pageCount > 0 && (
        <div className="page-count-phone "> {`${pageNumber} / ${pageCount}`} </div>
      )}
    </Fragment>
  );
};

export default PaginatedItems;
