import { createAction } from '../../utils/index';

// Types
export const types = {
  GET_NOTIFICATIONS_REQUESTED: "GET_NOTIFICATIONS_REQUESTED",
  GET_NOTIFICATIONS_SUCCEEDED: "GET_NOTIFICATIONS_SUCCEEDED",
  SET_NOTIFICATIONS_READ_REQUESTED: 'SET_NOTIFICATIONS_READ_REQUESTED',
  SET_NOTIFICATIONS_READ_SUCCEEDED: 'SET_NOTIFICATIONS_READ_SUCCEEDED',
};

// Actions
export default {
  getNotificationsRequested : () => createAction(types.GET_NOTIFICATIONS_REQUESTED, {}),
  getNotificationsSucceeded : list => createAction(types.GET_NOTIFICATIONS_SUCCEEDED, {list}),
  setNotificationsReadRequested: () => createAction(types.SET_NOTIFICATIONS_READ_REQUESTED, {}),
  setNotificationsReadSucceeded: (list) => createAction(types.SET_NOTIFICATIONS_READ_SUCCEEDED, {list}),
};
