import React, { useState } from 'react';
import { icons } from '../../../assets';
import './screen-header.scss';
import { Button, ButtonColors } from '../buttons';
import { projectStatus } from '../../../utils';

export const ScreenHeader = React.memo(({showFilters = false, handleFilterChange, strings, screenName, arrowAction, showBackAction = true, className, projectsLength, handleDataCollection = null,redirectTo = null}) => {

  const statusFilter = projectStatus;
  const [selectedButton, setSelectedButton] = useState('ALL');

  const handleInputChange = (status) => {
    setSelectedButton(status);
    handleFilterChange(status);
  }
  return (
    <div id={'screenHeader'} className={className}>
      {showBackAction && (
        <i className={'material-icons arrow-back link'} onClick={arrowAction}>
          {icons.ARROW_BACKIOS}
          <span> Retour</span>
        </i>
      )}
      {showFilters && (
        <div className={'links'}>
          <p onClick={redirectTo ? arrowAction : arrowAction}>
          {projectsLength > 1 ? `${projectsLength} ${strings(screenName)}` :  `${projectsLength} ${strings(screenName)}`}
          </p>
          <span>Filtrer par statut :</span>
          {Object.keys(statusFilter).map((status, index) => {
            if (status !== 'ARCHIVED') {
              return (
                <li className='list-filter' key={index}>
                  <button key={index} className={`btn-filter ${selectedButton === status ? 'active' : ''}`} onClick={() => handleInputChange(status)}>
                    {strings(`project_status_${status.toLowerCase()}`)}
                  </button>
                </li>
              )
            }

          })}

          {handleDataCollection && (
            <div className={'button-wrapper'}>
              <Button
                className={'data-collection'}
                label={strings('manage')}
                color={ButtonColors.orange}
                action={handleDataCollection}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
});
