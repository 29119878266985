import React, { PureComponent } from 'react';
import { icons, images } from '../../../assets';
import { usePageViews } from '../../../utils';
import { Button, ButtonColors, ScreenHeader } from '../../components/index';
import AddBudget from './addBudget';
import './detail-budget-list.scss';
import { BudgetItem } from './detailBudgetItem/DetailBudgetItem';
import { DetailBudgetMobile } from './detailBudgetItem/DetailBudgetMobile';
import { BudgetSummary } from './DetailBudgetSummary';

export class DetailBudgetList extends PureComponent {
  componentDidMount() {
    this.props.getOperationTypes();
  }
  
  state = {
    isEditionAvailable: false,
    isEditionVisible: false,
    entryToModify: null,
    screenSize: 680,
  };

  componentDidMount() {
    const { projectId } = this.props.match.params;
    if (
      !this.props.project.id ||
      this.props.project.id !== parseInt(projectId, 10)
    ) {
      this.props.getProject(parseInt(projectId, 10));
    }
  }

  componentDidUpdate() {
    if (this.props.operationTypes.length > 0) {
      this.setState({ isEditionAvailable: true });
    }
  }

  handleCloseBudget = () => {
    this.setState({ isEditionVisible: false });
    this.setState({ entryToModify: null });
  };

  handleAddBudget = () => {
    this.setState({ isEditionVisible: true });
    if (this.props.operationTypes.length === 0) {
      this.props.getOperationTypes(false);
    }
  };

  handleUpdateBudget = entryId => {
    this.setState({
      entryToModify: this.props.project.budget.entries.find(
        search => search.id === entryId
      ),
    });
    this.setState({ isEditionVisible: true });
    if (this.props.operationTypes.length === 0) {
      this.props.getOperationTypes(false);
    }
  };

  handleDeleteBudget = id => {
    const projectId = this.props.project.id;
    this.props.deleteBudget(projectId, id);
  };

  handleOpenConfirm = id => {
    this.props.showPopup(
      this.props.strings('confirmation_question_budget'),
      () => this.handleDeleteBudget(id)
    );
  };

  handleDownload = () => {
    const projectId = this.props.project.id;
    this.props.downloadBudgetEntries(projectId);
  };

  render() {
   
    const { project, strings, pop, operationTypes, role } = this.props;
    const { budget } = project;
    const entries = budget ? budget.entries : [];

    const { isEditionAvailable, isEditionVisible, entryToModify } = this.state;
    //usePageViews();
    return (
      <div id={'detailBudget'}>
        {isEditionVisible && isEditionAvailable && (
          <AddBudget
            strings={strings}
            project={project}
            operationTypes={operationTypes}
            handleCloseBudget={this.handleCloseBudget}
            entry={entryToModify}
          />
        )}
        <img className={'background'} src={images.epaBackground} alt={'background'} />
        <ScreenHeader screenName={'dashboard'} arrowAction={pop} />
        <div className={'main fade'}>
          <div className={'entries'}>
            <div className={'header'}>
              <div className={'col date'}>
                <p>{strings('budget_date')}</p>
              </div>
              <div className={'col object'}>
                <p>{strings('budget_object')}</p>
              </div>
              <div className={'col type'}>
                <p>{strings('budget_type')}</p>
              </div>
              <div className={'col execution'}>
                <p>{strings('budget_execution_date')}</p>
              </div>
              <div className={'col amount'}>
                <p>{strings('budget_amount')}</p>
              </div>
              <div className={'col delete'} />
            </div>
            <div className={'list'}>
              {entries &&
                entries.map((entry) => (
                  <>
                    <div className="listeItemLarge" key={entry.id}>
                      <BudgetItem
                        entry={entry}
                        strings={strings}
                        id={entry.id}
                        role={role}
                        handleUpdateBudget={this.handleUpdateBudget}
                        handleOpenConfirm={this.handleOpenConfirm}
                      />
                    </div>
                    <div className="listeItemSmall" key={entry.id}>
                      <DetailBudgetMobile
                        entry={entry}
                        strings={strings}
                        id={entry.id}
                        role={role}
                        handleUpdateBudget={this.handleUpdateBudget}
                        handleOpenConfirm={this.handleOpenConfirm}
                      />
                    </div>
                  </>
                ))}
            </div>
            <div className={'footer'}>
              <Button
                className={'add_button btn_outlined'}
                label={strings('add')}
                color={ButtonColors.green500}
                icon={icons.ADD}
                action={this.handleAddBudget}
              />
              <Button
                className={'download'}
                label={strings('download_list')}
                color={ButtonColors.green500}
                icon={icons.DOWNLOAD}
                action={this.handleDownload}
              />
            </div>
          </div>
          <BudgetSummary budget={budget} strings={strings} />
        </div>
      </div>
    );
  }
}
