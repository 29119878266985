import React from 'react';
import { icons } from '../../../../assets';
import { history } from '../../../../core/history';
import { Routes } from '../../../../utils';
import { DropDown } from '../../../components';

const Navigation = ({ strings, miniId, rooms, roomIndex, navigateTo, pop }) => {
  const handleBack = () => {
    if (history.oldLocation && history.oldLocation.pathname === Routes.PROJECT_LIST) {
      pop();
    } else {
      navigateTo(Routes.HOME);
    }
  };

  const handleRoomSelected = (response) => {
    if (response.value !== roomIndex) {
      navigateTo(Routes.ROOM_DETAIL.replace(':miniId', miniId).replace(':roomId', rooms[response.value]));
    }
  };

  const roomsDropDown = rooms.map((r, index) => `${strings('room_number')}${index + 1}`);
  return (
    <div className={'navigation row'}>
      <i className={'material-icons'} onClick={handleBack}>
        {icons.ARROW_BACK}
      </i>
      <p className={'title'}>{strings('dashboard')}</p>
      {rooms && (
        <div className={'room-picker row'}>
          <div className={'vertical-separator'} />
          <DropDown current={roomsDropDown[roomIndex]} data={roomsDropDown} className={'rooms'} handleClickItem={handleRoomSelected} />
          <div className={'vertical-separator'} />
        </div>
      )}
    </div>
  );
};

export default Navigation;
