import React from 'react'
import Iframe from "react-iframe"
import { images } from '../../../assets'
import ReactPlayer from 'react-player'
const VideoItem = (props) => {
  const {url,title,validated,onPalyVideo,index} = props
  return (
    <div className={`video-card ${validated ? 'checked' : ''}`}>
      <div className="card-header">
        <iframe
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"
          loading="lazy"
        ></iframe>
        <div className="play-button">
          <img src={images.playButton} alt="play button" onClick={() => onPalyVideo(index)} />
        </div>
      </div>
      <div className={'card-footer'}>
        <div className={'title'}>{title}</div>
        <img src={images.expand} alt="expand" onClick={() => onPalyVideo(index)} />
      </div>
    </div>
  );
}

export default VideoItem
