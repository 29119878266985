import { push } from 'react-router-redux';
import { Footer } from './Footer';
import {getTranslate} from "react-localize-redux";
import {connect} from 'react-redux'
import {footerLink} from '../../../utils/enum';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  footerLink: Object.entries(footerLink).map(([key, value]) => ({label: getTranslate(state.locale)(value.label) , link: value.link}))
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
