import FileSaver from 'file-saver';
import Axios from 'axios';
export const downloadAttachment = (response) => {
  const {headers, data} = response;
  const contentDisposition = headers['content-disposition'];
  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    let filename = '';
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"?]/g, '');
    }
    FileSaver.saveAs(data, filename);
  }
};

export const uploadFile = async (file) => {
   const formatData = new FormData()
      formatData.append('file', file)
      formatData.append('upload_preset', "project_profil")
      const response = await Axios.post("https://api.cloudinary.com/v1_1/dcmzzdl4g/image/upload", formatData)
  return response ? response.data:''
}