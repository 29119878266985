import { Pie } from '@nivo/pie';
import React, { useState } from 'react';
import { icons } from '../../../../../assets';
import { filterAndSortUsers, getProjectImage, projectTypeKeys, Routes, projectStatus } from '../../../../../utils';
import FooterItem from '../../footerItem';
import SchoolItem from '../schoolItem';
import UserItem from '../userItem';
import { pieStyle } from './index';
import './project-item.scss';

export const ProjectItem = ({ project, showContactsInfo, strings, navigateTo, currentUserId }) => {
  const [more, setMore] = useState(false);
  const { id, name, users, type, school, code, password, progress, status } = project;
  const projectUsers = filterAndSortUsers(users, currentUserId);

  const getStatusLabel = status => {
    switch (status) {
      case projectStatus.VALIDATED:
        return strings('project_status_validated');
      case projectStatus.ARCHIVED:
        return strings('project_status_archived');
      case projectStatus.COMMING:
        return strings('project_status_comming');
      case projectStatus.FINISHED:
        return strings('project_status_finished');
      default:
        return '';
    }
  };

  const getUserList = (role, users) => {
    return (
      <div className={`role ${role.toLowerCase()}`} key={role}>
        <div className={'name'}>{strings(role)}</div>
        <div className={'users'}>
          {users.map((user) => (
            <UserItem user={user} key={user.id} strings={strings} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={'project-item fade'}>
      <div className={'container'}>
        <div
          className={'header'}
          onClick={() => navigateTo(Routes.PROJECT_DETAIL.replace(':projectId', id))}
        >
          <div className={'middle-header'}>
            <hr className={'separator'} />
            {getProjectImage(strings, type)}
            <div className={'pie'}>
              <Pie
                data={[
                  { id: 'curr', value: progress, color: '#00763d' },
                  {
                    id: 'tot',
                    value: 100 - progress,
                    color: '#f8f8f9',
                  },
                ]}
                {...pieStyle}
              />
              <p className={'total'}>{`${progress}%`}</p>
            </div>
            <i className={'material-icons'}>{icons.ARROW_FORWARD}</i>
          </div>
          <div className={"title"}></div>
          <div className={'wrapper'}>
            <div className={"title"}>
              <span>{name || strings('namelessProject')}</span>
            </div>
            <div className={"status"}>
              <p>{strings(`project_status_${status.toLowerCase()}`)}</p>
              <div className={`state ${status.toLowerCase()}`} />
            </div>
          </div>
        </div>
        <hr className={'separator'} />
        <div className={`info-list ${more && 'expanded'}`}>
          <div className={'wrapper'}>
            <SchoolItem school={school} strings={strings} />
            {projectUsers.supervisors.length > 0 &&
              getUserList('supervisors', projectUsers.supervisors)}
          </div>
          <div
            className={`wrapper ${
              projectUsers.mentors.length > 0 && projectUsers.facilitators.length > 0
                ? 'halfed'
                : 'full'
            }`}
          >
            {projectUsers.mentors.length > 0 && getUserList('mentors', projectUsers.mentors)}
            {projectUsers.facilitators.length > 0 &&
              getUserList('facilitators', projectUsers.facilitators)}
          </div>
        </div>
        <div className="more-container">
          <div className={'more'} onClick={() => setMore(!more)}>
            {more ? 'Réduire' : 'Voir plus ...'}
          </div>
        </div>
      </div>
      <FooterItem code={code} password={password} strings={strings} />
    </div>
  );
};
