import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { logger } from '../../../utils';
import './input.scss';

export class Input extends PureComponent {

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  state = {
    forceFloat: false,
  };

  componentDidMount() {
    const {defaultValue} = this.props;
    this.setState({forceFloat: defaultValue});
  }

  valueChanged = event => {
    const {value} = event.target;
    const {tag, handleValueChanged} = this.props;

    handleValueChanged(tag, value);
  };

  render() {
    const {label, placeholder, type, className, inputClassName, defaultValue, maxLength, image, alt, unit} = this.props;

    return (
      <div className={`input ${className}`}>
        <div className={'wrapper'}>
          <input
            type={type}
            ref={this.input}
            placeholder={placeholder}
            defaultValue={defaultValue ? defaultValue : ''}
            onChange={this.valueChanged}
          />
          {image && <img src={image} alt={alt}/>}
          {unit && <p className={'unit'}>{unit}</p>}
        </div>
      </div>
    );
  }
}

Input.propsType = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleValueChanged: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  handleValueChanged: () => logger.warn('handleValueChanged not implemented.'),
};


