import React from 'react';
import { images } from '../assets/images';
import { projectTypeKeys as type } from './enum';

export const getProjectImage = (strings, name) => {
  switch (name) {
    case type.L:
      return (
        <div className={'mini-icon-container'}>
          <img className={'mini-icon'} src={images.epaLogoMiniL} alt={'Icon Mini L'} />
        </div>
      );
    case type.XL:
      return (
        <div className={'mini-icon-container'}>
          <img className={'mini-icon'} src={images.epaLogoMiniXL} alt={'Icon Mini XL'} />
        </div>
      );
    case type.S:
      return (
        <div className={'mini-icon-container'}>
          <img className={'mini-icon'} src={images.epaLogoMiniS} alt={'Icon Mini S'} />
        </div>
      );
    case type.X10:
      return (
        <div className={'mini-icon-container challenge'}>
          <img
            className={'mini-icon'}
            src={images.epaLogoChallenge10X}
            alt={'Icon Challenge 10X'}
          />
        </div>
      );
    case type.M9:
      return (
        <div className={'mini-icon-container'}>
          <img className={'mini-icon'} src={images.epaLogoMiniM} alt={'Icon Mini M9'} />
          <p>{strings('project_m9_age')}</p>
        </div>
      );
    case type.M13:
      return (
        <div className={'mini-icon-container'}>
          <img className={'mini-icon'} src={images.epaLogoMiniM} alt={'Icon Mini M13'} />
          <p>{strings('project_m13_age')}</p>
        </div>
      );
    default:
      return <></>;
  }
};
