import { ConnectedRouter } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import React, { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AuthActions } from '../redux/auth';
import { UserActions } from '../redux/user';
import { clearUrlParams, origins } from '../utils';
import { history } from './history';
import screens from './screens';
import store from './store';
import { Routes } from '../utils';
import { ProjectsActions } from '../redux/projects';
 import TagManager from 'react-gtm-module';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { accessToken, refreshToken } = store.getState().auth;
  const tokens = qs.parse(rest.location.search, { ignoreQueryPrefix: true });

 useEffect(() => {
   TagManager.initialize({ gtmId: 'GTM-5F36KT7' });
 }, []);
  return (
    <Route
      {...rest}
      render={props => {
        if (tokens.accessToken && tokens.refreshToken) {
          const decoded = jwtDecode(tokens.accessToken);

          const user = {
            id: decoded.id,
            role: decoded.role,
            email: decoded.email,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
          };
          store.dispatch(
            AuthActions.refreshTokensSucceed(
              decoded.id,
              tokens.accessToken,
              tokens.refreshToken
            )
          );
          store.dispatch(UserActions.getUserSucceeded(user));

          if (
            decoded.tempUser &&
            accessToken &&
            decoded.family_name !== jwtDecode(accessToken).family_name
          ) {
            store.dispatch(ProjectsActions.resetProjectDetail());
          }
          return <Component {...props} />;
        }

        return accessToken && refreshToken ? (
          <Component {...props} />
        ) : (
          (window.location.href = `${process.env.REACT_APP_AUTH_URL}?origin=${
            origins.DASHBOARD
          }&redirectUrl=${clearUrlParams(window.location.href)}`)
        );
      }}
    />
  );
};
const simpleRoute = () =>
  Object.entries(screens).map(([key, value]) => (
    <AuthRoute exact key={key} path={value.path} component={value.component} />
  ));
const ScrollToTop = () => {
  history.listen(e => window.scrollTo(0, 0));
  return null;
};

export default function Router() {
  return (
    <ConnectedRouter history={history}>
      <Fragment>
        <ScrollToTop />
        <Switch>
          {simpleRoute()}
          <Redirect
            exact
            from={Routes.HOME}
            to={`${Routes.PROJECT_LIST}${history.location.search}`}
          />
        </Switch>
      </Fragment>
    </ConnectedRouter>
  );
}
