import { connect } from 'react-redux';
import {ProjectsActions} from '../../../../redux/projects';
import { getTranslate } from "react-localize-redux";
import { goBack, push } from 'react-router-redux';
import { DetailSuccessList } from './DetailSuccessList';
import { ConfirmPopupActions } from '../../../../redux/confirmPopup';

const mapStateToProps = (state) => ({
  project: state.projects.project,
  isAdded: state.projects.successAdded,
  strings: getTranslate(state.locale),
  role: state.user.user.role,
});

const mapDispatchToProps = (dispatch) => ({
  showPopup: (message, popupAction) =>
    dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  getProjectDetails: (id) => dispatch(ProjectsActions.getProjectDetailRequested(id)),
  updateSuccesses: (projectId, successes) =>
    dispatch(ProjectsActions.updateSuccessesRequested(projectId, successes)),
  pop: () => dispatch(goBack()),
  navigateTo: (path) => dispatch(push(path)),
  addSuccesToProject: (projectId, success) =>
    dispatch(ProjectsActions.addSuccessRequested(projectId, success)),
  deleteSuccess: (projectId, successId) =>
    dispatch(ProjectsActions.deleteSuccessRequested(projectId, successId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailSuccessList);
