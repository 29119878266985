import React, { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import "./confetti.scss"
const ConfettiSuccess = ({show,numberOfPieces}) => {
  const [height,setHeight] = useState(null)
  const [width, setWidth] = useState(null)
  const confettiRef = useRef(null)
  useEffect(() => {
    setHeight(confettiRef.current.clientHeight)
    setWidth(confettiRef.current.clientWidth)
  }, [])
  return (
    <div ref={confettiRef} className={`confetti-wrapper ${show ? 'show' : 'hide'}`}>
      <Confetti
        tweenDuration={1000}
        width={width}
        height={height}
        numberOfPieces={numberOfPieces}
      />
    </div>
  );
}

export default ConfettiSuccess
