import { createSelector } from 'reselect';
import { formatDateForDisplay, budgetOperation } from '../../utils';
import moment from 'moment';

const projectSelector = (state) => state.projects.project || {};
const successesSelector = (state) => state.projects.project.successes || [];
const operationTypesSelector = (state) => state.projects.operationTypes || [];
const categoriesSelector = (state) => state.projects.categories || [];
const levelsSelector = (state) => state.projects.levels || [];
const sectorsSelector = (state) => state.projects.sectors || [];
const timesSelector = (state) => state.projects.times || [];
const lastTimeRetrievingListSelector = (state) => state.projects.lastTimeRetrievingList || [];

const formatMeetings = () =>
  createSelector(projectSelector, (project) => {

    const { meetings } = project;
    let allEvents = [];
    let comingEvents = [];
    let monthlyEvents = [];
    let datesForCalendar = [];
    if (meetings && meetings.coming.length > 0) {
      comingEvents = meetings.coming.map((event) => ({
        id: event.id,
        date: formatDateForDisplay(event.date),
        description: event.description,
      }));
    }

    if (meetings && meetings.all.length > 0) {
      monthlyEvents = meetings.all
        .filter((event) => moment(event.date).isAfter(moment().startOf('month')) && moment(event.date).isBefore(moment().endOf('month')))
        .map((event) => ({
          id: event.id,
          date: formatDateForDisplay(event.date),
          description: event.description,
        }));

      datesForCalendar = meetings.all.map((event) => ({
        id: event.id,
        date: event.date,
        description: event.description,
      }));

      allEvents = meetings.all.map((event) => ({
        id: event.id,
        date: formatDateForDisplay(event.date),
        description: event.description,
      }));
    }
    return {
      all: allEvents,
      coming: comingEvents,
      monthly: monthlyEvents,
      datesForCalendar,
    };
  });

const formatComingMeetings = () => createSelector(projectSelector, (project) => {
  const { meetings } = project;
  const meetingsList = meetings.coming?.length > 0 ? meetings.coming.slice(0, 3) :[]

  return meetingsList.map((event) => ({
    id: event.id,
    date: formatDateForDisplay(event.date),
    description: event.description,
  }))
});

const getPourcentageOfWonSuccesses = () =>
  createSelector(successesSelector, (successes) => {
    const successesList = successes.filter(item => item.validated || item.tobeRealized)
    if (successesList && successesList.length > 0) {
      let count = 0;
      successesList.map((success) => {
        if (success.validated) {
          count += 1;
        }
        return null;
      });
      return Math.floor((100 / successesList.length) * count);
    } else {
      return 0;
    }
  });

const getFirstSuccesses = () =>
  createSelector(successesSelector, (successes) => {
    const successesToDisplay = successes.slice(0, 4);
    return successesToDisplay;
  });
const getValidatedSuccesses = () =>
  createSelector(successesSelector, (successes) => {
    const successesToDisplay = successes.filter(success => success.validated );
    return successesToDisplay;
  });
  const getUnValidatedSuccesses = () =>
  createSelector(successesSelector, (successes) => {
    const successesToDisplay = successes.filter((success) => success.tobeRealized);
    return successesToDisplay;
  });
const getData = () =>
  createSelector(projectSelector, (project) => {
    const isDataFilled =
      project && project.data && project.data.classes && project.data.times && project.data.classes.length > 0 && project.data.times.length > 0;

    if (isDataFilled) {
      const classes = project.data.classes.map((item) => ({
        category: item.category.label,
        level: item.level.label,
        sector: item.sector.label,
        girlsCount: item.girlsCount,
        boysCount: item.boysCount,
        total: item.girlsCount + item.boysCount,
      }));
      const times = project.data.times.map((item) => ({ time: item.time.label, volume: item.volume }));
      return {
        classes,
        times,
        totalVolume: times.map((item) => item.volume).reduce((acc, val) => acc + val),
        totalStudents: classes.map((item) => item.total).reduce((acc, val) => acc + val),
      };
    }

    return null;
  });

const getOperationTypes = () =>
  createSelector(operationTypesSelector, (operationTypes) => {
    let structuredOperationTypes = {
      DEPOSIT: {
        labelList: operationTypes.filter((e) => e.operation === budgetOperation.DEPOSIT).map((operation) => operation.label),
        idList: operationTypes.filter((e) => e.operation === budgetOperation.DEPOSIT).map((operation) => operation.id),
      },
      WITHDRAW: {
        labelList: operationTypes.filter((e) => e.operation === budgetOperation.WITHDRAW).map((operation) => operation.label),
        idList: operationTypes.filter((e) => e.operation === budgetOperation.WITHDRAW).map((operation) => operation.id),
      },
    };

    return structuredOperationTypes;
  });

const shouldUpdateDataList = () =>
  createSelector(
    categoriesSelector,
    levelsSelector,
    sectorsSelector,
    timesSelector,
    lastTimeRetrievingListSelector,
    (categories, levels, sectors, times, lastTimeRetrievingLists) => {
      const lastRetrieval = moment(lastTimeRetrievingLists);
      const delta = moment().diff(lastRetrieval, 'days');
      return (
        !categories ||
        categories.length === 0 ||
        !levels ||
        levels.length === 0 ||
        !sectors ||
        sectors.length === 0 ||
        !times ||
        times.length === 0 ||
        (!isNaN(delta) && delta >= 1)
      );
    }
  );

export default {
  formatMeetings,
  formatComingMeetings,
  getPourcentageOfWonSuccesses,
  getFirstSuccesses,
  getValidatedSuccesses,
  getUnValidatedSuccesses,
  getOperationTypes,
  getData,
  shouldUpdateDataList,
};
