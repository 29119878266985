import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../../utils/constants';
import './successStyle.scss';
import { icons } from '../../../../../../assets';
import { useLocation } from 'react-router';
const AchevedSuccessItem = ({ success, succesDetail }) => {
  const { id, name, validated, validationDate } = success;
  const location = useLocation().pathname.match(/(\d+)/)[0];
  const validDate = validationDate ? moment(validationDate).format(DATE_FORMAT) : '';

  return (
    <div className={'card-success'} onClick={() => succesDetail(id)}>
      <div className={'body'}>
        <h4>{name}</h4>

        <div className={'actions'}>
          <div className={'medias'}>
            <p>{validDate && validDate}</p>
          </div>
          <div className={'btn'}>
            <i  className={'material-icons'}>
              {' '}
              {icons.CHECK_CIRCLE}{' '}
            </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AchevedSuccessItem;
