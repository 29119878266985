import React, { Fragment, useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import { icons } from '../../../assets';
import { meetingFilteringType } from '../../../utils';
import { Button, ButtonColors, ScreenHeader } from '../../components';
import { modifiers, modifiersStyles } from './calendarConfig';
import { EditMeeting } from './editMeeting';
import { Meetings } from './meetings';
import './planning.scss';

export const Planning = React.memo(
  ({
    match,
    navigateTo,
    strings,
    projectId,
    backLink,
    meetings,
    getProject,
    addMeetingToProject,
    deleteMeeting,
    showPopup,
    updateMeeting,
  }) => {
    const [selectedDay, setSelectedDay] = useState(null);
    const [isCreatingMeeting, setIsCreatingMeeting] = useState(false);
    const [meeting, setMeeting] = useState(null);
    const [filter, setFilter] = useState(meetingFilteringType.COMING);
    const [newMeeting, setNewMeeting] = useState(false)
    useEffect(() => {
      const { projectId: urlProjectId } = match.params;
      if (!projectId || projectId !== parseInt(urlProjectId, 10)) {
        getProject(parseInt(urlProjectId, 10));
      }
    }, [projectId]);

    const handleOnDayClick = e => {
      setSelectedDay(e);
      setFilter(meetingFilteringType.CURRENT)
      if (meeting) {
        setMeeting({ ...meeting, date: e });
        setIsCreatingMeeting(true);
      }
      else {
        setIsCreatingMeeting(newMeeting);
      }
    };

    const handleCancel = () => {
      setSelectedDay(null);
      setIsCreatingMeeting(false);
      setMeeting(null);
      setNewMeeting(false);
    };

    const handleAddNewMeeting = () => {
      setSelectedDay(new Date());
      setIsCreatingMeeting(true);
      setNewMeeting(true);
    };

    const saveNewMeeting = () => {
      setIsCreatingMeeting(true);
      if (meeting.id) {
        updateMeeting(projectId, meeting.id, {...meeting,date: selectedDay});
          setIsCreatingMeeting(false);
      } else {
        addMeetingToProject(projectId, meeting);
          setIsCreatingMeeting(false);
      }
      setNewMeeting(false);
       setSelectedDay(null);
      setMeeting(null);
      setFilter(meetingFilteringType.COMING)
    };

    const handleDeleteMeeting = m => {
      setSelectedDay(null);
      deleteMeeting(projectId, m.id);
      setMeeting(null);
      setNewMeeting(false);
      setIsCreatingMeeting(false);
    };

    const handleOpenConfirm = m =>
      showPopup(strings('confirmation_question'), () => handleDeleteMeeting(m));
     // usePageViews();
    return (
      <Fragment>
        <ScreenHeader
          screenName={'dashboard'}
          arrowAction={() => navigateTo(backLink)}
          showBackAction={true}
        />
        <div id={'planning'} className={'fade'}>
          <div
            className={`calendar ${
              (isCreatingMeeting || selectedDay) && 'add-padding'
            }`}
          >
            <div className={'calendar-bloc'}>
              <DayPicker
                localeUtils={MomentLocaleUtils}
                locale={'fr'}
                modifiers={modifiers(meetings, selectedDay)}
                modifiersStyles={modifiersStyles}
                showOutsideDays
                weekdaysShort={['D', 'L', 'M', 'M', 'J', 'V', 'S']}
                onDayClick={handleOnDayClick}
              />
              {!isCreatingMeeting && (
                <Button
                  color={ButtonColors.green500}
                  label={strings('add')}
                  icon={icons.ADD}
                  className={'add-button fade'}
                  action={handleAddNewMeeting}
                />
              )}
            </div>
          </div>
          {(!selectedDay && !isCreatingMeeting) || (selectedDay && !isCreatingMeeting) ? (
            <Meetings
              strings={strings}
              meetings={meetings}
              handleOpenConfirm={handleOpenConfirm}
              setSelectedDay={setSelectedDay}
              selectedDay={selectedDay}
              setMeeting={setMeeting}
              setIsCreatingMeeting={setIsCreatingMeeting}
              filter={filter}
              setFilter={setFilter}
            />
          ) :
           (
            <EditMeeting
              strings={strings}
              selectedDay={selectedDay}
              isCreatingMeeting={isCreatingMeeting}
              handleCancel={handleCancel}
              handleOpenConfirm={handleOpenConfirm}
              meeting={meeting}
              setMeeting={setMeeting}
              saveNewMeeting={saveNewMeeting}
              updateMeeting={updateMeeting}
              deleteMeeting={deleteMeeting}
            />
          )}

        </div>
      </Fragment>
    );
  }
);
