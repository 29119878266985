import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { civility, userRole } from "../../../../utils";
import { Button, ButtonColors, DropDown, Input, Switch } from "../../../components";
import { validateUserForm, validateMentorForm } from "./FormValidator";
import { default as MentorProfile } from "./MentorProfile";
import "./user.scss";

const civilities = [
  { key: civility.MR, label: "mister" },
  { key: civility.MME, label: "misses" },
];

const User = ({ strings, user, regions, updateProfile }) => {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [mentorForm, setMentorForm] = useState({});
  const [mentorFormErrors, setMentorFormErrors] = useState({});

  //Once the password have been changed, reset form
  useEffect(() => {
    setForm({
      civility: user.civility,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      region: user.region,
      displayContact: user.displayContact,
    });
    if (user.role === userRole.MENTOR) {
      setMentorForm({ ...user.profile });
    }
  }, [user]);

  useEffect(() => {
    scrollToFirstError();
  }, [formErrors, mentorFormErrors]);

  const onValueChanged = (tag, value) => {
    const newForm = JSON.parse(JSON.stringify(form));
    newForm[tag] = value;
    setForm(newForm);
    setFormErrors({});
    setMentorFormErrors({});
  };

  const handleCivilityChanged = (change) => onValueChanged("civility", civilities[change.value].key);

  const handleCheckChange = (checked) => onValueChanged("displayContact", checked);

  const handleRegionChanged = (change) => onValueChanged("region", regions[change.value]);

  const onMentorValueChanged = (tag, value) => {
    const newForm = JSON.parse(JSON.stringify(mentorForm));
    newForm[tag] = value;
    setMentorForm(newForm);
    setFormErrors({});
    setMentorFormErrors({});
  };

  const validateForm = () => {
    const userErrors = validateUserForm(form);
    const mentorErrors = user.role === userRole.MENTOR ? validateMentorForm(mentorForm) : [false];

    if (Object.values(userErrors).some((val) => val) || Object.values(mentorErrors).some((val) => val)) {
      setFormErrors(userErrors);
      setMentorFormErrors(mentorErrors);
    } else {
      updateProfile({ id: user.id, ...form, ...mentorForm });
    }
  };

  const scrollToFirstError = () => {
    const elementsWithError = document.querySelectorAll(".wrapper .invalid");
    const offsetTop = 80 + 32;
    if (elementsWithError && elementsWithError.length > 0 && elementsWithError[0].getBoundingClientRect().top < offsetTop) {
      window.scrollTo({
        top: elementsWithError[0].offsetTop - offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const userCivility = civilities.find((c) => form.civility === c.key);
  const enableButton = true;

  return (
    <div className={"user-form"}>
      <p>{strings("user_profile")}</p>
      <div className={"separator"} />
      <div className={"wrapper civility"}>
        <DropDown
          label={"civility"}
          className={"civility"}
          current={userCivility ? strings(userCivility.label) : null}
          data={civilities.map((c) => strings(c.label))}
          handleClickItem={handleCivilityChanged}
        />
        <Input
          label={strings("lastname")}
          className={"lastname"}
          inputClassName={`${formErrors.lastName ? "invalid" : ""}`}
          error={formErrors.lastName ? strings("input_required") : null}
          type={"text"}
          tag={"lastName"}
          defaultValue={form.lastName}
          handleValueChanged={onValueChanged}
        />
        <Input
          label={strings("firstname")}
          className={"firstname"}
          inputClassName={`${formErrors.firstName ? "invalid" : ""}`}
          error={formErrors.firstName ? strings("input_required") : null}
          type={"text"}
          tag={"firstName"}
          defaultValue={form.firstName}
          handleValueChanged={onValueChanged}
        />
      </div>
      <div className={"wrapper"}>
        <Input
          label={strings("email")}
          className={"email"}
          inputClassName={`${formErrors.email ? "invalid" : ""}`}
          error={formErrors.email ? (!form.phone ? strings("input_required") : strings("wrong_email")) : null}
          type={"email"}
          tag={"email"}
          defaultValue={form.email}
          handleValueChanged={onValueChanged}
        />
        <Input
          label={strings("phone_number")}
          className={"phone"}
          inputClassName={`${formErrors.phone ? "invalid" : ""}`}
          error={formErrors.phone ? (!form.phone ? strings("input_required") : strings("wrong_phone")) : null}
          type={"tel"}
          tag={"phone"}
          defaultValue={form.phone}
          handleValueChanged={onValueChanged}
        />
      </div>
      <div className={"wrapper region"}>
        <DropDown
          label={"region"}
          className={`region ${formErrors.region ? "invalid" : ""}`}
          error={formErrors.region ? strings("input_required") : null}
          current={form.region}
          data={regions}
          handleClickItem={handleRegionChanged}
        />
      </div>
      {user.role === userRole.MENTOR && (
        <>
          <div className={"separator"} />
          <MentorProfile strings={strings} profile={mentorForm} errors={mentorFormErrors} handleValueChanged={onMentorValueChanged} />
        </>
      )}
      <div className={"separator"} />
      <Switch label={strings("display_info")} checked={form.displayContact} handleCheckChange={handleCheckChange} />
      <div className={"action"}>
        <Button className={"confirm"} label={strings("save")} color={ButtonColors.green} enabled={enableButton} action={validateForm} />
      </div>
      <div className={"separator"} />
    </div>
  );
};

User.propTypes = {
  strings: PropTypes.func,
  user: PropTypes.object,
  regions: PropTypes.array,
  updateProfile: PropTypes.func,
};

User.defaultProps = {
  strings: () => console.log("Strings not implemented"),
  user: {},
  regions: [],
  updateProfile: () => console.log("updateProfile not implemented"),
};

export default User;
