import React, { Component } from "react";
import "./dropDown.scss";

export class DropDown extends Component {
  state = {
    displayList: false,
  };

  boxRef = React.createRef();

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.boxRef && this.boxRef.current && !this.boxRef.current.contains(event.target)) {
      this.setState({
        displayList: false,
      });
      event.stopPropagation();
    }
  };

  handleClickBox = () => {
    const { displayList } = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  handleClickItem = (item) => () => {
    const { label, handleClickItem } = this.props;

    handleClickItem({ key: label, value: item });

    const { displayList } = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  render() {
    const { className, label, data, strings, disabled, error } = this.props;
    let { current } = this.props;
    const { displayList } = this.state;

    return (
      <div className={`drop-down ${className}`}>
        {label && <p className={"label"}>{strings(label)}</p>}
        <div className={"selector-container"}>
          <div className={`box ${disabled ? "disabled" : ""}`} onClick={() => (!disabled ? this.handleClickBox() : undefined)} ref={this.boxRef}>
            <p className={"current"}>{!current || current === "" ? strings("select") : current}</p>
            <div className={"arrow " + (displayList ? "up" : "")} />
          </div>
          {error && <span className={"error"}>{error}</span>}
          <div className={`list ${displayList ? "display" : "hide"}`} style={{ display: !displayList ? "none" : null }}>
            {data.map((d, i) => (
              <div key={i} className={`row`} onClick={this.handleClickItem(i)}>
                <p>{d}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
