import { resettableReducer } from 'reduxsauce';
import { AuthReducers, AuthSagas } from './auth';
import { LoaderReducers } from './loader';
import { ProjectsReducers, ProjectsSagas } from './projects';
import { SnackReducers, SnackSagas } from './snackBar';
import { UserReducers, UserSagas } from './user';
import { ConfirmPopupReducers, ConfirmPopupSagas } from './confirmPopup';
import { CommonReducers, CommonSagas } from './common';
import { NotificationsReducers, NotificationsSagas } from './notifications';

// Specify reset action type rule
const resettable = resettableReducer('RESET_REDUCERS');

const reducers = Object.assign({},
  {
    auth: resettable(AuthReducers),
    user: resettable(UserReducers),
    loader: resettable(LoaderReducers),
    snackBar: SnackReducers,
    projects: resettable(ProjectsReducers),
    confirmPopup: resettable(ConfirmPopupReducers),
    common: resettable(CommonReducers),
    notifications: resettable(NotificationsReducers),
  });

export const rootSaga = [
  ...UserSagas,
  ...SnackSagas,
  ...ProjectsSagas,
  ...AuthSagas,
  ...ConfirmPopupSagas,
  ...CommonSagas,
  ...NotificationsSagas,
];

export default reducers;
