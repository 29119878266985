import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  user: {
    id: null,
    role: null,
  }
};

// region Reducers
const getUserSucceeded = (state = initialState, action) => {
  const {user} = action;
  return {...state, user};
};

const updateUserSucceeded = (state = initialState, action) => {
  const {user} = action;
  return {...state, user};
};

const changePasswordSucceeded = (state = initialState, action) => {
  const {user} = action;
  return {...state, user};
};
// endregion

export default createReducer(initialState, {
  [types.GET_USER_SUCCEEDED]: getUserSucceeded,
  [types.UPDATE_USER_SUCCEEDED]: updateUserSucceeded,
  [types.CHANGE_PASSWORD_SUCCEEDED]: changePasswordSucceeded,
});
