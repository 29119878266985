import { ProjectItem } from './ProjectItem';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { userRole } from '../../../../../utils';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectItem);

export const pieStyle = {
  width: 60,
  height: 60,
  colors: d => d.color,
  innerRadius: 0.6,
  enableRadialLabels: false,
  enableSlicesLabels: false,
  animate: true,
  isInteractive: false,
};
