import { createAction } from '../../utils';

// Types
export const types = {
  LOGOUT_REQUESTED: 'LOGOUT_REQUESTED',
  REFRESH_TOKENS_SUCCEED: 'REFRESH_TOKENS_SUCCEED',
};

// Actions
export default {
  logoutRequested: () => createAction(types.LOGOUT_REQUESTED),
  refreshTokensSucceed: (id, accessToken, refreshToken) => createAction(types.REFRESH_TOKENS_SUCCEED, {
    id,
    accessToken,
    refreshToken,
  }),
};
