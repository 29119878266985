import React, { useEffect, useState } from 'react'
import { images, icons, documents } from '../../../../../assets';
import { splitString } from '../../../../../utils/splitString'
import RemoveIcon from '@mui/icons-material/Remove';
import { pink } from '@mui/material/colors';
import "./addSuccesItem.scss"

const AddSuccesItem = ({ success,handleSelectionChange,callBack}) => {
  const {id,name,other_name,validated,validationDate,ressources,illustration}=success
  const [selected, setSelected] = useState(false)

  const handleSelected = (success) => {
    setSelected(!selected)
    handleSelectionChange(success)
  }
  useEffect(() => {
    setSelected(false)
  },[callBack])

  return (
    <div
      className={`addsuccess-item ${selected ? 'bordered' : ''}`}
      onClick={() => handleSelected(success)}
    >
      <div className={'header-content'}>
        {selected ? <i className={'material-icons'}> {icons.CHECK_CIRCLE} </i> : ''}
        <img src={illustration || images.success2} alt={name || 'logo'} />
      </div>
      <div className={'success-detail'}>
        <h4>{splitString(name, 100)}</h4>
        <div className={'actions'}>
          <div className={'medias'}>
            {ressources?.documents.length > 0 && <i className={'material-icons'}> {icons.FILE} </i>}
            {ressources?.videos.length > 0 && <i className={'material-icons'}> {icons.PLAY} </i>}
          </div>
          <div className={'btn'}>
            {selected ? (
              <RemoveIcon color="success" />
            ) : (
              <i className={'material-icons fill-1'}> {icons.ADD} </i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSuccesItem