import React, { Fragment, PureComponent } from 'react';
import { icons, images } from '../../../../assets';
import { Button, ButtonColors, ScreenHeader } from '../../../components';
import DetailSuccessItem from './detailSuccessItem';
import './detail-success-list.scss';
import moment from 'moment';
import { Routes, projectStatus, usePageViews, userRole } from '../../../../utils';
import avatars from '../../../../utils/avatars';
import UnachevedSuccessItem from './detailSuccessItem/successItems/UnachevedSuccessItem';
import AchevedSuccessItem from './detailSuccessItem/successItems/AchevedSuccessItem';
import item from '../../../components/navigation/item';
import PaginatedItems from '../../project/detail/successItem/PaginatedItems';
import AddSuccess from '../addSuccess/AddSuccess';
import ConfettiSuccess from '../../../components/confetti/ConfettiSuccess';
import { getDiffSuccesses, parseSuccessFromExcel } from '../../../../utils/success';

export class DetailSuccessList extends PureComponent {
  state = {
    successes: this.props.project ? this.props.project.successes || [] : [],
    wonSuccesses: 0,
    successesSelected: null,
    show: false,
    unAchevedSuccessItem: this.props.project
      ? parseSuccessFromExcel(
        this.props.project.successes.filter((item) => item.tobeRealized),
        this.props.project.type,
      ) || []
      : [],
    isConfetti: false,
    isAdded: false,
    isDeleted: false,
    numberOfSuccess: 0,
  };
  onShowConfetti = () => {
    let time = 0;
    const interval = setInterval(() => {
      time++;
      this.setState({ isConfetti: true });
      if (time >= 3) {
        this.setState({ isConfetti: false });
        clearInterval(interval);
      }
    }, 1000);
  };
  getWonSuccesses(successes) {
    if (successes && successes.length > 0) {
      let count = 0;
      successes.map((success) => {
        if (success.validated) {
          count += 1;
        }
        return null;
      });

      return Math.floor((100 / successes.length) * count);
    } else {
      return 0;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.project && prevProps.project !== this.props.project) {
      // if (prevProps.history.oldLocation !== undefined) {
      //   if (prevProps.history.oldLocation.pathname.split('/')[1] === 'success') {
      //     setTimeout(() => {
      //       window.location.reload();
      //     }, 1800);
      //   }
      // }
      this.setState({ successes: this.props.project.successes });
      this.setState({
        wonSuccesses: this.getWonSuccesses(
          this.props.project.successes.filter((item) => item.validated || item.tobeRealized),
        ),
      });
      this.setState({
        unAchevedSuccessItem: parseSuccessFromExcel(
          this.props.project.successes.filter((item) => item.tobeRealized),
          this.props.project.type,
        ),
      });
      this.onShowConfetti();
    }
  }
  componentDidMount() {
    const { getProjectDetails } = this.props;
    const projectId = this.props.match.params.projectId;
    getProjectDetails(projectId);
    this.setState({
      unAchevedSuccessItem: this.props.project
        ? parseSuccessFromExcel(
          this.props.project.successes.filter((item) => item.tobeRealized),
          this.props.project.type,
        ) || []
        : [],
    });
    this.onShowConfetti();
  }

  onClickValidate = () => {
    let { updateSuccesses, project } = this.props;
    let { id } = project;
    let filteredSuccesses = this.state.successes.slice().filter((success) => success.validated);
    const formattedSuccesses = filteredSuccesses.map((success) => ({
      ...success,
      validationDate: moment(success.validationDate).toISOString(),
    }));
    updateSuccesses(id, formattedSuccesses);
  };

  handleCheck = (successId) => {
    let newSuccesses = this.state.successes.slice();

    newSuccesses.map((success) => {
      if (success.id === successId) {
        success.validated = !success.validated;
        success.validationDate = success.validated ? moment() : null;
      }

      return null;
    });

    this.setState({ successes: newSuccesses });
  };
  handleSuccesDetail = (id) => {
    const { navigateTo } = this.props;
    navigateTo(Routes.SUCCESS_DETAIL.replace(':succesId', id));
  };
  handleDateChanged = (successId, newDate) => {
    let newSuccesses = this.state.successes.slice();

    newSuccesses.map((success) => {
      if (success.id === successId) {
        success.validationDate = newDate;
      }

      return null;
    });

    this.setState({ successes: newSuccesses });
  };

  handleShowForm = () => {
    this.setState({ show: true, isAdded: false, isDeleted: false });
  };
  handleHideForm = () => {
    this.setState({ show: false });
  };
  addedSuccess = (number, confirm) => {
    this.setState({ numberOfSuccess: number, isAdded: confirm, isDeleted: false });
  };
  removeSuccess = (id) => {
    const { project, deleteSuccess, navigateTo } = this.props;
    const projectId = project.id;
    const successId = id;
    this.setState({ numberOfSuccess: 0, isAdded: false, isDeleted: true });
    deleteSuccess(projectId, successId);
    navigateTo(Routes.PROJECT_SUCCESS.replace(':projectId', project.id));
  };
  handleOpenConfirm = (id) => {
    this.props.showPopup(this.props.strings('confirmation_question_success'), () =>
      this.removeSuccess(id),
    );
  };

  render() {
    const { strings, pop, project, role, addSuccesToProject, deleteSuccess, isAdded } = this.props;
    const { type, status } = project;
    const { successes, wonSuccesses, unAchevedSuccessItem, isConfetti, numberOfSuccess } =
      this.state;

    return (
      <Fragment>
        {wonSuccesses && wonSuccesses === 100 && (
          <ConfettiSuccess show={isConfetti} numberOfPieces={500} />
        )}

        <div id={'detailSuccess'}>
          <img className={'background'} src={images.epaBackground} alt={'background'} />
          <ScreenHeader screenName={'dashboard'} arrowAction={pop} className={'navigation'} />
          <div className={'container fade'}>
            <div className="row">
              <div className="left">
                <h1> {strings('success_list')} </h1>
                <h2> {'Cliquez sur les cartes pour voir le détail et valider les succès'} </h2>
                <p className="purcent"> {`${wonSuccesses} %`} </p>
                <div className="progress">
                  <div className="bar">
                    <div className="inner" style={{ width: `${wonSuccesses}%` }} />{' '}
                  </div>
                </div>
              </div>
              <p className="added_success">
                {isAdded
                  ? numberOfSuccess > 1
                    ? strings('added_successes')
                    : strings('added_success')
                  : ''}
              </p>
            </div>
            <div className="successes">
              <div className="unacheved_success">
                <h2> {strings('unacheved_success')} </h2>
                <p className="terminated_success">
                  {unAchevedSuccessItem.length === 0 &&
                    wonSuccesses === 100 &&
                    strings('terminated_successes')}
                </p>
                <div className="unacheved_container">
                  {unAchevedSuccessItem &&
                    unAchevedSuccessItem.length > 0 &&
                    parseSuccessFromExcel(unAchevedSuccessItem, type).map((success, index) => {
                      return (
                        <UnachevedSuccessItem
                          isListSucces={true}
                          removeSuccess={this.handleOpenConfirm}
                          key={index}
                          success={success}
                          succesDetail={this.handleSuccesDetail}
                          role={role}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="acheved_success">
                <h2>
                  {successes.filter((item) => item.validated).length > 0 &&
                    strings('acheved_success')}{' '}
                </h2>
                <div className="acheved_container">
                  <PaginatedItems
                    items={parseSuccessFromExcel(
                      successes.filter((item) => item.validated),
                      type,
                    )}
                    itemsPerPage={14}
                    pageRange={5}
                    isDashboard={false}
                    succesDetail={this.handleSuccesDetail}
                  />
                </div>
                <div className="acheved_success_footer"></div>
              </div>
            </div>
            {status && status === projectStatus.FINISHED ? (
              <>
                {(role === userRole.FACILITATOR || role === userRole.ADMIN) ? (
                  <div className={'footer'}>
                    <Button
                      enabled={true}
                      color={ButtonColors.green500}
                      label={`Ajouter un succès`}
                      className={'action-button'}
                      action={this.handleShowForm}
                      icon={icons.ADD}
                      reverse={true}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div className={'footer'}>
                <Button
                  enabled={true}
                  color={ButtonColors.green500}
                  label={`Ajouter un succès`}
                  className={'action-button'}
                  action={this.handleShowForm}
                  icon={icons.ADD}
                  reverse={true}
                />
              </div>
            )}
          </div>
          <AddSuccess
            show={this.state.show}
            hideForm={this.handleHideForm}
            successList={parseSuccessFromExcel(
              successes.filter((item) => item.toBeAdded),
              type,
            )}
            addedSuccess={this.addedSuccess}
            type={type}
            addSuccesToProject={addSuccesToProject}
            project={project}
          />
        </div>
      </Fragment>
    );
  }
}
