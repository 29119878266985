import React, { PureComponent } from 'react';
import { icons } from '../../../../assets';
import { Button, ButtonColors, DropDown, IconInput } from '../../../components';
import { accountType, budgetOperation } from '../../../../utils';
import './add-budget.scss';
import 'react-day-picker/lib/style.css';
import '../../../../../node_modules/moment/locale/fr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export class AddBudget extends PureComponent {
  state = {
    id: null,
    operation: null,
    account: null,
    operationType: null,
    description: '',
    amount: '',
    operationDate: new Date(),
    executionDate: null,
    instantAction: false,
  };

  componentDidMount() {
    const { entry } = this.props;
    if (entry) {
      this.setBudgetToModify(entry);
    }

    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setBudgetToModify = (entry) => {
    this.setState({
      id: entry.id,
      operationType: this.props.operationTypes[entry.operation].idList.indexOf(
        entry.operationType.id,
      ),
      operation: Object.values(budgetOperation).indexOf(entry.operation),
      account: Object.values(accountType).indexOf(entry.account),
      description: entry.description,
      amount: `${entry.amount}`,
      operationDate: entry.operationDate,
      executionDate: entry.executionDate,
      instantAction: entry.operationDate === entry.executionDate,
    });
  };

  handleValueChanged = (tag, value) => {
    const { operation } = this.state;
    if (tag === 'operation' && operation !== value) {
      this.setState({ operationType: null });
    }
    this.setState({ [tag]: value });
  };

  handleSwitch = () => {
    const { instantAction } = this.state;
    this.setState({ instantAction: !instantAction });
  };

  saveBudget = () => {
    const projectId = this.props.project.id;
    let newBudget = JSON.parse(JSON.stringify(this.state));

    newBudget.operationType =
      this.props.operationTypes[Object.keys(budgetOperation)[newBudget.operation]].idList[
        newBudget.operationType
      ];
    newBudget.operation = Object.keys(budgetOperation)[newBudget.operation];
    newBudget.account = accountType.BANK;
    //newBudget.account = Object.keys(accountType)[newBudget.account];
    newBudget.amount = parseFloat(newBudget.amount);

    newBudget.operationDate = moment(newBudget.operationDate).format();

    if (newBudget.executionDate !== null) {
      newBudget.executionDate = moment(newBudget.executionDate).format();
    }

    if (newBudget.instantAction) {
      newBudget.executionDate = newBudget.operationDate;
    }

    const { id } = this.state;
    if (id) {
      this.props.updateBudget(projectId, id, newBudget);
    } else {
      this.props.addBudget(projectId, newBudget);
    }

    this.props.handleCloseBudget();
  };

  isSaveEnabled = () => {
    const { operation, operationType, description, amount, operationDate } = this.state;
    return (
      operation !== null &&
      operationType !== null &&
      operationDate !== null &&
      description.length > 0 &&
      amount.length > 0
    );
  };

  render() {
    const { strings, project, operationTypes, handleCloseBudget } = this.props;
    const { bank, fund } = project.budget.total;
    const {
      operation,
      operationType,
      description,
      amount,
      operationDate,
      executionDate,
      instantAction,
    } = this.state;

    let operationList = Object.values(budgetOperation).map((e) => {
      return [strings(e)];
    });

    let operationTypesList =
      operation !== null ? operationTypes[Object.keys(budgetOperation)[operation]].labelList : [];

    const validOperationDate = operationDate ? Date.parse(operationDate) : null;
    const validExecutionDate = executionDate ? Date.parse(executionDate) : null;

    return (
      <div id={'addBudget'}>
        <div className={'background'}></div>
        <div className={'addBudget fade'}>
          <div>
            <div className={'section header'}>
              <h2>{strings('add_budget')}</h2>
              <i className="material-icons" onClick={handleCloseBudget}>
                {icons.CLOSE}
              </i>
            </div>
            <div className="section">
              <DropDown
                label={'operation'}
                current={operationList[operation]}
                data={operationList}
                className={'operation'}
                handleClickItem={(item) => this.handleValueChanged('operation', item.value)}
              />
             <div className={'divider'}></div>
              <DropDown
                label={'budget_type'}
                current={operationTypesList[operationType]}
                data={operationTypesList}
                className={'type'}
                disabled={operation === null}
                handleClickItem={(item) => this.handleValueChanged('operationType', item.value)}
              />
            </div>
            <div className="section">
              <IconInput
                label={strings('description')}
                defaultValue={description}
                type={'text'}
                className={'description'}
                tag={'description'}
                handleValueChanged={this.handleValueChanged}
              />
              <IconInput
                label={strings('currency_amount')}
                defaultValue={amount}
                type={'number'}
                className={'currencyAmount'}
                tag={'amount'}
                handleValueChanged={this.handleValueChanged}
              />
            </div>
            <div className="section">
              <div className={'switch-item'}>
                <input
                  type="checkbox"
                  name="set-name"
                  id="switch"
                  className="switch-input"
                  onChange={() => this.handleSwitch()}
                  checked={instantAction}
                />
                <label htmlFor="switch" className="switch-label">
                  {strings('action')}
                </label>
              </div>
              <div className={'dates'}>
                <div className={'date operationDate'}>
                  <span>{strings('budget_operation_date')}</span>
                  <div className={'input-wrapper'}>
                    <DatePicker
                      selected={validOperationDate}
                      onChange={(date) => this.handleValueChanged('operationDate', date)}
                      dateFormat={'dd/MM/yyyy'}
                    />
                  </div>
                </div>
                <div className={'date executionDate'}>
                  <span>{strings('budget_execution_date')}</span>
                  <div className={`input-wrapper ${instantAction ? 'disabled' : ''}`}>
                    <DatePicker
                      selected={instantAction ? validOperationDate : validExecutionDate}
                      onChange={(date) => this.handleValueChanged('executionDate', date)}
                      dateFormat={'dd/MM/yyyy'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="section last">
              <Button
                className={'cancel'}
                label={strings('cancel')}
                color={ButtonColors.gray}
                action={handleCloseBudget}
              />
              <Button
                className={'save'}
                label={strings('save')}
                color={ButtonColors.green}
                action={this.saveBudget}
                enabled={this.isSaveEnabled()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
