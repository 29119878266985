import moment from 'moment';

export default {
  /* SnackBar Message */
  excel_succes_retrieval_failed:
    'La récupération des succès Excel a échoué, veuillez réessayer plus tard',
  projects_retrieval_failed: 'La récupération des projets a échoué, veuillez réessayer plus tard',
  project_retrieval_failed: 'La récupération du projet a échoué, veuillez réessayer plus tard',
  project_retrieval_failed_forbidden: "Vous n'êtes pas membre de ce projet",
  success_update_failed: 'La modification des succès a échoué, veuillez réessayer plus tard',
  success_update_succes: 'Votre succès est validé.',
  project_update_failed: 'La modification du projet a échoué, veuillez réessayer plus tard',

  /* Navigation */
  my_projects: 'Mes projets',
  my_sessions: 'Mes séances',
  pedagogic_content: 'Contenu pédagogique',
  project_name: 'Nom du projet',
  dashboard: 'Tableau de bord',
  projectList: 'Liste des projets',
  tool_box: 'Playlist Vidéos',
  supervisor_guides: 'Guides des encadrants',
  supervisor_guide: 'Guide encadrant',
  sucess_title: 'Entreprendre Pour Apprendre',

  /* list user role */
  mentor: 'Mentor',
  supervisor: 'Encadrant',
  student: 'Étudiant',
  facilitator: 'Facilitateur',
  admin: 'Administrateur',

  /* list user role plural*/
  mentors: 'Mentors',
  supervisors: 'Encadrants',
  students: 'Étudiants',

  facilitators: 'Facilitateurs',
  admins: 'Administrateurs',

  /* school */
  school: 'Établissements',

  /* project */
  project: 'Projet',
  projects: 'Projets',
  int: 'Intermediaire',
  long: 'Long',
  namelessProject: 'Projet sans nom',
  no_projects: 'Aucun projet pour le moment',
  successes: 'Succès',
  sucess_list: 'Liste des succès',
  sucess_detail: 'Détail du succès',
  manage: 'Gérer',
  planning: 'Planning',
  tracking: 'Suivi',
  ressources: 'Ressources',
  soon: 'Bientôt disponible',
  no_event: "Pas d'événement",
  all_events: 'Afficher tous les évènements',
  select_date: 'Selectionnez une date',
  add_meeting_failed: `Impossible d'ajouter l'évèvement`,
  added_meeting_successfully: `Evènement ajouté avec succès`,
  delete_meeting_successfully: `Evènement supprimé avec succès`,
  delete_meeting_failed: `Impossible de supprimer l'évènement`,
  access: 'Accès',
  shared_links: 'Liens partagés',
  project_details: 'Détails du projet',
  project_data_unfilled: 'Données du projet non renseignées',
  project_data_unfilled_action: 'cliquez ici pour les compléter',
  project_data_collection:
    'Bonjour, avant de commencer, merci de prendre quelques minutes pour renseigner les données de votre projet',
  project_data_remind: 'Me le rappeler ultérieurement',
  project_data_fill: 'Renseigner les informations',
  project_working_group: 'Le groupe de travail',
  added_success_successfully: `Module ajouté avec succès`,
  added_success_successfullys: `Modules ajoutés avec succès`,
  added_success_failed: `Imposible d'ajouter un succès`,
  delete_success_successfully: `Le succès est supprimé`,
  delete_success_validate_successfully: `La validation du succès a bien été annulée.`,
  delete_success_failed: `Impossible de supprimer le succès`,
  confirmation_question_success: 'Etes-vous certain de vouloir supprimer ce succès ?',
  confirmation_question_success_invalidation:
    'Êtes-vous certain de vouloir annuler la validation de ce succès ?',
  project_status: "Statut",
  project_status_all: "Tous",
  project_status_validated: "En cours",
  project_status_archived: "Archivé",
  project_status_finished: "Terminé",
  project_status_comming: "À venir",
  project_status_draft: "Brouillon",
  project_status_running: "En cours",

  /* Project data */
  category: 'Catégorie',
  level: 'Niveau de classe',
  sector: 'Filière',
  composition: 'Nombre de jeunes',
  dedicated_time: 'Temps dédié',
  annual_volume: 'Volume horaire temps dédié',
  total_annual_volume: "Nombre d'heure annuelles",
  total_volume: "Nombre d'heure",
  class_number: 'Classe #',
  girls_count: 'Nombre de filles',
  boys_count: 'Nombre de garçons',
  dedicated_time_annual: 'Temps dediés (Annuel)',
  volume: 'Volume horaire',
  annual: 'annuel',
  add_class: 'Ajouter une classe',
  delete_class: 'Supprimer la classe',
  add_time: 'Ajouter un temps dédié',
  delete_time: 'Supprimer le temps dédié',

  /* project events */
  current: 'Date courante',
  current_month: 'Mois courant',
  coming: 'A venir',
  all_dates: 'Toutes les dates',
  cancel: 'Annuler',
  delete: 'Supprimer',
  description: 'Description',
  add_entry: 'Ajouter entrée',
  download_list: 'Télécharger le suivi',
  export_failed: "L'export a échoué, veuillez réessayer plus tard",
  confirmation_question: 'Etes-vous certain de vouloir supprimer cet évènement ?',
  yes: 'oui',
  no: 'non',
  validate: 'Valider',
  update_meeting_successfully: 'Evènement mis à jour avec succès',
  update_meeting_failed: `Impossible de mettre à jour l'évènement`,
  add_event: 'Ajouter un évènement',
  add_budget_successfully: 'Entrée ajoutée avec succès au budget',
  add_budget_failed: `Impossible d'ajouter une entrée au budget`,
  delete_budget_successfully: `Entrée supprimée avec succès`,
  delete_budget_failed: `Impossible de supprimer l'entrée`,
  update_budget_successfully: `Entrée mis à jour avec succès`,
  update_budget_failed: `Impossible de mettre à jour l'entrée`,
  get_operation_types_successfully: `Types d'opération obtenus avec succès`,
  get_operation_types_failed: `Impossible d'obtenir les types d'opération`,
  update_data_failed: `Une erreur est survenue lors de la mise à jour du groupe de travail`,
  get_lists_failed: `Une erreur est survenue lors de la récupération des informations`,

  /* Project links */
  choose_icon: 'Choisir une icône',
  object: 'objet',
  edit: 'Editer',
  add_link_successfully: 'Lien ajouté avec succès',
  add_link_failed: `Impossible d'ajouter le lien`,
  update_link_successfully: 'Lien mis à jour avec succès',
  update_link_failed: 'Impossible de mettre à jour le lien',
  delete_link_successfully: 'Lien supprimé avec succès',
  delete_link_failed: 'Impossible de supprimer le lien',
  no_link_at_this_time: 'Aucun lien pour le moment',
  link_title: 'Intitulé du lien...',
  link_url: 'Url du lien...',
  confirmation_question_links: 'Etes-vous sûr de vouloir supprimer ce lien ?',
  confirmation_question_budget: 'Etes-vous sur de vouloir supprimer cette écriture ?',

  /* success */
  success: 'Succès',
  status: 'Statut',
  title_success: 'Titre du succès',
  success_list: 'Liste des succès',
  earned_success: 'Obtenu',
  no_successes: 'Aucun succès pour le moment',
  all_successes: 'Afficher tous les succès',
  unacheved_success: 'Succès à accomplir',
  acheved_success: 'Succès réalisés',
  terminated_successes: 'Bravo! Tu as terminé les étapes de ta mini entreprise!',
  added_successes: 'Les nouveaux succès ont bien été ajoutés.',
  added_success: 'Le nouveau succès a bien été ajouté.',

  /* Détail d'un success */
  repartition_success: 'Répartition des responsabilités',
  /* budget */
  BANK: 'Banque',
  FUND: 'Caisse',
  DEPOSIT: 'Recette',
  WITHDRAW: 'Dépense',
  add_budget: 'Afficher le suivi',
  currency: ' €',
  budget_title: 'Suivi Financier',
  budget_download: 'Télécharger le suivi',
  budget_date: 'Date',
  budget_object: 'Objet',
  budget_type: 'Type',
  budget_account: 'Compte',
  budget_execution_date: "Date d'exécution",
  budget_operation_date: "Date de l'opération",
  budget_amount: 'Montant',
  budget_balance: 'Solde',
  operation: 'Opération',
  currency_amount: 'Montant en Euros',
  action: 'Action immédiate',
  select: 'Sélectionner',
  date_format: 'JJ/MM/AAAA',
  // Matière
  topic: 'Matière',
  topics: 'Matières',
  /* button */
  save: 'Sauvegarder',
  add: 'Ajouter',

  /* legal mentions */
  legal_mentions: 'Mentions Légales',
  private_policy: 'Politique de confidentialité',
  cgu: 'CGU',
  intellectual_property: 'Propriété intellectuelle',
  legal_mentions_contact:
    'La plateforme pédagogique EPA est développée par :\n\n' +
    'Entreprendre Pour Apprendre France\n' +
    'La Filature – 32 rue du Faubourg Poissonnière\n' +
    '75010 Paris\n' +
    '\n' +
    'Numéro SIREN : 449 242 049\n\n' +
    'Contact : contact@epa-france.fr\n\n' +
    'EPA France est une association loi 1901 reconnue d’intérêt général et titulaire d’un agrément du Ministère de l’Education et de la Jeunesse.',
  private_policy_complete: 'Politique de confidentialité et de traitement des données personnelles',
  private_policy_text:
    'Les informations recueillies par Entreprendre pour Apprendre France (EPA France) à partir de cette plateforme pédagogique font l’objet d’un traitement informatique destiné au réseau EPA. Il s’agit des coordonnées des encadrants, des mentors et des établissements dans lesquels se mettent en place des programmes pédagogiques EPA.\n\n' +
    'Cette collecte de données a pour finalité de procéder aux inscriptions des établissements dans les parcours Mini-Entreprise M® et Mini-Entreprise L®, mais également de pouvoir réaliser des statistiques sur notre action.\n\n' +
    'Ces données nominatives des encadrants et des mentors seront conservées par EPA France au maximum 2 ans après le dernier contact.\n\n' +
    'Afin de protéger la confidentialité de vos données personnelles, EPA France s’engage à ne pas transmettre ni partager vos données avec d’autres entités, entreprises ou organismes extérieurs au réseau EPA.\n\n' +
    'Conformément à la loi Informatique et libertés du 6 janvier 1978 modifiée et aux dispositions du Règlement Général sur la Protection des Données Personnelles du 27 avril 2016, vous disposez d’un droit d’accès, de rectification, de portabilité et d’effacement de vos données personnelles.\n\n' +
    'Vous pouvez vous opposer au traitement de vos données et disposez du droit de retirer votre consentement à tout moment.\n\n' +
    'Pour exercer vos droits, vous pouvez vous adresser à : ',
  private_policy_contact: 'contact@epa-france.fr',
  cgu_complete: "Conditions générales d'utilisation de la plateforme pedagogique EPA",
  cgu_presentation: 'Présentation de la plateforme pedagogique EPA',
  cgu_presentation_text:
    'La plateforme pédagogique EPA a pour objectif de permettre aux encadrants, jeunes et mentors participant à un programme pédagogique EPA de suivre leur Mini-Entreprise® tout au long de l’année en mode gestion de projet.\n\n' +
    'Elle se présente sous la forme d’un espace dédié à chaque projet de Mini-Entreprise® et propose plusieurs types d’outils pédagogiques (documents, vidéos …) permettant aux jeunes et à leurs encadrant et mentor de mener à bien leur projet.\n\n' +
    'L’accès à l’espace dédié au projet se fait via un login et un mot de passe.\n\n' +
    'Toutes les ressources présentes sur la plateforme pédagogique sont consultables sur ordinateur, tablette et smartphone, en multi-accès.\n\n',
  cgu_inscription: 'Inscription',
  cgu_inscription_text:
    'Toute personne a accès à un contenu freemium de la plateforme pédagogique EPA en laissant ses nom, prénom et contact mail.\n\n' +
    'Pour accéder au contenu Premium, et donc à l’ensemble des ressources pédagogiques de la plateforme, il est nécessaire de faire une demande d’inscription en indiquant les coordonnées de l’établissement qui va accueillir le programme EPA.\n\n' +
    'Un facilitateur de l’association régionale EPA compétente prendra contact avec vous pour finaliser l’inscription et vous transmettre la convention qui sera signée entre l’établissement et EPA pour la mise en place du programme EPA.\n\n',
  cgu_uses: 'Utilisation',
  cgu_uses_text:
    'L’accès premium à la plateforme se fait grâce à un login et un mot de passe qui sont communiqués une fois que la convention entre l’établissement et EPA est signée.\n\n' +
    'L’utilisateur a alors accès à toutes les ressources pédagogiques présentes sur la plateforme et à son espace projet qui lui est dédié. Il peut alors consulter les documents et vidéos en ligne et les télécharger.\n\n' +
    'Nous attirons votre attention sur le fait que le téléchargement des documents enlève le caractère interactif des documents.\n\n' +
    'L’utilisateur s’engage à :\n\n' +
    '-\tne pas communiquer ses identifiants à des personnes extérieures au projet EPA,\n' +
    '-\tutiliser les ressources pédagogiques dans le strict cadre du projet EPA,\n' +
    '-\tne pas utiliser les ressources proposées à des fins commerciales.\n\n' +
    'EPA France se réserve le droit de modifier ou de corriger, à tout moment et ce sans préavis, le contenu de sa plateforme et des documents qui sont proposés, et ne peut être tenue pour responsable des conséquences éventuelles de ces modifications. \n\n',
  cgu_technical: 'Problème technique',
  cgu_technical_text:
    'En cas de problème technique dans l’utilisation de la plateforme pédagogique EPA, ou en cas de perte de vos identifiants, merci de contacter EPA France : <b>plateforme@epa-france.fr</b>',
  intellectual_property_complete: 'Propiété intellectuelle et copyright',
  intellectual_property_licence:
    'Les outils et supports sont sous licence Créative Commons <b>BY NC ND</b>',
  intellectual_property_mentions:
    'Les mentions liées à Entreprendre pour Apprendre font intégralement partie des supports',
  intellectual_property_commercial:
    'Toute utilisation à des fins commerciales est passible de poursuites judiciaires',
  intellectual_property_shared:
    'Les supports ne peuvent être intégrés partiellement ou en totalité dans un autre support. Ils ne peuvent être partagés qu’en intégralité (fond et forme)',
  intellectual_property_text:
    'La structure générale, les textes, images, logo, vidéos, sons, savoir-faire, animations, documents téléchargeables, et plus généralement toutes les informations et contenus figurant sur la plateforme pédagogique EPA, sont la propriété d’EPA France ou font l’objet d’un droit d’utilisation ou d’exploitation.\n\n' +
    'Toute représentation, modification, reproduction, dénaturation, totale ou partielle, de tout ou partie de la plateforme ou de son contenu, par quelque procédé que ce soit, et sur quelque support que ce soit constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la Propriété Intellectuelle.',

  /* FOOTER */
  copyright: `© ${moment().year()} Entreprendre pour Apprendre`,
  access_to: 'Accédez au site',
  epa_com: 'epa.com',

  mister: 'Monsieur',
  misses: 'Madame',

  /* Profile */
  profile: 'Mon profil',
  user_profile: 'Vos informations',
  civility: 'Civilité',
  firstname: 'Prénom',
  lastname: 'Nom',
  phone_number: 'Numéro de téléphone',
  email: 'Email',
  region: 'Région',
  perimeter: "Périmètre d'action *",
  company: 'La société *',
  company_ceo: 'Dirigeant',
  company_name: 'Raison sociale',
  address: 'Adresse',
  postal_code: 'Code postal',
  city: 'Ville',
  country: 'Pays',
  input_required: 'Ce champs est obligatoire',
  input_wrong: 'Ce champs est invalide',
  wrong_email: "Format d'email invalide",
  wrong_phone: 'Format de numéro invalide',
  display_info: "Permettre l'affichage de mon numéro de téléphone et email",
  update_user_error_generic:
    'Une erreur est survenue lors de la modification de votre profil, veuillez réessayer plus tard',
  change_password: 'Modifier mon mot de passe (3 champs)',
  current_password: 'Votre mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  validation_password: 'Confirmez le nouveau mot de passe',
  error_password_length: "Veuillez renseigner un mot de passe d'au moins 7 charactères",
  error_password_mismatch: 'Les mots de passe ne correspondent pas',
  change_password_success: 'Votre changement de mot de passe a bien été pris en compte',
  change_password_error_wrong_password:
    "Mauvais mot de passe, la modification n'a pas pu être prise en compte",
  change_password_error_generic:
    'Une erreur est survenue lors de la modification de votre mot de passe, veuillez réessayer plus tard',

  /* Notifications */
  notifications: 'Notifications',

  /* Minis */
  date: 'Date',
  online: 'En ligne',
  rooms: 'Salles',
  room_number: 'Salle #',
  hours: 'Heures',
  hide_detail: 'Masquer les détails',
  show_detail: 'Afficher les détails',
  partners: 'Partenaires',
  mini_s: 'Mini S',
  common_tools: 'Outils communs',
  room_tools: 'Outils de la salle',
  other_rooms: 'Les autres salles',
  partners_title: 'Réalisé en partenariat avec',
  desc: 'un petit texte d’explication que l’activité a ete fait avec la collaboration d’une entreprise',
  current_room: '(votre salle)',

  /* Project types */
  project_m9_age: '9-12 ans',
  project_m13_age: '13-25 ans',
};
