import { FORBIDDEN } from 'http-status-codes';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { MinisServices, ProjectsServices } from '../../services';
import { downloadAttachment, projectType, Routes } from '../../utils';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { ProjectsActions } from './index';
import { types } from './actions';
import { history } from '../../core/history';

function* getProjectsRequested() {
  yield put(LoaderActions.loading());

  const userId = yield select((state) => state.auth.id);
  const [[errorMinis, responseMinis], [errorProjects, responseProjects]] = yield all([
    call(MinisServices.getListMinis, userId),
    call(ProjectsServices.getListProjects, userId),
  ]);
    if (!errorMinis && !errorProjects) {
    const projects = [
      ...responseMinis.data.map((mini) => ({ ...mini, projectType: projectType.MINI })),
      ...responseProjects.data.map((proj) => ({ ...proj, projectType: projectType.PROJECT })),
    ];
    yield put(ProjectsActions.getProjectsSucceeded(projects));
  } else {
    yield put(SnackActions.displayError('projects_retrieval_failed'));
  }

  yield put(LoaderActions.loaded());
}
function* updateSuccessesRequested({ projectId, successes }) {
 
  yield put(LoaderActions.loading());

  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(ProjectsServices.updateSuccesses, userId, projectId, successes);
  if (!error) {
    yield put(ProjectsActions.updateSuccessesSucceeded(response.data));
    const succesId = localStorage.getItem('successId');
    const projectPath = succesId
      ? Routes.SUCCESS_DETAIL.replace(':succesId', succesId)
      : Routes.PROJECT_DETAIL.replace(':projectId', projectId);
    if (history.oldLocation && history.oldLocation.pathname === projectPath) {
      yield call(history.goBack);
    } else {
      yield call(history.replace, projectPath);
    }
    yield put(SnackActions.displayInfo('success_update_succes'))
    yield put(LoaderActions.loaded());
  } else {
    yield put(SnackActions.displayError('success_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* getProjectDetailRequested({ projectId }) {
  yield put(LoaderActions.loading());

  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(ProjectsServices.getProjectDetail, userId, projectId);
  if (!error) {
    yield put(ProjectsActions.getProjectDetailSucceeded(response.data));
  } else {
    if (error.response.status === FORBIDDEN) {
      yield put(SnackActions.displayError('project_retrieval_failed_forbidden'));
    } else {
      yield put(SnackActions.displayError('project_retrieval_failed'));
    }
  }

  yield put(LoaderActions.loaded());
}

function* updateProjectRequested({ projectId, projectName,projectDescription,projectAvatar}) {
  
  yield put(LoaderActions.loading());

  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(ProjectsServices.updateProject, userId, projectId, projectName,projectDescription,projectAvatar);
 
  if (!error) {
    yield put(ProjectsActions.updateProjectSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('project_update_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* addMeetingRequested({ projectId, meeting }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.addMeeting, userId, projectId, meeting);

  if (!error) {
    yield put(ProjectsActions.addMeetingSucceeded(response.data));
    yield put(SnackActions.displayInfo('added_meeting_successfully'));
  } else {
    yield put(SnackActions.displayError('add_meeting_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* deleteMeetingRequested({ projectId, meetingId }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.deleteMeeting, userId, projectId, meetingId);

  if (!error) {
    yield put(ProjectsActions.deleteMeetingSucceeded(response.data));
    yield put(SnackActions.displayInfo('delete_meeting_successfully'));
  } else {
    yield put(SnackActions.displayError('delete_meeting_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* updateMeetingRequested({ projectId, meetingId, meeting }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.updateMeeting, userId, projectId, meetingId, meeting);

  if (!error) {
    yield put(ProjectsActions.updateMeetingSucceeded(response.data));
    yield put(SnackActions.displayInfo('update_meeting_successfully'));
  } else {
    yield put(SnackActions.displayError('update_meeting_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* addLinkRequested({ projectId, link }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.addLink, userId, projectId, link);

  if (!error) {
    yield put(ProjectsActions.addLinkSucceeded(response.data));
    yield put(SnackActions.displayInfo('add_link_successfully'));
  } else {
    yield put(ProjectsActions.linkInError(true));
    yield put(SnackActions.displayError('add_link_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* updateLinkRequested({ projectId, linkId, link }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.updateLink, userId, projectId, linkId, link);

  if (!error) {
    yield put(ProjectsActions.updateLinkSucceeded(response.data));
    yield put(SnackActions.displayInfo('update_link_successfully'));
  } else {
    yield put(ProjectsActions.linkInError(true));
    yield put(SnackActions.displayError('update_link_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* deleteLinkRequested({ projectId, linkId }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.deleteLink, userId, projectId, linkId);

  if (!error) {
    yield put(ProjectsActions.deleteLinkSucdeeded(response.data));
    yield put(SnackActions.displayInfo('delete_link_successfully'));
  } else {
    yield put(ProjectsActions.linkInError(true));
    yield put(SnackActions.displayError('delete_link_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* addBudgetRequested({ projectId, budget }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.addBudget, userId, projectId, budget);

  if (!error) {
    yield put(ProjectsActions.addBudgetSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('add_budget_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* deleteBudgetRequested({ projectId, budgetId }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.deleteBudget, userId, projectId, budgetId);

  if (!error) {
    yield put(ProjectsActions.deleteBudgetSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('delete_budget_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* updateBudgetRequested({ projectId, budgetId, budget }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.updateBudget, userId, projectId, budgetId, budget);

  if (!error) {
    yield put(ProjectsActions.updateBudgetSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('update_budget_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* downloadBudgetRequested({ projectId }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);

  const [error, response] = yield call(ProjectsServices.downloadBudget, userId, projectId);

  if (response && response.data && !error) {
    downloadAttachment(response)
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }

  yield put(LoaderActions.loaded());
}
function* getOperationTypesRequested({ inBackground }) {
  if (!inBackground) {
    yield put(LoaderActions.loading());
  }

  const [error, response] = yield call(ProjectsServices.getOperationTypes);

  if (!error) {
    yield put(ProjectsActions.getOperationTypesSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('get_operation_types_failed'));
  }

  if (!inBackground) {
    yield put(LoaderActions.loaded());
  }
}

function* getDataListsRequested({ inBackground }) {
  if (!inBackground) {
    yield put(LoaderActions.loading());
  }

  const [[catErr, categories], [lvlErr, levels], [sectErr, sectors], [timesErr, times]] = yield all([
    call(ProjectsServices.getClassCategories),
    call(ProjectsServices.getClassLevels),
    call(ProjectsServices.getClassSectors),
    call(ProjectsServices.getClassDedicatedTimes),
  ]);

  if (!catErr && !lvlErr && !sectErr && !timesErr) {
    yield put(ProjectsActions.getDataListsSucceeded(categories.data, levels.data, sectors.data, times.data));
  } else {
    if (!inBackground) {
      yield put(SnackActions.displayError('get_lists_failed'));
    }
  }

  if (!inBackground) {
    yield put(LoaderActions.loaded());
  }
}

function* updateDataRequested({ projectId, data }) {
  yield put(LoaderActions.loading());

  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(ProjectsServices.updateData, userId, projectId, data);

  if (!error) {
    yield put(ProjectsActions.updateBudgetSucceeded(response.data));

    const projectPath = Routes.PROJECT_DETAIL.replace(':projectId', projectId);
    if (history.oldLocation && history.oldLocation.pathname === projectPath) {
      yield call(history.goBack);
    } else {
      yield call(history.replace, projectPath);
    }
  } else {
    yield put(SnackActions.displayError('update_data_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* getMiniRoomRequested({ miniId, roomId }) {
  yield put(LoaderActions.loading());

  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(MinisServices.getMiniRoom, userId, miniId, roomId);

  if (!error) {
    yield put(ProjectsActions.getMiniRoomSucceeded(response.data));
  } else {
    if (error.response.status === FORBIDDEN) {
      yield put(SnackActions.displayError('project_retrieval_failed_forbidden'));
    } else {
      yield put(SnackActions.displayError('project_retrieval_failed'));
    }
  }

  yield put(LoaderActions.loaded());
}

function* updateMiniRoomSuccessesRequested({ miniId, roomId, successes }) {
  yield put(LoaderActions.loading());

  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(MinisServices.updateMiniRoomSuccesses, userId, miniId, roomId, successes);

  if (!error) {
    yield put(ProjectsActions.updateMiniRoomSuccessesSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('success_update_failed'));
  }
  yield put(LoaderActions.loaded());
}
function* addSuccessRequested({ projectId, success }) {
  yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(ProjectsServices.addSuccess, userId, projectId, success);
  if (!error) {
  yield put(ProjectsActions.addSuccessSucceeded(response.data));

   yield put(SnackActions.displayInfo(success.length >1 ?'added_success_successfullys':'added_success_successfully'));
  } else {
    yield put(SnackActions.displayError('added_success_failed'));
  }
  yield put(LoaderActions.loaded());
}
function* deleteSuccessRequested({ projectId, successId }) {

   yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(ProjectsServices.deleteSuccess, userId, projectId, successId);
  if (!error) {
    yield put(ProjectsActions.deleteSuccessSucceeded(response.data));
    yield put(SnackActions.displayInfo('delete_success_successfully'));
  } else {
    yield put(SnackActions.displayError('delete_success_failed'));
  }
   yield put(LoaderActions.loaded());
}
function* deleteSuccessvalidated({ projectId, successId }) {

   yield put(LoaderActions.loading());
  const userId = yield select((state) => state.auth.id);
  const [error, response] = yield call(ProjectsServices.deleteSuccess, userId, projectId, successId);
  if (!error) {
    yield put(ProjectsActions.deleteSuccessSucceeded(response.data));
    yield put(SnackActions.displayInfo('delete_success_validate_successfully'));
  } else {
    yield put(SnackActions.displayError('delete_success_failed'));
  }
   yield put(LoaderActions.loaded());
}

function* updateSuccessesCanceled({ projectId, successes }) {

  yield put(LoaderActions.loading());
 const userId = yield select((state) => state.auth.id);
 const [error, response] = yield call(ProjectsServices.updateSuccesses, userId, projectId, successes);
 if (!error) {
   yield put(ProjectsActions.deleteSuccessSucceeded(response.data));
   yield put(SnackActions.displayInfo('delete_success_validate_successfully'));
 } else {
   yield put(SnackActions.displayError('delete_success_failed'));
 }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_PROJECTS_REQUESTED, getProjectsRequested),
  takeLatest(types.PATCH_SUCCESS_REQUESTED, updateSuccessesRequested),
  takeLatest(types.GET_PROJECT_DETAIL_REQUESTED, getProjectDetailRequested),
  takeLatest(types.PATCH_PROJECT_REQUESTED, updateProjectRequested),
  takeLatest(types.ADD_MEETING_REQUESTED, addMeetingRequested),
  takeLatest(types.DELETE_MEETING_REQUESTED, deleteMeetingRequested),
  takeLatest(types.UPDATE_MEETING_REQUESTED, updateMeetingRequested),
  takeLatest(types.ADD_BUDGET_REQUESTED, addBudgetRequested),
  takeLatest(types.DELETE_BUDGET_REQUESTED, deleteBudgetRequested),
  takeLatest(types.UPDATE_BUDGET_REQUESTED, updateBudgetRequested),
  takeLatest(types.DOWNLOAD_BUDGET_REQUESTED, downloadBudgetRequested),
  takeLatest(types.GET_OPERATION_TYPES_REQUESTED, getOperationTypesRequested),
  takeLatest(types.ADD_LINK_REQUESTED, addLinkRequested),
  takeLatest(types.UPDATE_LINK_REQUESTED, updateLinkRequested),
  takeLatest(types.DELETE_LINK_REQUESTED, deleteLinkRequested),
  takeLatest(types.GET_DATA_LISTS_REQUESTED, getDataListsRequested),
  takeLatest(types.UPDATE_DATA_REQUESTED, updateDataRequested),
  takeLatest(types.GET_MINI_ROOM_REQUESTED, getMiniRoomRequested),
  takeLatest(types.PATCH_MINI_ROOM_SUCCESS_REQUESTED, updateMiniRoomSuccessesRequested),
  takeLatest(types.ADD_SUCCESS_REQUESTED, addSuccessRequested),
  takeLatest(types.DELETE_SUCCESS_REQUESTED, deleteSuccessRequested),
  takeLatest(types.DELETE_SUCCESS_VALIDATED, deleteSuccessvalidated),
  takeLatest(types.PATCH_SUCCESS_CANCELED, updateSuccessesCanceled),
];
