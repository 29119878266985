import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, ButtonColors, ScreenHeader } from '../../../components';
import { Success } from './Success';

const SuccessesDetail = ({ strings, show, roomSuccesses, handleSave, handleClose }) => {
  const [successes, setSuccesses] = useState(roomSuccesses.slice());

  useEffect(() => {
    if (roomSuccesses) {
      setSuccesses(roomSuccesses.slice());
    }
  }, [roomSuccesses]);

  const onClickSave = () => handleSave(successes);

  const handleCheck = (index) => () => {
    let newSuccesses = successes.slice();

    const updatedSuccess = newSuccesses[index];
    updatedSuccess.validated = !updatedSuccess.validated;
    updatedSuccess.validationDate = updatedSuccess.validated ? moment() : null;

    setSuccesses(newSuccesses);
  };

  const handleDateChanged = (index) => (newDate) => {
    let newSuccesses = successes.slice();
    newSuccesses[index].validationDate = newDate;
    setSuccesses(newSuccesses);
  };

  return (
    <div className={`successes-detail ${show ? 'show' : ''}`}>
      <ScreenHeader screenName={'success'} arrowAction={handleClose} className={'navigation'} />
      <div className={'wrapper column'}>
        <div className={'panel column'}>
          <div className={'container column'}>
            <div className={'titles row'}>
              <p className={'status'}>{strings('status')}</p>
              <p className={'title'}>{strings('title_success')}</p>
              <p className={'earned'}>{strings('earned_success')}</p>
            </div>
            <div>
              {successes ? (
                successes.map((success, index) => {
                  return <Success key={success.successId} success={success} handleCheck={handleCheck(index)} handleDateChanged={handleDateChanged(index)} />;
                })
              ) : (
                <div className={'no-success'}>{strings('no_successes')}</div>
              )}
            </div>
          </div>
          <div className={'footer'}>
            <Button enabled={successes && successes.length !== 0} color={ButtonColors.green} label={strings('save')} className={'save'} action={onClickSave} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessesDetail;
