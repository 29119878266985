import React from 'react';

export const Item = ({text, route, newTab, location, navigateTo, fromResponsiveMenu, close}) => {

  const handleRedirection = () => {
    if (fromResponsiveMenu) close();
    if (newTab) {
      window.open(route, "_blank");
    } else {
      route !== location && navigateTo(route);
    }
  };

  return (
    <li
      className={`item ${route === location && 'is-current'}`}
      onClick={handleRedirection}>
      {text}
    </li>
  );
}
