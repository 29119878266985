import { createAction } from '../../utils/index';

// Types
export const types = {
  GET_USER_REQUESTED: 'GET_USER_REQUESTED',
  GET_USER_SUCCEEDED: 'GET_USER_SUCCEEDED',
  UPDATE_USER_REQUESTED: 'UPDATE_USER_REQUESTED',
  UPDATE_USER_SUCCEEDED: 'UPDATE_USER_SUCCEEDED',
  CHANGE_PASSWORD_REQUESTED: 'CHANGE_PASSWORD_REQUESTED',
  CHANGE_PASSWORD_SUCCEEDED: 'CHANGE_PASSWORD_SUCCEEDED',
};

// Actions
export default {
  getUserRequested: () => createAction(types.GET_USER_REQUESTED),
  getUserSucceeded: (user) => createAction(types.GET_USER_SUCCEEDED, {user}),
  updateUserRequested: (user) => createAction(types.UPDATE_USER_REQUESTED, {user}),
  updateUserSucceeded: (user) => createAction(types.UPDATE_USER_SUCCEEDED, {user}),
  changePasswordRequested: (body) => createAction(types.CHANGE_PASSWORD_REQUESTED, {body}),
  changePasswordSucceeded: (user) => createAction(types.CHANGE_PASSWORD_SUCCEEDED, {user}),
};
