export const successExtratLargeData = [
  //---------------------------1--------------------------------//
  {
    id: 121,
    name: "Le droit à l'image",
    description:
      "Consultez les ressources pour comprendre l'importance des autorisations de droit à l'image.",
    ressources: {
      videos: [],
      documents: [
        {
          title: "Autorisation de l'utilisation de l'image",
          url: 'AutorisationdroitimageMiniL.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/LeDroitAlImage_csgh6p.png',
  },
  //---------------------------2-------------------------------//
  {
    id: 122,
    name: "La Mini-Entreprise®, qu'est-ce que c'est?",
    description: "Consultez les ressources pour comprendre ce qu'est une Mini-Entreprise®.",
    ressources: {
      videos: [
        {
          title: 'Votre année de mini-entrepreneurs en quelques mots',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d69243e7b98a80ceb1bb56f/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/LaMiniEntrepriseQuEstCeQueCest_xnrqrq.png',
  },
  //---------------------------3--------------------------------//
  {
    id: 123,
    name: 'Le tableau de bord de la Mini-Entreprise®',
    description:
      'Consultez le tutoriel de la plateforme pédagogique pour vous familiariser avec le tableau de bord.',
    ressources: {
      videos: [
        {
          title: 'La plateforme pédagogique',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f1987a3736d8a00114b9d2a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675019/Illustrations%20succ%C3%A8s/mini_xl_illustration/TableaudeBorddeNotreEntreprise_c5sco5.png',
  },
  //---------------------------4--------------------------------//
  {
    id: 124,
    name: "Une entreprise, qu'est-ce que c'est?",
    description: "Consultez les ressources pour comprendre ce qu'est une entreprise.",
    ressources: {
      videos: [
        {
          title: "Qu'est-ce qu'une entreprise ?",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d6919207b98a80ceb1b767c/deploy',
        },
      ],
      documents: [
        {
          title: 'Lexique des termes entrepreneuriaux',
          url: 'Lexiquedestermesentrepreneuriaux.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675499/Illustrations%20succ%C3%A8s/mini_xl_illustration/UneEntrepriseQuEstCeQueCest_gf2pvv.png',
  },
  //---------------------------5--------------------------------//
  {
    id: 125,
    name: 'Le rôle du mentor de la Mini-Entreprise®',
    description:
      'Consultez les ressources pour comprendre le rôle du mentor de votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Un mentor, pour quoi faire ?',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d78b40a27f1280d17c65dd1/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663674996/Illustrations%20succ%C3%A8s/mini_xl_illustration/RoleDuMentorDeLaMinientreprise-MAJ_ywhomn.png',
  },
  //---------------------------6--------------------------------//
  {
    id: 153,
    name: 'Le développement durable et les entreprises',
    description:
      "Retrouvez bientôt les ressources pour comprendre l'importance du développement durable dans votre Mini-Entreprise®.",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663674950/Illustrations%20succ%C3%A8s/mini_xl_illustration/devdurable_xyphqq.png',
  },
  //---------------------------7--------------------------------//
  {
    id: 152,
    name: "Les inégalités dans l'entreprise, comment les empêcher ?",
    description:
      'Retrouvez bientôt les ressources pour mettre en place dans votre Mini-Entreprise® un fonctionnement respectueux de chaque membre.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675066/Illustrations%20succ%C3%A8s/mini_xl_illustration/LesIne%CC%81galite%CC%81s_dwpx01.png',
  },
  //---------------------------8--------------------------------//
  {
    id: 128,
    name: 'Charte des valeurs de notre Mini-Entreprise®',
    description: 'Consultez les ressources pour vous aider à définir votre charte.',
    ressources: {
      videos: [
        {
          title: 'Les valeurs de sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a3b0798f3b50cfb52f5ab/deploy',
        },
      ],
      documents: [
        {
          title: 'Animer un atelier - Définir ses valeurs',
          url: 'AnimerunatelierDéfinirsesvaleurss.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663674767/Illustrations%20succ%C3%A8s/mini_xl_illustration/CharteDesValeursDeNotreMinientreprise_lrypkp.png',
  },
  //---------------------------9--------------------------------//
  {
    id: 154,
    name: 'Les étapes et activités de la Mini-Entreprise®',
    description:
      'Retrouvez bientôt les ressources pour découvrir les 4 étapes et les différentes activités de la la Mini-Entreprise®',
    ressources: {
      videos: [
        {
          title: 'le rétroplanning de la Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d6e22384cb79f0cf98f37e0/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675054/Illustrations%20succ%C3%A8s/mini_xl_illustration/LesEtapesEtActivite%CC%81_dejquu.png',
  },
  //---------------------------10--------------------------------//
  {
    id: 126,
    name: 'Le choix de notre bien ou service',
    description:
      'Consultez les ressources pour vous aider à choisir le bien ou service que vous allez développer dans votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Pourquoi étudier la faisabilité de mon idée ?',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d67a4847b98a80ceb19dc42/deploy',
        },
        {
          title: 'Poser son modèle économique',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f06e713c32a750011048467/deploy',
        },
        {
          title: 'Favoriser la créativité',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d67a42c7b98a80ceb19dc01/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663674911/Illustrations%20succ%C3%A8s/mini_xl_illustration/LeChoixDeNotreBienOuService_i3wz6s.png',
  },
  //---------------------------11--------------------------------//
  {
    id: 127,
    name: 'Définition de notre clientèle cible',
    description:
      'Consultez les ressources pour vous aider à définir la clientèle de votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: 'Etudier son marché',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a45e598f3b50cfb5325eb/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/DefinitionDeNotreClienteleCible_t2aopk.png',
  },
  //---------------------------12--------------------------------//
  {
    id: 129,
    name: "Choix d'une ou plusieurs associations qui recevra les futurs bénéfices de la Mini-Entreprise®",
    description:
      'Consultez les ressources pour vous aider à choisir les associations qui recevront votre donation.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663674795/Illustrations%20succ%C3%A8s/mini_xl_illustration/ChoixDuneAssoPourFutursBe%CC%81ne%CC%81fices_hyhxce.png',
  },
  //---------------------------13--------------------------------//
  {
    id: 130,
    name: 'Validation de notre bien ou service par Entreprendre Pour Apprendre',
    description: 'Validez votre produit avec le référent Entreprendre pour Apprendre.',
    ressources: {
      videos: [],
      documents: [
        {
          title: 'Que peut-on produire ?',
          url: 'Quepeutonproduire.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675512/Illustrations%20succ%C3%A8s/mini_xl_illustration/ValidationBienService_pyfw8l.png',
  },
  //---------------------------14--------------------------------//
  {
    id: 131,
    name: 'Répartition des responsabilités entre les membres de notre Mini-Entreprise®',
    description:
      "Consultez les ressources pour connaître les différents modes d'organisation possibles de votre Mini-Entreprise®. ",
    ressources: {
      videos: [
        {
          title: 'Organiser sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f0597c8c32a750011024ba7/deploy',
        },
        {
          title: 'Prendre une décision',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5dd4240d6244d00c56880ea8/deploy',
        },
        {
          title: 'Organiser son équipe',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7bbac0e6b6a10c48cce38d/deploy',
        },
        {
          title: "Gérer une crise dans l'équipe ",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d654de0074a8c0cfd022022/deploy',
        },
      ],
      documents: [
        {
          title: 'Les rôles dans la Mini-Entreprise®',
          url: 'LesrôlesdanslaMiniEntreprise.pdf',
        },
        {
          title: 'Prendre une décision',
          url: 'Prendreunedécision.pdf',
        },
        {
          title: 'Animer un atelier - Répartir les rôles',
          url: 'AnimerunatelierRépartirlesrôles.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/RepartitionDesResponsabilitesDesMembres_m5kkwi.png',
  },
  //---------------------------15--------------------------------//
  {
    id: 155,
    name: "Mise en place d'une démarche de responsabilité sociale de notre Mini-Entreprise®",
    description: 'Validez votre produit avec le référent Entreprendre pour Apprendre.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675093/Illustrations%20succ%C3%A8s/mini_xl_illustration/MiseEnPlaceRSE_fg3q72.png',
  },
  //---------------------------16--------------------------------//
  {
    id: 133,
    name: 'Identification de notre besoin en financement',
    description:
      'Consultez les ressources pour vous aider à identifier vos besoins en financement.',
    ressources: {
      videos: [
        {
          title: 'Identifier ses besoins de financement ',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7639ff27f1280d17c45a13/deploy',
        },
      ],
      documents: [
        {
          title: 'Introduction au budget prévisionnel',
          url: 'Introductionaubudgetprévisionnel.pdf',
        },
        {
          title: 'Trame - Budget prévisionnel',
          url: 'TrameBudgetprévisionnel.xlsm',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/IdentificationDesBesoinsDeFinancement_aubfn4.png',
  },
  //---------------------------17--------------------------------//
  {
    id: 134,
    name: 'Organisation de notre fonctionnement financier',
    description:
      "Consultez les ressources pour comprendre l'outil comptable de la plateforme et organiser votre fonctionnement financier.",
    ressources: {
      videos: [
        {
          title: "Utiliser l'outil comptable",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5e1dbc68666c250037bafc4a/deploy',
        },
        {
          title: 'Suivre sa comptabilité',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d766dbd27f1280d17c533f2/deploy',
        },
      ],
      documents: [
        {
          title: "Utiliser la plateforme 4 - L'outil comptable",
          url: 'Utiliserlaplateforme4Loutilcomptable.pdf',
        },
        {
          title: 'Trame - Budget prévisionnel',
          url: 'TrameBudgetprévisionnel.xlsm',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945344/Illustrations%20succ%C3%A8s/OrganisationDeNotreFonctionnementFinancier_qixync.png',
  },
  //---------------------------18--------------------------------//
  {
    id: 135,
    name: 'Organisation de notre production',
    description: 'Consultez les ressources pour vous aider à organiser votre production. ',
    ressources: {
      videos: [
        {
          title: 'Produire',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5e2199e9666c250037bc8b5f/deploy',
        },
      ],
      documents: [
        {
          title: 'Trame - Le cahier des charges',
          url: 'TrameLecahierdescharges.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675217/Illustrations%20succ%C3%A8s/mini_xl_illustration/OrganisationdeNotreProduction_v7ivfw.png',
  },
  //---------------------------19--------------------------------//
  {
    id: 157,
    name: 'Test de notre produit ',
    description:
      'Retrouvez bientôt les ressources pour comprendre comment créer le prototype de votre produit et le tester.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675493/Illustrations%20succ%C3%A8s/mini_xl_illustration/TestDeNotreProduit_avb2ui.png',
  },
  //---------------------------20--------------------------------//
  {
    id: 136,
    name: 'Nom et logo de notre Mini-Entreprise®',
    description: 'Consultez les ressources pour organiser un atelier de créativité.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/NomEtLogoDeNotreMinientreprise_xzbrde.png',
  },
  //---------------------------21--------------------------------//
  {
    id: 137,
    name: 'Notre stratégie marketing',
    description: "Consultez les ressources pour découvrir ce qu'est le marketing. ",
    ressources: {
      videos: [
        {
          title: 'Découvrir le marketing',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5ef20ef202f98600114396ff/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/NotreStrate%CC%81gieMarketing_mzmuw5.png',
  },
  //---------------------------22--------------------------------//
  {
    id: 138,
    name: 'Notre stratégie de communication',
    description:
      'Consultez les ressources pour définir la stratégie de communication pour toucher votre clientèle.',
    ressources: {
      videos: [
        {
          title: 'Communiquer, du nom aux relations presse',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d78fa1a27f1280d17c6d58a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945345/Illustrations%20succ%C3%A8s/StrategieDeCommunication_jatmjo.png',
  },
  //---------------------------23--------------------------------//
  {
    id: 139,
    name: 'Inscription au festival régional',
    description:
      'Consultez les ressources pour vous préparer au festival Entreprendre Pour Apprendre dans votre région.',
    ressources: {
      videos: [
        {
          title: 'Créer sa vidéo pour le festival des Mini-Entreprises®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5ffdb71a4f0b6f001170de13/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/InscriptionAuFestivalRegional_ub6nxd.png',
  },
  //---------------------------24--------------------------------//
  {
    id: 140,
    name: 'Organisation d’une vente',
    description: 'Consultez les ressources pour préparer votre première vente.',
    ressources: {
      videos: [
        {
          title: 'Bâtir un argumentaire de vente',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5da724a60777a10ca582b598/deploy',
        },
        {
          title: 'Organiser une vente',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d6e3c48d3ce3b0d01fe648f/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945344/Illustrations%20succ%C3%A8s/OrganisationdUneVente_gnaajm.png',
  },
  //---------------------------25--------------------------------//
  {
    id: 141,
    name: 'Bilan individuel des compétences acquises',
    description:
      'Consultez les ressources pour vous aider à organiser un atelier de bilan individuel des compétences.',
    ressources: {
      videos: [
        {
          title: 'Rejoindre le réseau des alumni',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a4b2d98f3b50cfb533573/deploy',
        },
        {
          title: "Ce que j'ai appris cette année",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a4e1098f3b50cfb5341e6/deploy',
        },
      ],
      documents: [
        {
          title: 'Faire le bilan humain de sa Mini-Entreprise®',
          url: 'BilanInduviduelMiniEntreprise.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945341/Illustrations%20succ%C3%A8s/BilanIndividuelDeCompe%CC%81tences_kbm4j0.png',
  },
  //---------------------------26--------------------------------//
  {
    id: 158,
    name: 'Présentation de son rôle dans la Mini-Entreprise®',
    description:
      'Retrouvez bientôt les ressources pour vous aider à présenter devant un public votre rôle dans la Mini-Entreprise®',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675438/Illustrations%20succ%C3%A8s/mini_xl_illustration/roleMini_hz82fk.png',
  },
  //---------------------------27--------------------------------//
  {
    id: 156,
    name: "Création d'une vidéo ou d'une présentation de restitution du projet",
    description:
      'Retrouvez bientôt les ressources pour vous aider à présenterla démarche de votre Mini-Entreprise®',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663674804/Illustrations%20succ%C3%A8s/mini_xl_illustration/creationvideo_kacbkc.png',
  },
  //---------------------------28--------------------------------//
  {
    id: 142,
    name: 'Bilan et clôture de notre Mini-Entreprise®',
    description:
      'Consultez les ressources pour vous aider à effectuer le bilan de votre Mini-Entreprise®.',
    ressources: {
      videos: [
        {
          title: "Rédiger son rapport d'activité",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/60015c325a65050011bc4940/deploy',
        },
        {
          title: 'Clôturer sa Mini-Entreprise®',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5f0476c45de5580011cc0c14/deploy',
        },
        {
          title: "Ce que j'ai appris cette année",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7a4e1098f3b50cfb5341e6/deploy',
        },
      ],
      documents: [
        {
          title: "Rédiger son rapport d'activité - collège",
          url: 'Rédigersonrapportdactivitécollège.pdf',
        },
        {
          title: "Rédiger son rapport d'activité - lycée",
          url: 'Rédigersonrapportdactivitélycée.pdf',
        },
        {
          title: 'Trame - Lettre investisseur',
          url: 'TrameLettreinvestisseur.docx',
        },
        {
          title: 'Faire le bilan humain de sa Mini-Entreprise®',
          url: 'FairelebilanhumaindesaMiniEntreprise.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945340/Illustrations%20succ%C3%A8s/BilanEtClotureDelaMiniEntreprise_rt4ihn.png',
  },
  //---------------------------29--------------------------------//
  {
    id: 146,
    name: "La classe inversée, qu'est-ce que c'est? ",
    description: "Retrouvez bientôt les ressources pour comprendre ce qu'est la classe inversée .",
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675595/Illustrations%20succ%C3%A8s/mini_xl_illustration/Classeinverse%CC%81e_kctyvu.png',
  },
  //---------------------------30--------------------------------//
  {
    id: 143,
    name: 'Attentes, craintes, questions des membres de notre Mini-Entreprise®',
    description:
      'Consultez les ressources pour organiser un atelier de démarrage de Mini-Entreprise®. ',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675553/Illustrations%20succ%C3%A8s/mini_xl_illustration/AtteintesCraintesQuestionsDesMembresDeNotreMiniEntreprise_knfhmf.png',
  },
  //---------------------------31--------------------------------//
  {
    id: 144,
    name: 'Entrepreneur, intrapreneur, quelle différence?',
    description:
      "Consultez les ressources pour comprendre les différentes manières d'entreprendre.",
    ressources: {
      videos: [
        {
          title: "Qu'est-ce qu'un entrepreneur ?",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d936599f8c8eb0cf33bed97/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675577/Illustrations%20succ%C3%A8s/mini_xl_illustration/EntrepreneurIntrapreneurQuelleDifference_gjrvn2.png',
  },
  //---------------------------32--------------------------------//
  {
    id: 159,
    name: 'La découverte de notre environnement et de ses entreprises ?',
    description:
      'Retrouvez bientôt les ressources pour explorer votre environnement économique de proximité.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675602/Illustrations%20succ%C3%A8s/mini_xl_illustration/decouverteenvironnement_acjxeb.png',
  },
  //---------------------------33--------------------------------//
  {
    id: 145,
    name: "Visite d'une entreprise et repérage de ses actions de responsabilité sociale",
    description:
      'Retrouvez bientôt les ressources pour explorer votre environnement économique de proximité.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675637/Illustrations%20succ%C3%A8s/mini_xl_illustration/VisiteDUneEntreprise_wdymi6.png',
  },
  //---------------------------34--------------------------------//
  {
    id: 160,
    name: 'Constitution de notre capital de démarrage ',
    description:
      'Consultez les ressources pour vous aider à la constitution de votre capital de démarrage.',
    ressources: {
      videos: [
        {
          title: 'Financer sa Mini-Entreprise',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d76463727f1280d17c47c4a/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663687753/Illustrations%20succ%C3%A8s/mini_xl_illustration/ConstituerUnCapitalDeDemarrage_udxu1v.png',
  },
  //---------------------------35--------------------------------//
  {
    id: 161,
    name: 'Santé et sécurité dans notre Mini-Entreprise®',
    description:
      'Retrouvez bientôt les ressources pour comprendre les enjeux de santé et sécurité dans votre  Mini-Entreprise®.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675634/Illustrations%20succ%C3%A8s/mini_xl_illustration/santesecurite_evp11u.png',
  },
  //---------------------------36--------------------------------//
  {
    id: 147,
    name: 'Des partenaires correspondant à la charte des valeurs de notre Mini-Entreprise®',
    description: '',
    ressources: {
      videos: [
        {
          title: 'Présenter son projet à un partenaire',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d7911af98f3b50cfb524903/deploy',
        },
      ],
      documents: [
        {
          title: 'Prendre la parole en public',
          url: 'Prendrelaparoleenpublic.pdf',
        },
      ],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945342/Illustrations%20succ%C3%A8s/DesPartenairesCorrespondantAlaCharte_rzleyi.png',
  },
  //---------------------------37--------------------------------//
  {
    id: 148,
    name: 'Organisation de la recherche de nos futurs partenaires',
    description: '',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945344/Illustrations%20succ%C3%A8s/OrganisationDeRechercheDeFutursPartenaires_tuyooh.png',
  },
  //---------------------------38--------------------------------//
  {
    id: 132,
    name: "Retour d'expériences de la Mini-Entreprise® ",
    description:
      'Retrouvez bientôt les ressources pour identifier les succès et erreurs dans votre projet de Mini-Entreprise®.',
    ressources: {
      videos: [],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1663675623/Illustrations%20succ%C3%A8s/mini_xl_illustration/RetourExperience_xr95ih.png',
  },

  //---------------------------39--------------------------------//
  {
    id: 149,
    name: 'Les challenges du réseau européen JA',
    description:
      'Consultez les ressources pour savoir comment organiser la recherche des futurs partenaires.',
    ressources: {
      videos: [
        {
          title: 'La COYC - un concours pour les 16-20 ans',
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d8e290106e89b0cf50e1e0f/deploy',
        },
        {
          title: "L'EEC - un concours pour les 18-25 ans",
          url: 'https://airapi.kumullus.com/api/v1/kumullus/5d8e296006e89b0cf50e1e95/deploy',
        },
      ],
      documents: [],
    },
    illustration:
      'https://res.cloudinary.com/dcmzzdl4g/image/upload/v1658945343/Illustrations%20succ%C3%A8s/LesChallengesDuReseauEuropeen_wpu7pd.png',
  },
];
