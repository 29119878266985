import React from "react";
import {icons} from "../../../assets";
import "./side-bar.scss";

export const SideBar = ({back, menu, onBack}) => {

  const scrollTo = (id) => {
    const element = document.querySelector(`.${id}`);
    const menuHeight = document.getElementById('fixed-wrapper').getBoundingClientRect().height;

    const offsetTop = element.offsetTop - menuHeight - 25;

    // Internet Explorer 6-11
    const isIE = !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    if (isIE || isEdge) {
      window.scrollTop = offsetTop;
    } else {
      window.scrollTo({
        top: offsetTop,
        // top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div id={'sideBar'}>
      {back && <div className="back-link" onClick={onBack}>
        <i className="material-icons">{icons.ARROW_BACK}</i>
        <p className={'link'}>{back}</p>
      </div>
      }
      {menu && <ul className={'menu'}>
        {Object.values(menu).map((value, index) =>
          <li
            key={index}
            id={value.id}
            onClick={() => scrollTo(value.id)}
          >{value.label}</li>
        )}
      </ul>
      }
    </div>
  )
};
