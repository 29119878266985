import PropTypes from "prop-types";
import React from "react";
import { Input, TextArea } from "../../../components";
import "./user.scss";

const MentorProfile = ({ strings, profile, errors, handleValueChanged }) => {
  return (
    <div className={"mentor-form"}>
      <p>{strings("perimeter")}</p>
      <div className={"wrapper"}>
        <TextArea
          type={"text"}
          tag={"description"}
          className={"description"}
          maxLength={500}
          inputClassName={`${errors.description ? "invalid" : ""}`}
          error={errors.description ? strings('input_required') : null}
          label={strings("description")}
          defaultValue={profile.description}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <p>{strings("company")}</p>
      <div className={"wrapper"}>
      <Input
          label={strings("company_name")}
          className={"name"}
          inputClassName={`${errors.companyName ? "invalid" : ""}`}
          error={errors.companyName ? strings('input_required') : null}
          type={"text"}
          tag={"companyName"}
          defaultValue={profile.companyName}
          handleValueChanged={handleValueChanged}
        />
        <Input
          label={strings("address")}
          className={"address"}
          inputClassName={`${errors.companyAddress ? "invalid" : ""}`}
          error={errors.companyAddress ? strings('input_required') : null}
          type={"text"}
          tag={"companyAddress"}
          defaultValue={profile.companyAddress}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <div className={"wrapper"}>
      <Input
          label={strings("postal_code")}
          className={"postal"}
          inputClassName={`${errors.companyPostalCode ? "invalid" : ""}`}
          error={errors.companyPostalCode ? strings('input_required') : null}
          type={"text"}
          tag={"companyPostalCode"}
          defaultValue={profile.companyPostalCode}
          handleValueChanged={handleValueChanged}
        />
        <Input
          label={strings("city")}
          className={"city"}
          inputClassName={`${errors.companyCity ? "invalid" : ""}`}
          error={errors.companyCity ? strings('input_required') : null}
          type={"text"}
          tag={"companyCity"}
          defaultValue={profile.companyCity}
          handleValueChanged={handleValueChanged}
        />
        <Input
          label={strings("country")}
          className={"country"}
          inputClassName={`${errors.companyCountry ? "invalid" : ""}`}
          error={errors.companyCountry ? strings('input_required') : null}
          type={"text"}
          tag={"companyCountry"}
          defaultValue={profile.companyCountry}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <p>{strings("company_ceo")}</p>
      <div className={"wrapper"}>
        <Input
          label={strings("lastname")}
          className={"lastname"}
          inputClassName={`${errors.ceoLastName ? "invalid" : ""}`}
          error={errors.ceoLastName ? strings('input_wrong') : null}
          type={"text"}
          tag={"ceoLastName"}
          defaultValue={profile.ceoLastName}
          handleValueChanged={handleValueChanged}
        />
        <Input
          label={strings("firstname")}
          className={"firstname"}
          inputClassName={`${errors.ceoFirstName ? "invalid" : ""}`}
          error={errors.ceoFirstName ? strings('input_wrong') : null}
          type={"text"}
          tag={"ceoFirstName"}
          defaultValue={profile.ceoFirstName}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <div className={"wrapper"}>
        <Input
          label={strings("email")}
          className={"lastname"}
          inputClassName={`${errors.lastName ? "invalid" : ""}`}
          error={errors.lastName ? strings('wrong_email') : null}
          type={"email"}
          tag={"ceoEmail"}
          defaultValue={profile.ceoEmail}
          handleValueChanged={handleValueChanged}
        />
        <Input
          label={strings("phone_number")}
          className={"phone"}
          inputClassName={`${errors.ceoPhone ? "invalid" : ""}`}
          error={errors.ceoPhone ? strings('wrong_phone') : null}
          type={"tel"}
          tag={"ceoPhone"}
          defaultValue={profile.ceoPhone}
          handleValueChanged={handleValueChanged}
        />
      </div>
    </div>
  );
};

MentorProfile.propTypes = {
  strings: PropTypes.func,
  profile: PropTypes.object,
  errors: PropTypes.object,
  handleValueChanged: PropTypes.func,
};

MentorProfile.defaultProps = {
  strings: () => console.log("strings not implemented"),
  profile: {},
  errors: {},
  handleValueChanged: () => console.log("handleValueChanged not implemented"),
};

export default MentorProfile;
