import { connect } from 'react-redux';
import { Guide } from './Guide';

const mapStateToProps = state => ({
  notionUrl: process.env.REACT_APP_NOTION_URL
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Guide);
