import {createReducer} from 'reduxsauce';
import {types} from './actions';
import {logger} from "../../utils/logger";

const initialState = {
  message: '',
  displayed: false,
  actionPopup: () => logger.warn("no function implemented")
};

const displayConfirmPopup = (state = initialState, action) => {
  const {message, popupAction} = action;
  return {...state, message: message, actionPopup: popupAction, displayed: true};
};

const hideConfirmPopup = (state = initialState) => {
  return {...state, message: '', displayed: false};
};

export default createReducer(initialState, {
  [types.DISPLAY_CONFIRM_POPUP]: displayConfirmPopup,
  [types.HIDE_CONFIRM_POPUP]: hideConfirmPopup,
});
