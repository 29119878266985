import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { CommonServices } from "../../services";
import { NotificationsActions } from "../notifications";
import { default as CommonsActions, types } from "./actions";

function* getRegionsRequested() {
  const [error, response] = yield call(CommonServices.getRegions);

  if (!error) {
    yield put(CommonsActions.getRegionsSucceeded(response.data));
  }
}


function* startSyncJobsRequested() {
  let canJob = true;

  while (canJob) {
    const {id, accessToken} = yield select(state => state.auth);
    canJob = id && accessToken;

    if (canJob) {
      if (document.hasFocus()) {
        yield put(NotificationsActions.getNotificationsRequested());
      }
      yield delay(30000);
    }
  }
}

export default [
  takeLatest(types.GET_REGIONS_REQUESTED, getRegionsRequested),
  takeLatest(types.START_SYNC_JOBS_REQUESTED, startSyncJobsRequested),
];
