import { connect } from 'react-redux';
import { getTranslate } from "react-localize-redux";
import { goBack } from 'react-router-redux';
import DetailVideo from './DetailVideo.jsx';

const mapStateToProps = (state) => ({
  project: state.projects.project,
  strings: getTranslate(state.locale),
  role: state.user.user.role,
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailVideo);
