import { default as Room } from './Room';
import { connect } from 'react-redux';
import { ProjectsActions } from '../../../redux/projects';
import { createAction } from '../../../utils';
import { getTranslate } from 'react-localize-redux';
import { push } from 'react-router-redux';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  room: state.projects.room,
  role: state.user.user.role,
});

const mapDispatchToProps = (dispatch) => ({
  resetRoomDetail: () => createAction(dispatch(ProjectsActions.resetRoomDetail())),
  getMiniRoom: (miniId, roomId) => createAction(dispatch(ProjectsActions.getMiniRoomRequested(miniId, roomId))),
  updateSuccesses: (miniId, roomId, successes) => dispatch(ProjectsActions.updateMiniRoomSuccessesRequested(miniId, roomId, successes)),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
