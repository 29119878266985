import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ScreenHeader } from '../../components';
import { default as User } from './user';
import { default as Password } from './password';
import './profile.scss';
import { history } from '../../../core/history';
import { Routes } from '../../../utils';
import { images } from '../../../assets';

const Profile = ({ getUser, getRegions, navigateTo, pop }) => {
  useEffect(() => {
    getUser();
    getRegions();
  }, []);

  const handleBack = () => {
    if (history.oldLocation) {
      pop();
    } else {
      navigateTo(Routes.HOME);
    }
  };
 // usePageViews();
  return (
    <div className={'profile'}>
      <img
        className={'background'}
        src={images.epaBackground}
        alt={'background'}
      />
      <ScreenHeader
        screenName={'profile'}
        showBackAction={true}
        arrowAction={handleBack}
      />
      <div className={'container'}>
        <div className={'form-wrapper'}>
          <User />
          <Password />
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  getUser: PropTypes.func,
  getRegions: PropTypes.func,
  navigateTo: PropTypes.func,
  pop: PropTypes.func,
};

Profile.defaultProps = {
  getUser: () => console.log('getUser not implemented'),
  getRegions: () => console.log('getRegions not implemented'),
  navigateTo: () => console.log('navigateTo not implemented'),
  pop: () => console.log('pop not implemented'),
};

export default Profile;
