import { createAction } from '../../utils/index';

// Types
export const types = {
  GET_PROJECTS_REQUESTED: 'GET_PROJECTS_REQUESTED',
  GET_PROJECTS_SUCCEEDED: 'GET_PROJECTS_SUCCEEDED',
  PATCH_SUCCESS_REQUESTED: 'PATCH_SUCCESS_REQUESTED',
  PATCH_SUCCESS_SUCCEEDED: 'PATCH_SUCCESS_SUCCEEDED',
  GET_PROJECT_DETAIL_REQUESTED: 'GET_PROJECT_DETAIL_REQUESTED',
  GET_PROJECT_DETAIL_SUCCEEDED: 'GET_PROJECT_DETAIL_SUCCEEDED',
  PATCH_PROJECT_REQUESTED: 'PATCH_PROJECT_REQUESTED',
  PATCH_PROJECT_SUCCEEDED: 'PATCH_PROJECT_SUCCEEDED',
  ADD_MEETING_REQUESTED: 'ADD_MEETING_REQUESTED',
  ADD_MEETING_SUCCEEDED: 'ADD_MEETING_SUCCEEDED',
  DELETE_MEETING_REQUESTED: 'DELETE_MEETING_REQUESTED',
  DELETE_MEETING_SUCCEEDED: 'DELETE_MEETING_SUCCEEDED',
  UPDATE_MEETING_REQUESTED: 'UPDATE_MEETING_REQUESTED',
  UPDATE_MEETING_SUCCEEDED: 'UPDATE_MEETING_SUCCEEDED',
  ADD_BUDGET_REQUESTED: 'ADD_BUDGET_REQUESTED',
  ADD_BUDGET_SUCCEEDED: 'ADD_BUDGET_SUCCEEDED',
  DELETE_BUDGET_REQUESTED: 'DELETE_BUDGET_REQUESTED',
  DELETE_BUDGET_SUCCEEDED: 'DELETE_BUDGET_SUCCEEDED',
  UPDATE_BUDGET_REQUESTED: 'UPDATE_BUDGET_REQUESTED',
  UPDATE_BUDGET_SUCCEEDED: 'UPDATE_BUDGET_SUCCEEDED',
  GET_OPERATION_TYPES_REQUESTED: 'GET_OPERATION_TYPES_REQUESTED',
  GET_OPERATION_TYPES_SUCCEEDED: 'GET_OPERATION_TYPES_SUCCEEDED',
  ADD_LINK_REQUESTED: 'ADD_LINK_REQUESTED',
  ADD_LINK_SUCCEEDED: 'ADD_LINK_SUCCEEDED',
  UPDATE_LINK_REQUESTED: 'UPDATE_LINK_REQUESTED',
  UPDATE_LINK_SUCCEEDED: 'UPDATE_LINK_SUCCEEDED',
  DELETE_LINK_REQUESTED: 'DELETE_LINK_REQUESTED',
  DELETE_LINK_SUCCEEDED: 'DELETE_LINK_SUCCEEDED',
  SET_LINK_IN_ERROR: 'SET_LINK_IN_ERROR',
  GET_DATA_LISTS_REQUESTED: 'GET_DATA_LISTS_REQUESTED',
  GET_DATA_LISTS_SUCCEEDED: 'GET_DATA_LISTS_SUCCEEDED',
  UPDATE_DATA_REQUESTED: 'UPDATE_DATA_REQUESTED',
  UPDATE_DATA_SUCCEEDED: 'UPDATE_DATA_SUCCEEDED',
  DOWNLOAD_BUDGET_REQUESTED: 'DOWNLOAD_BUDGET_REQUESTED',
  RESET_PROJECT_DETAIL: 'RESET_PROJECT_DETAIL',
  GET_MINI_ROOM_REQUESTED: 'GET_MINI_ROOM_REQUESTED',
  GET_MINI_ROOM_SUCCEEDED: 'GET_MINI_ROOM_SUCCEEDED',
  PATCH_MINI_ROOM_SUCCESS_REQUESTED: 'PATCH_MINI_ROOM_SUCCESS_REQUESTED',
  PATCH_MINI_ROOM_SUCCESS_SUCCEEDED: 'PATCH_MINI_ROOM_SUCCESS_SUCCEEDED',
  RESET_ROOM_DETAIL: 'RESET_ROOM_DETAIL',
  ADD_SUCCESS_REQUESTED: 'ADD_SUCCESS_REQUESTED',
  ADD_SUCCESS_SUCCEEDED: 'ADD_SUCCESS_SUCCEEDED',
  DELETE_SUCCESS_REQUESTED: 'DELETE_SUCCESS_REQUESTED',
  DELETE_SUCCESS_VALIDATED: 'DELETE_SUCCESS_VALIDATED',
  DELETE_SUCCESS_SUCCEEDED: 'DELETE_SUCCESS_SUCCEEDED',
  PATCH_SUCCESS_CANCELED: 'PATCH_SUCCESS_CANCELED',
};

// Actions
export default {
  getProjectsRequested: () => createAction(types.GET_PROJECTS_REQUESTED, {}),
  getProjectsSucceeded: (list) => createAction(types.GET_PROJECTS_SUCCEEDED, { list }),
  updateSuccessesRequested: (projectId, successes) =>
    createAction(types.PATCH_SUCCESS_REQUESTED, { projectId, successes }),
  updateSuccessesCancel: (projectId, successes) =>
    createAction(types.PATCH_SUCCESS_CANCELED, { projectId, successes }),
  updateSuccessesSucceeded: (project) => createAction(types.PATCH_SUCCESS_SUCCEEDED, { project }),
  getProjectDetailRequested: (projectId) =>
    createAction(types.GET_PROJECT_DETAIL_REQUESTED, { projectId }),
  getProjectDetailSucceeded: (project) =>
    createAction(types.GET_PROJECT_DETAIL_SUCCEEDED, { project }),
  updateProjectRequested: (projectId, projectName, projectDescription, projectAvatar) =>
    createAction(types.PATCH_PROJECT_REQUESTED, {
      projectId,
      projectName,
      projectDescription,
      projectAvatar,
    }),
  updateProjectSucceeded: (project) => createAction(types.PATCH_PROJECT_SUCCEEDED, { project }),
  addMeetingRequested: (projectId, meeting) =>
    createAction(types.ADD_MEETING_REQUESTED, { projectId, meeting }),
  addMeetingSucceeded: (project) => createAction(types.ADD_MEETING_SUCCEEDED, { project }),
  deleteMeetingRequested: (projectId, meetingId) =>
    createAction(types.DELETE_MEETING_REQUESTED, { projectId, meetingId }),
  deleteMeetingSucceeded: (project) => createAction(types.DELETE_MEETING_SUCCEEDED, { project }),
  updateMeetingRequested: (projectId, meetingId, meeting) =>
    createAction(types.UPDATE_MEETING_REQUESTED, { projectId, meetingId, meeting }),
  updateMeetingSucceeded: (project) => createAction(types.UPDATE_MEETING_SUCCEEDED, { project }),
  addBudgetRequested: (projectId, budget) =>
    createAction(types.ADD_BUDGET_REQUESTED, { projectId, budget }),
  addBudgetSucceeded: (project) => createAction(types.ADD_BUDGET_SUCCEEDED, { project }),
  deleteBudgetRequested: (projectId, budgetId) =>
    createAction(types.DELETE_BUDGET_REQUESTED, { projectId, budgetId }),
  deleteBudgetSucceeded: (project) => createAction(types.DELETE_BUDGET_SUCCEEDED, { project }),
  updateBudgetRequested: (projectId, budgetId, budget) =>
    createAction(types.UPDATE_BUDGET_REQUESTED, { projectId, budgetId, budget }),
  updateBudgetSucceeded: (project) => createAction(types.UPDATE_BUDGET_SUCCEEDED, { project }),
  getOperationTypesRequested: (inBackground = true) =>
    createAction(types.GET_OPERATION_TYPES_REQUESTED, { inBackground }),
  getOperationTypesSucceeded: (list) => createAction(types.GET_OPERATION_TYPES_SUCCEEDED, { list }),
  addLinkRequested: (projectId, link) =>
    createAction(types.ADD_LINK_REQUESTED, { projectId, link }),
  addLinkSucceeded: (project) => createAction(types.ADD_LINK_SUCCEEDED, { project }),
  updateLinkRequested: (projectId, linkId, link) =>
    createAction(types.UPDATE_LINK_REQUESTED, { projectId, linkId, link }),
  updateLinkSucceeded: (project) => createAction(types.UPDATE_LINK_SUCCEEDED, { project }),
  deleteLinkRequested: (projectId, linkId) =>
    createAction(types.DELETE_LINK_REQUESTED, { projectId, linkId }),
  deleteLinkSucdeeded: (project) => createAction(types.DELETE_LINK_SUCCEEDED, { project }),
  setLinkInError: (boolean) => createAction(types.SET_LINK_IN_ERROR, { boolean }),
  updateDataRequested: (projectId, data) =>
    createAction(types.UPDATE_DATA_REQUESTED, { projectId, data }),
  getDataListsRequested: (inBackground = true) =>
    createAction(types.GET_DATA_LISTS_REQUESTED, { inBackground }),
  getDataListsSucceeded: (categories, levels, sectors, times) =>
    createAction(types.GET_DATA_LISTS_SUCCEEDED, { categories, levels, sectors, times }),
  updateDataSucceeded: (project) => createAction(types.UPDATE_DATA_SUCCEEDED, { project }),
  downloadBudgetRequested: (projectId) =>
    createAction(types.DOWNLOAD_BUDGET_REQUESTED, { projectId }),
  resetProjectDetail: () => createAction(types.RESET_PROJECT_DETAIL, {}),
  getMiniRoomRequested: (miniId, roomId) =>
    createAction(types.GET_MINI_ROOM_REQUESTED, { miniId, roomId }),
  getMiniRoomSucceeded: (room) => createAction(types.GET_MINI_ROOM_SUCCEEDED, { room }),
  updateMiniRoomSuccessesRequested: (miniId, roomId, successes) =>
    createAction(types.PATCH_MINI_ROOM_SUCCESS_REQUESTED, { miniId, roomId, successes }),
  updateMiniRoomSuccessesSucceeded: (room) =>
    createAction(types.PATCH_MINI_ROOM_SUCCESS_SUCCEEDED, { room }),
  resetRoomDetail: () => createAction(types.RESET_ROOM_DETAIL, {}),
  addSuccessRequested: (projectId, success) =>
    createAction(types.ADD_SUCCESS_REQUESTED, { projectId, success }),
  addSuccessSucceeded: (project) => createAction(types.ADD_SUCCESS_SUCCEEDED, { project }),

  deleteSuccessRequested: (projectId, successId) =>
    createAction(types.DELETE_SUCCESS_REQUESTED, { projectId, successId }),

    deleteSuccessvalidated: (projectId, successId) =>
    createAction(types.DELETE_SUCCESS_VALIDATED, { projectId, successId }),

  deleteSuccessSucceeded: (project) => createAction(types.DELETE_SUCCESS_SUCCEEDED, { project }),
};
