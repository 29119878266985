import React from 'react';
import '../detail-budget-list.scss';
import { accountType, budgetOperation, userRole } from '../../../../utils';
import { formatDateForBudget } from '../../../../utils/formatDate';
import { icons } from '../../../../assets';

export const BudgetItem = ({entry, strings, id, role, handleOpenConfirm, handleUpdateBudget}) => {
  
  const {description, amount, account, operationDate, executionDate, operationType} = entry;

  const isWithdraw = operationType => {
    return operationType.operation === budgetOperation.WITHDRAW;
  };

  const value = (val, operationType) => (
    <p
      className={`${isWithdraw(operationType) ? 'negative' : ''}`}>{`${isWithdraw(operationType) ? '- ' : '+ '}` + val.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + strings('currency')}</p>
  );

  const deleteBudget = (e) => {
    e.stopPropagation();
    handleOpenConfirm(id);
  };

  const updateBudget = () => {
    handleUpdateBudget(id);
  };

  return (
    <div className={'budget-item selectable'} onClick={updateBudget}>
      <div className={'col date'}>
        <p>{formatDateForBudget(operationDate)}</p>
      </div>
      <div className={'col object'}>
        <p>{description}</p>
      </div>
      <div className={'col type'}>
        <p>{operationType.label}</p>
      </div>
      <div className={'col execution'}>
        <p>{executionDate !== null ? formatDateForBudget(executionDate) : '-'}</p>
      </div>
      <div className={'col amount'}>
        {value(amount, operationType)}
      </div>
      <div className={'col delete'}>
        <i className='material-icons' onClick={deleteBudget}>{icons.DELETE}</i>
      </div>
    </div>
  );
};
