import * as Sentry from '@sentry/browser';
import 'moment/locale/fr';
import React, { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { version } from '../package.json';
import { Router, store } from './core';
import CssBaseline from '@mui/material/CssBaseline';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import './index.scss';
import { unregister } from './registerServiceWorker';
import CacheBuster from 'react-cache-buster';
import { ConfirmPopup, Footer, Loader, Navigation, SnackBar } from './ui/components';
import { MaintenanceMode } from './ui/screens';
import { loadState, saveState } from './utils';
import { getMaintenance } from './utils/service';

if (loadState('epa_dashboard_last-version') !== version) {
  saveState('epa_dashboard_last-version', version);
  saveState('epa_dashboard_state', {});
  if (caches) {
    caches.keys().then((keys) => {
      for (let key of keys) caches.delete(key);
    });
  }
  window.location.reload();
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://4648aa1419a64220ab936cbd94a13826@sentry.io/1858145',
  });
}
const isProduction = process.env.NODE_ENV === 'production';
const ltsVersion = process.env.REACT_APP_LTS_VERSION;
console.log(`Version LTS: ${ltsVersion}`);
ReactDOM.render(
  getMaintenance() ? (
    <MaintenanceMode />
  ) : (
    <CacheBuster
      currentVersion={ltsVersion}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <Provider store={store}>
        <Fragment>
          <ScopedCssBaseline>
            <Navigation />
            <Router />
            <SnackBar />
            <ConfirmPopup />
            <Loader />
            <Footer />
          </ScopedCssBaseline>
        </Fragment>
      </Provider>
    </CacheBuster>
  ),
  document.getElementById('root'),
);

unregister();
