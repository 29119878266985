import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {ProjectsActions} from "../../../../redux/projects";
import { ContactList } from './ContactList';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  projects: state.projects.list,
  currentUser: state.user.user,
});

const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(ProjectsActions.getProjectsRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
