import React, { useState } from 'react'
import { icons } from '../../../../assets'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import "./validateSuccess.scss"
import Calender from '../../../components/calendar/Calender';
import { modifiers, modifiersStyles } from './configCalendar';
const ValidateSuccess = ({ show, hide,selectedDay,choiseDate }) => {

    const handleOnDayClick = date => {
      choiseDate(date);
  };
 
  return (
    <div className={`validate-success ${show ? 'shown' : 'hidden'}`}>
        <div className={'popups-calendar'}>
        <div className={'header'}>
          <h2 className={"header-title"} >Valider le succès</h2>
          <i className={'material-icons close'} onClick={hide}>
            {icons.CLOSE}
          </i>
        </div>
        <div className={'calendar-content'} >
          <div className={'description'} >
            <p>Quand avez-vous accompli ce succès ?</p>
            <p>Sélectionnez une date afin de valider ce succès.</p>
          </div>
          <div className={'calendar'} >
             <DayPicker
            localeUtils={MomentLocaleUtils}
            locale={'fr'}
            modifiers={modifiers(selectedDay)}
            modifiersStyles={modifiersStyles}
            showOutsideDays
            weekdaysShort={['D', 'L', 'M', 'Me', 'J', 'V', 'S']}
              onDayClick={handleOnDayClick}
              today={true}
          />
          </div>
        </div>
        

        </div>
    </div>
  )
}

export default ValidateSuccess
