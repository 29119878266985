import React, { PureComponent } from 'react';
import { Routes, usePageViews } from '../../../utils';
import { Button, ButtonColors, ScreenHeader } from '../../components';
import { LinkItem } from './linkItem';
import './links.scss';

export class Links extends PureComponent {

  state = {
    links: this.props.links,
    editedLinkIndex: null,
    isSaving: false,
  };

  componentDidMount() {
    const {projectId: urlProjectId} = this.props.match.params;
    if (!this.props.projectId || this.props.projectId !== parseInt(urlProjectId, 10)) {
      this.props.getProject(parseInt(urlProjectId, 10));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isSaving && this.state.isSaving && !this.props.linkInError) {
      //In this case, we must handle a non error response from the service
      this.setState({isSaving: false, editedLinkIndex: null, links: this.props.links});
      this.props.resetErrors()
    } else if (!this.state.isSaving && this.props.links !== prevProps.links) {
      //In this case we must handle a response from a service (not related to creation or update) to update the link list
      this.setState({editedLinkIndex: null, links: this.props.links});
    }
  }

  addBlankLink = () => {
    const {links} = this.state;
    const newLink = {url: 'https://', icon: '', label: ''};
    this.setState({editedLinkIndex: links.length, links: [...links, newLink]});
  };

  setNewValue = (newValue) => {
    this.setState({newValue});
  };

  handleLinkDeletion = index => () => {
    const {strings, projectId, deleteLink, showPopup} = this.props;
    const {editedLinkIndex, links} = this.state;
    const deletedLink = links[index];
    if (deletedLink.id) {
      showPopup(strings('confirmation_question_links'), () => deleteLink(projectId, deletedLink.id));
    } else {
      const newLinkList = links.slice();
      newLinkList.splice(index, 1);
      this.setState({
        editedLinkIndex: index === editedLinkIndex ? null : index > editedLinkIndex ? editedLinkIndex : editedLinkIndex - 1,
        links: [...newLinkList],
      });
    }
  };

  handleLinkSave = link => {
    const {projectId, addLink, updateLink} = this.props;
    if (link.id) {
      updateLink(projectId, link.id, link);
    } else {
      addLink(projectId, link);
    }
    this.setState({isSaving: true});
  };

  handleEditLink = index => isEdited => {
    this.setState({editedLinkIndex: isEdited ? index : null});
  };

  render() {
    const {strings, navigateTo, projectId, addLink, updateLink, showPopup, linkInError} = this.props;
    const {links, editedLinkIndex} = this.state;
    //usePageViews()
    return (
      <React.Fragment>
        <ScreenHeader
          screenName={'dashboard'}
          arrowAction={() => navigateTo(Routes.PROJECT_DETAIL.replace(':projectId', projectId))}
        />
        <div className={'links fade'}>
          <div className={'list'}>
            <div className={'captions'}>
              <p>{strings('choose_icon')}</p>
              <p>{strings('object')}</p>
            </div>
            <ul className={'icons-list'}>
              {links.length > 0 ? links.map((link, index) =>
                  <LinkItem
                    strings={strings}
                    link={link}
                    key={link.id || 'temp'}
                    saveLink={this.handleLinkSave}
                    addLink={addLink}
                    updateLink={updateLink}
                    editLink={this.handleEditLink(index)}
                    deleteLink={this.handleLinkDeletion(index)}
                    showPopup={showPopup}
                    projectId={projectId}
                    isEdited={index === editedLinkIndex}
                    disableEdition={editedLinkIndex !== null && editedLinkIndex !== index}
                    setNewValue={this.setNewValue}
                    linkInError={linkInError}
                  />)
                :
                <p className={'no-link'}>{strings('no_link_at_this_time')}</p>
              }
            </ul>
          </div>
          <div className={'buttons'}>
            <Button
              label={strings('add')}
              icon={'add'}
              color={ButtonColors.green500}
              className={'btn'}
              action={this.addBlankLink}
              enabled={editedLinkIndex === null}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
