import { methods } from '../utils';
import { authenticatedService } from './middleware';

const {GET, PATCH} = methods;
const routes = {
  notifications: id => `/users/${id}/notifications`,
};

export default {
  getNotifications: id => authenticatedService(GET, routes.notifications(id), {}, {}),
  setNotificationsRead: (userId, notifIds) => authenticatedService(PATCH, routes.notifications(userId), notifIds, {}),
};
