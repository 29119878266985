import React from 'react';
import { Button, ButtonColors } from '../../../components';
import './edit-meeting.scss';
import { formatDateForDisplay } from '../../../../utils';
import { icons } from '../../../../assets';

export const EditMeeting = React.memo(({ strings, selectedDay, handleCancel, handleOpenConfirm, meeting, setMeeting, saveNewMeeting, isCreatingMeeting }) => {

  return (
    <div className={'add-meeting fade'}>
      <h1 className={'title'}>{strings('add_entry')}</h1>
      <p className={'date'}>{formatDateForDisplay(selectedDay) || strings('select_date')}</p>
      <p className={'description'}>{strings('description')} : </p>
      
      <textarea
        className={'text-input'}
        onChange={e => setMeeting({ ...meeting, date: selectedDay, description: e.target.value })}
        value={meeting && meeting.description !== null ? meeting.description : ''}
      />
      <div className={'buttons'}>
        <Button
          label={strings('cancel')}
          color={ButtonColors.gray}
          action={handleCancel}
        />
        {meeting &&
          <Button
            label={strings('delete')}
            color={ButtonColors.gray}
            action={() => handleOpenConfirm(meeting)}
          />
        }
        <Button
          label={strings('save')}
          action={saveNewMeeting}
          enabled={meeting && meeting.description !== ''}
        />
      </div>
      <i className={'material-icons close'}
        onClick={handleCancel}>
        {icons.CLOSE}
      </i>
    </div>
  )
})