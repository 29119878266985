import React from 'react';
import './contact-item.scss';
import UserItem from '../../userItem';
import { userRole } from '../../../../../utils'

export const ContactItem = ({project, currentUser, showContactsInfo, strings}) => {
  const { name, users } = project;

  const usersFiltered = users.filter((user) => {
    if (currentUser.role === userRole.STUDENT) {
      return user.id !== currentUser.id
    }
    return user.id !== currentUser.id
  });
  
  const sortByRole = (a,b) => {
    if (a.role === b.role) {
      return b.lastName.localeCompare(a.lastName);
    } else {
      if (b.role === userRole.SUPERVISOR) {
        return 1;
      } else if (a.role === userRole.SUPERVISOR) {
        return -1
      } else if (b.role === userRole.MENTOR) {
        return 1;
      } else if (a.role === userRole.MENTOR) {
        return -1;
      } else if (b.role === userRole.FACILITATOR) {
        return 1;
      } else {
        return -1;
      }
    }
  }

  return (
    <div className={'contact-item'}>
      <div className={'resume'}>
        <p className={'item-title'}>{name}</p>
        <div className={'users-list'}>
          {usersFiltered && usersFiltered.sort(sortByRole)
          .map(user => (
            <UserItem user={user} showContactsInfo={showContactsInfo} strings={strings} key={user.id}/>
          ))}
        </div>
      </div>
    </div>
  )
};
