import PropTypes from 'prop-types';
import React from 'react';
import { logger } from '../../../utils';
import './button.scss';
import { ButtonColors } from './Colors';

export const Button = ({action, color, label, icon, className, enabled = true,reverse = false}) => (
  <div
    onClick={() => enabled ? action() : null}
    className={`button ${color} ${className} ${enabled ? '' : 'disabled'}`}
  >
    {!reverse ?
       <span className={icon ? 'with-icon' : ''}>
      {label}{icon && <i className="material-icons">{icon}</i>}
    </span>
      :  <span className={icon ? 'with-icon' : ''}>
    {icon && <i className="material-icons">{icon}</i>}{label}
    </span>
    }    
  </div>
);

Button.propsType = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf([...Object.values(ButtonColors)]),
  icon: PropTypes.string,
  action: PropTypes.func.isRequired,
};

Button.defaultProps = {
  color: ButtonColors.green500,
  action: () => logger.warn('action not implemented.'),
};
