import { userRole } from './enum';

export const filterAndSortUsers = (users, currentUserId) => {
  const filteredUsers = users.filter((user) => user.id !== currentUserId);
  const sortedUsers = {
    supervisors: filteredUsers.filter((user) => user.role === userRole.SUPERVISOR),
    mentors: filteredUsers.filter((user) => user.role === userRole.MENTOR),
    facilitators: filteredUsers.filter((user) => user.role === userRole.FACILITATOR || user.role === userRole.ADMIN),
    students: filteredUsers.filter((user) => user.role === userRole.STUDENT),
  };

  return sortedUsers;
};
