import PropTypes from "prop-types";
import React, {useEffect} from "react";
import moment from 'moment';
import { icons } from "../../../assets";
import { Button } from "../buttons";
import "./notifications.scss";
import { userRole } from "../../../utils";

const Notifications = ({
  strings,
  displayed,
  notifications,
  user,
  getNotifications,
  setNotificationsRead,
  handleClose,
}) => {
  useEffect(() => {
    if (displayed) {
      getNotifications();
    }
  }, [displayed]);

  const onClose = () => {
    if (user.role !== userRole.STUDENT && notifications.some((n) => n.isNew)) {
      setNotificationsRead();
    }

    handleClose();
  };

  const openLink = (link) => () => window.open(link, '_blank', 'noopener,noreferrer');
  return (
    <div className={`notifications ${displayed ? 'displayed' : ''}`}>
      <div className={'header'}>
        <p>{strings('notifications')}</p>
        <i className={'material-icons'} onClick={onClose}>
          {icons.CLOSE}
        </i>
      </div>
      <div className={'container'}>
        {notifications.map((notif, index) =>
          moment(notif.createdAt).diff(moment(new Date()), 'days') >=0 ? (
            <>
              <div className={'notif'} key={index}>
                <div className={'header'}>
                  <p>{notif.title}</p>
                  <div className={'date'}>
                    <p>{moment(notif.createdAt).format('DD MMM YYYY')}</p>
                    <div className={`badge ${notif.isNew ? 'new' : ''}`} />
                  </div>
                </div>
                <div className={'content'}>
                  <p>{notif.description}</p>
                  {notif.links && notif.links.length > 0 && (
                    <div className={'links'}>
                      {notif.links.map((link, index) => (
                        <Button
                          key={index}
                          label={link.title}
                          icon={icons.ARROW_FORWARD}
                          action={openLink(link.link)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={'separator'} />
            </>
          ) : (
            <div className={'message'} >
            <p>Aucune nouvelle notification pour le moment.</p>
           </div>
          ),
        )}
      </div>
    </div>
  );
};;

Notifications.propTypes = {
  strings: PropTypes.func,
  displayed: PropTypes.bool,
  notifications: PropTypes.array,
  user: PropTypes.object,
  getNotifications: PropTypes.func,
  setNotificationsRead: PropTypes.func,
  handleClose: PropTypes.func,
};

Notifications.defaultProps = {
  strings: () => console.log("strings not implemented"),
  displayed: false,
  notifications: [],
  user: {},
  getNotifications: () => console.log("getNotifications not implemented"),
  setNotificationsRead: () => console.log("setNotificationsRead not implemented"),
  handleClose: () => console.log("handleClose not implemented"),
};

export default Notifications;
